import { Flex, HStack, Stack } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { OrangeButton } from '../../../components/inputs/buttons';
import { MyLinkModal } from './my-link-modal';
import { LinksFilter } from '../../../components/display/views/filters/claim-filter';
import {
    shadowProps,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    VIEW_COLUMN_WIDTH,
    AMOUNT_COLUMN_WIDTH,
    ListViewCard,
    ListItemProps,
} from '../../../components/layout/cards';

const LINKS_HEADERS: ListViewCardProps['headers'] = [
    { label: 'link name', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'date created', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'amount collected', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'link', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];

export const MyLinksPageNew = () => {
    const [isLinkModalOpen, setIsLinkModalOpen] = React.useState(false);
    const onLinkModalClose = () => setIsLinkModalOpen(false);
    const listViewCardRef = useRef<HTMLDivElement>(null);

    const displayedListItems: ListItemProps[] = [
        {
            columnValues: ['Mike‘s Peanuts', '12 Dec 2020', '49 USDC', 'bulla.network.com/pay?t21saw24123', 'View'],
        },
        {
            columnValues: ['Mike‘s Peanuts', '12 Dec 2020', '49 USDC', 'bulla.network.com/pay?t21saw24123', 'View'],
        },
        {
            columnValues: ['Mike‘s Peanuts', '12 Dec 2020', '49 USDC', 'bulla.network.com/pay?t21saw24123', 'View'],
        },
        {
            columnValues: ['Mike‘s Peanuts', '12 Dec 2020', '49 USDC', 'bulla.network.com/pay?t21saw24123', 'View'],
        },
        {
            columnValues: ['Mike‘s Peanuts', '12 Dec 2020', '49 USDC', 'bulla.network.com/pay?t21saw24123', 'View'],
        },
    ];

    return (
        <Stack spacing="8">
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <LinksFilter filters={{ search: undefined }} setFilters={() => {}} searchPlaceholder="Links" />
                <OrangeButton onClick={() => setIsLinkModalOpen(true)}>Create new link</OrangeButton>
            </HStack>

            <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                <ListViewCard
                    headers={LINKS_HEADERS}
                    displayedListItems={displayedListItems}
                    bordered={false}
                    totalItemCount={displayedListItems.length}
                    emptyMessage="No links to display"
                />
            </Flex>

            <MyLinkModal isOpen={isLinkModalOpen} onClose={onLinkModalClose} />
        </Stack>
    );
};

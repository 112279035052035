export const _8949Tabs = ['transactionsIn', 'transactionsOut', 'transactionMatching', 'transfers'] as const;

export type _8949Tab = typeof _8949Tabs[number];

type SummaryColumn = {
    label: string;
    mockValue?: number;
};

type TabDisplayConfig = {
    displayName: string;
    summaryColumns: SummaryColumn[];
};

const TAB_CONFIG: Record<_8949Tab, TabDisplayConfig> = {
    transactionsIn: {
        displayName: 'Transactions (In)',
        summaryColumns: [
            {
                label: 'COST',
                mockValue: 25000.5,
            },
        ],
    },
    transactionsOut: {
        displayName: 'Transactions (Out)',
        summaryColumns: [
            {
                label: 'PROCEEDS SALE',
                mockValue: 26410.28,
            },
        ],
    },
    transactionMatching: {
        displayName: 'Transaction Matching',
        summaryColumns: [
            {
                label: 'PROCEEDS SALE',
                mockValue: 26410.28,
            },
            {
                label: 'COST',
                mockValue: 25000.5,
            },
            {
                label: 'GAIN/LOSS',
                mockValue: 1410.78,
            },
        ],
    },
    transfers: {
        displayName: 'Transfers',
        summaryColumns: [],
    },
};

export const get8949TypeDisplayName = (tab: _8949Tab): string => {
    return TAB_CONFIG[tab].displayName;
};

export const get8949Summary = (tab: _8949Tab): SummaryColumn[] => {
    return TAB_CONFIG[tab].summaryColumns;
};

import axios from 'axios';
import { parseUnits } from 'ethers/lib/utils';
import { CreateClaimFields } from '../components/modals/create-claim-modal/create-claim-modal';
import { ClaimType } from '../data-lib/data-model';
import { ClaimCreatedEvent } from '../data-lib/domain/bulla-claim-domain';
import { InstantPaymentEvent } from '../data-lib/domain/bulla-instant-pay-domain';
import { getClaimCreatedEvents, getInstantPaymentEvents, getSwapCreatedEvents } from '../data-lib/dto/event-filters';
import { findEventLogs, getUniqueEventId, TransactionResult } from '../data-lib/dto/events-dto';
import { EthAddress, addressEquality, weiToDisplayAmt } from '../data-lib/ethereum';
import { dateToInt } from '../data-lib/helpers';
import { ChainId, TokenInfo } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints, getBase64 } from '../tools/common';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';
import { CreatePaymentFields } from '../components/modals/create-claim-modal/create-payment-modal';
import { CreateOrderParams } from './useBullaSwap';
import { shortAddress } from '../components/base/address-label';
import { useTokenRepo } from './useTokenRepo';

const sendSwapEmail = async (body: string, actingWallet: EthAddress, isGnosisSafe: boolean, connectedNetwork: ChainId) => {
    const gnosisSafeQueryArgs = isGnosisSafe ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    try {
        let res = await axios.post(`${endpoints.emailApi}/sendSwapEmail/${actingWallet}${gnosisSafeQueryArgs}`, body, {
            withCredentials: true,
            headers: { 'content-type': 'application/json' },
        });

        return res.status === 202;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

const sendOffchainEmail = async (body: string, actingWallet: EthAddress, isGnosisSafe: boolean, connectedNetwork: ChainId) => {
    const gnosisSafeQueryArgs = isGnosisSafe ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    try {
        let res = await axios.post(`${endpoints.emailApi}/sendEmail/${actingWallet}${gnosisSafeQueryArgs}`, body, {
            withCredentials: true,
            headers: { 'content-type': 'application/json' },
        });

        return res.status === 202;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

const sendEmail = async (body: string, actingWallet: EthAddress, isGnosisSafe: boolean, connectedNetwork: ChainId) => {
    const gnosisSafeQueryArgs = isGnosisSafe ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    try {
        let res = await axios.post(`${endpoints.emailApi}/sendEmail/${actingWallet}${gnosisSafeQueryArgs}`, body, {
            withCredentials: true,
            headers: { 'content-type': 'application/json' },
        });

        return res.status;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const useEmail = () => {
    const {
        connectedNetworkConfig: { name: networkName },
        connectedNetwork,
    } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();

    type OffchainInvoiceEmailPros = {
        emailAddress: string;
        claimType: ClaimType;
        amount: string;
        dueBy: Date;
        tokenSymbol: string;
        payMeLink: string;
        network: number;
        description?: string;
        ccAddresses?: string[];
        file?: File;
    };

    const sendOffchainInvoiceEmail = async ({
        emailAddress,
        claimType,
        amount,
        dueBy,
        tokenSymbol,
        payMeLink,
        description,
        ccAddresses,
        file,
        network,
    }: OffchainInvoiceEmailPros): Promise<boolean> => {
        const webhookPayload = JSON.stringify({
            emailAddress,
            claimType,
            dueBy: dateToInt(dueBy),
            claimAmount: amount,
            currency: tokenSymbol,
            chainId: network,
            description,
            payMeLink,
            ccAddresses,
            file: file ? { name: file.name, base64: await getBase64(file) } : undefined,
        });
        return sendOffchainEmail(webhookPayload, actingWallet, !!connectedSafeAddress, connectedNetwork);
    };

    const sendClaimCreationEmail = ({
        emailAddress,
        claimType,
        amount,
        dueBy,
        tokenSymbol,
        description,
        ccAddresses,
        network,
        recipient,
        id,
        __typename,
    }: {
        id: string;
        emailAddress?: string;
        claimType: ClaimType;
        creditor: EthAddress;
        debtor: EthAddress;
        amount: string;
        dueBy?: Date;
        tokenSymbol: string;
        network: number;
        description: string;
        recipient: string;
        ccAddresses?: string[];
        __typename: (ClaimCreatedEvent | InstantPaymentEvent)['__typename'];
    }) => {
        const idPayload = __typename == 'ClaimCreatedEvent' ? { claimId: id } : { instantPaymentId: id };
        const webhookPayload = JSON.stringify({
            emailAddress,
            claimType,
            dueBy: claimType == 'Invoice' && !!dueBy ? dateToInt(dueBy) : undefined,
            claimAmount: amount,
            currency: tokenSymbol,
            chainId: network,
            description,
            recipient,
            ccAddresses,
            ...idPayload,
        });
        sendEmail(webhookPayload, actingWallet, !!connectedSafeAddress, connectedNetwork);
    };

    return {
        sendClaimCreationEmail,
        sendOffchainInvoiceEmail,
    };
};

export const isPaymentClaim = (
    claim: CreatePaymentFields | Omit<CreateClaimFields, 'attachment' | 'tags' | 'instantPayment'>,
): claim is CreatePaymentFields => {
    return 'paymentAmount' in claim;
};

type EmailClaimData = Omit<CreateClaimFields, 'attachment' | 'tags' | 'instantPayment'> & {
    emailDescription?: string;
};

export const useSendClaimEmail = () => {
    const { sendClaimCreationEmail } = useEmail();
    const { connectedNetwork } = useWeb3();
    const queryAddress = useActingWalletAddress();
    return (claimType: ClaimType, claims: EmailClaimData[] | CreatePaymentFields[], transactionResult: TransactionResult) => {
        const claimCreatedEvents = findEventLogs(transactionResult.events, 'ClaimCreatedEvent');
        const instantPaymentEvents = findEventLogs(transactionResult.events, 'InstantPaymentEvent');
        const claimEvents = getClaimCreatedEvents(claimCreatedEvents);
        const paymentEvents = getInstantPaymentEvents(instantPaymentEvents);
        const claimsByCreditorDebtorPair = claims.reduce<Record<string, typeof claims[number]>>((acc, claim) => {
            const [creditor, debtor] = claimType == 'Payment' ? [claim.recipient, queryAddress] : [queryAddress, claim.recipient];
            return {
                ...acc,
                [`${creditor}${debtor}${claim.description}${parseUnits(
                    isPaymentClaim(claim) ? claim.paymentAmount : claim.claimAmount,
                    claim.token.decimals,
                ).toString()}`.toLowerCase()]: claim,
            };
        }, {});

        [
            ...claimEvents.map(claimEvent => ({ ...claimEvent, id: claimEvent.tokenId })),
            ...paymentEvents.map(paymentEvent => ({
                ...paymentEvent,
                creditor: paymentEvent.to,
                debtor: paymentEvent.from,
                id: getUniqueEventId({ txHash: paymentEvent.txHash, logIndex: paymentEvent.logIndex }),
                claimAmount: paymentEvent.amount,
            })),
        ].forEach(claimEvent => {
            const key = `${claimEvent.creditor}${claimEvent.debtor}${claimEvent.description}${claimEvent.claimAmount}`.toLowerCase();
            const { emailAddress, token, description, emailCC, recipient } = claimsByCreditorDebtorPair[key];
            const claim = claimsByCreditorDebtorPair[key];
            const claimAmount = isPaymentClaim(claimsByCreditorDebtorPair[key])
                ? (claimsByCreditorDebtorPair[key] as CreatePaymentFields).paymentAmount
                : (claimsByCreditorDebtorPair[key] as CreateClaimFields).claimAmount;

            const hybridEmailDescription = !isPaymentClaim(claim) ? claim.emailDescription : undefined;

            if (!!emailAddress || !!emailCC) {
                sendClaimCreationEmail({
                    ...claimEvent,
                    tokenSymbol: token.symbol,
                    amount: claimAmount,
                    claimType,
                    network: connectedNetwork,
                    emailAddress,
                    description: hybridEmailDescription || description,
                    ccAddresses: !!emailCC ? [emailCC] : undefined,
                    recipient,
                });
            }
        });

        return claimEvents;
    };
};

export const useSendSwapEmail = () => {
    const { connectedNetwork } = useWeb3();
    const queryAddress = useActingWalletAddress();
    const { getTokenByChainIdAndAddress } = useTokenRepo();

    return (order: CreateOrderParams & { emailAddress: string }, transactionResult: TransactionResult, isGnosisSafe: boolean) => {
        const swapCreatedEvents = findEventLogs(transactionResult.events, 'OrderCreatedEvent');
        const swapEvents = getSwapCreatedEvents(swapCreatedEvents);
        const signerToken = getTokenByChainIdAndAddress(connectedNetwork)(order.signerToken.toLowerCase()).token;
        const senderToken = getTokenByChainIdAndAddress(connectedNetwork)(order.senderToken.toLowerCase()).token;

        const signerTokenSymbol = signerToken.symbol;
        const senderTokenSymbol = senderToken.symbol;

        const signerAmountDisplay = weiToDisplayAmt({ amountWei: order.signerAmount, token: signerToken }).toString();
        const senderAmountDisplay = weiToDisplayAmt({ amountWei: order.senderAmount, token: senderToken }).toString();

        const webhookPayload = JSON.stringify({
            orderId: swapEvents[0].order.orderId.toString(),
            expiry: Number(order.expiry),
            signerWallet: shortAddress(order.signerWallet, 12),
            signerToken: signerTokenSymbol,
            signerAmount: signerAmountDisplay,
            senderWallet: shortAddress(order.senderWallet, 12),
            senderToken: senderTokenSymbol,
            senderAmount: senderAmountDisplay,
            emailAddress: order.emailAddress,
            chainId: connectedNetwork,
        });

        return sendSwapEmail(webhookPayload, queryAddress, isGnosisSafe, connectedNetwork);
    };
};

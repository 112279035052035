import {
    Box,
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';
import { CloseModalButton } from '../../../components/modals/common';
import { OrangeButton, WhiteButton } from '../../../components/inputs/buttons';
import {
    AttachmentField,
    ChainSelector,
    ClaimAmountField,
    LinkTextField,
} from '../../../components/modals/create-claim-modal/create-claim-inputs';
import * as Yup from 'yup';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { SUPPORTED_NETWORKS } from '../../../data-lib/networks';
import { apply } from '../../../tools/common';
import { BullaItemAttachment } from '../../../components/modals/create-claim-modal/create-claim-modal';

type MyLinkModalProps = {
    isOpen: boolean;
    onClose: VoidFunction;
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').optional(),
    token: Yup.object().required('Token is required'),
});

export const MyLinkModal = ({ isOpen, onClose }: MyLinkModalProps) => {
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const { erc20sByChainId } = useTokenRepo();
    const isLoading = false; // TODO: add loading state

    // Initialize tokens
    const initialToken = connectedNetworkConfig.nativeCurrency.tokenInfo.token;

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={{
                        linkName: '',
                        yourName: '',
                        description: '',
                        chainId: connectedNetwork,
                        amount: '',
                        token: initialToken,
                        attachment: undefined,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        console.log(values);
                        // TODO: Handle link generation here
                        onClose();
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form placeholder={''}>
                            <ModalHeader display="flex" flexDirection="row">
                                <VStack align="flex-start" spacing={0}>
                                    <Text color="heading" fontWeight={'700'} fontSize="20px">
                                        Create new link
                                    </Text>
                                    <Text fontSize={'15px'} fontWeight={'400'} color="gray.600">
                                        Create a shareable link for anyone to send you payment in over 20+ cryptocurrencies.
                                    </Text>
                                </VStack>
                            </ModalHeader>

                            <Box>
                                <Divider width="100%" sx={{ height: '0.5px' }} />
                            </Box>

                            <ModalBody>
                                <VStack spacing="4">
                                    <Field name="linkName">
                                        {({ field }: FieldProps) => (
                                            <LinkTextField
                                                {...{
                                                    field,
                                                    setFieldValue,
                                                    error: errors.linkName,
                                                    touched: touched.linkName,
                                                    label: 'Link name',
                                                    isDisabled: isLoading,
                                                    placeholder: 'How should we name this link?',
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <Field name="yourName">
                                        {({ field }: FieldProps) => (
                                            <LinkTextField
                                                {...{
                                                    field,
                                                    setFieldValue,
                                                    error: errors.yourName,
                                                    touched: touched.yourName,
                                                    label: 'Your name',
                                                    isDisabled: isLoading,
                                                    placeholder: 'This will show up in user’s payment dialog',
                                                }}
                                            />
                                        )}
                                    </Field>

                                    <Field name="chainId">
                                        {({ field }: FieldProps) => (
                                            <Box alignSelf="flex-start">
                                                <Text mb={2} fontWeight="500">
                                                    Network
                                                </Text>
                                                <ChainSelector
                                                    chainId={field.value}
                                                    selectableChains={SUPPORTED_NETWORKS}
                                                    onChainSelected={chainId => setFieldValue('chainId', chainId)}
                                                    width="100%"
                                                />
                                            </Box>
                                        )}
                                    </Field>

                                    <Field name="amount">
                                        {({ field }: FieldProps) => (
                                            <ClaimAmountField
                                                {...{
                                                    claimType: 'Invoice',
                                                    networkOverride: connectedNetwork,
                                                    field,
                                                    isDisabled: false,
                                                    includeNativeToken: true,
                                                    error: errors.amount,
                                                    touched: touched.amount,
                                                    setAmount: apply(setFieldValue, 'amount'),
                                                    setToken: apply(setFieldValue, 'token'),
                                                    amount: values.amount,
                                                    token: values.token,
                                                    label: 'Token',
                                                    disableBalanceLabels: true,
                                                }}
                                            />
                                        )}
                                    </Field>

                                    <Field name="description">
                                        {({ field }: FieldProps) => (
                                            <LinkTextField
                                                {...{
                                                    field,
                                                    setFieldValue,
                                                    error: errors.description,
                                                    touched: touched.description,
                                                    label: 'Shared description',
                                                    isDisabled: isLoading,
                                                    required: false,
                                                    placeholder: 'This description will show up in user’s payment dialog',
                                                }}
                                            />
                                        )}
                                    </Field>

                                    <Field name="attachment">
                                        {({ field }: FieldProps) => (
                                            <AttachmentField
                                                field={field}
                                                amount={values.amount}
                                                description={values.description}
                                                recipient={values.linkName}
                                                tokenSymbol={values.token.symbol}
                                                type={'Invoice'}
                                                label="Attachment"
                                                transactionPending={isLoading}
                                                attachment={values.attachment}
                                                setAttachment={(file: BullaItemAttachment | undefined) => setFieldValue('attachment', file)}
                                                dragAndDropOnly
                                            />
                                        )}
                                    </Field>
                                </VStack>
                            </ModalBody>

                            <Box mt={4}>
                                <Divider width="100%" sx={{ height: '0.5px' }} />
                            </Box>

                            <ModalFooter>
                                <HStack w="100%" spacing={4}>
                                    <WhiteButton onClick={onClose} h="12" w="50%">
                                        Cancel
                                    </WhiteButton>
                                    <OrangeButton type="submit" w="50%">
                                        Create
                                    </OrangeButton>
                                </HStack>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>

                <CloseModalButton onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};

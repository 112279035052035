import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonProps,
    Checkbox,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { NETWORKS } from '../../../data-lib/networks';
import { fillToCount, toUSD } from '../../../tools/common';
import { ExportFileFormat, handleExport, toReportDateTimeString } from '../../../tools/excelExport';
import { CloseModalButton } from '../common';
import { toLedgerAccountNumber } from './ledger-account';
import { DomainCashDisbursement, DomainClaimLedgerEntry, DomainPaymentLedgerEntry } from './ledger-export-success-card';

type ExportFileFormatSelectorProps = {
    onFormatSelected: (format: ExportFileFormat) => void;
    selectedFormat: ExportFileFormat;
};

export const ExportFileFormatSelector = ({
    onFormatSelected,
    selectedFormat,
    ...props
}: ExportFileFormatSelectorProps & Partial<ButtonProps>) => {
    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        bg="white"
                        border="1px solid"
                        borderColor={'inherit'}
                        {...props}
                    >
                        <Text textStyle="noWrap" fontWeight="500">
                            {selectedFormat.toUpperCase()}
                        </Text>
                    </MenuButton>
                    <MenuList display={isOpen ? 'block' : 'none'} borderRadius="4" overflowY={'auto'} maxH="150px">
                        {(['csv', 'excel'] as ExportFileFormat[]).map(format => (
                            <MenuItem key={format} onClick={() => onFormatSelected(format)}>
                                <Text textStyle="noWrap" fontWeight="500">
                                    {format.toUpperCase()}
                                </Text>
                            </MenuItem>
                        ))}
                    </MenuList>
                </>
            )}
        </Menu>
    );
};

export type LedgerExportModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    allLedgerEntries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry)[];
    cashDisbursements: DomainCashDisbursement[];
};

type SelectedExportTypes = {
    cashIn: boolean;
    cashOut: boolean;
    journalEntries: boolean;
};

type ExportType = keyof SelectedExportTypes;

const toDisplayText = (exportType: ExportType) => {
    switch (exportType) {
        case 'cashIn':
            return 'Cash In';
        case 'cashOut':
            return 'Cash Out';
        case 'journalEntries':
            return 'Journal Entries';
    }
};

const PAYMENT_EXPORT_HEADERS = [
    'Date',
    'From',
    'To',
    'Chain Id',
    'Chain',
    'Token Address',
    'Token Symbol',
    'Token Amount',
    'USD Mark',
    'USD Amount',
    'Tx Hash',
];

const cashDisbursementsToExportRow = (entry: DomainCashDisbursement) => [
    toReportDateTimeString(entry.timestamp),
    entry.from,
    entry.to,
    entry.chainId,
    NETWORKS[entry.chainId].label,
    entry.token.token.address,
    entry.token.token.symbol,
    toUSD(entry.displayAmount, true).replace('$', '').replace(',', ''),
    entry.usdMark !== null ? `${entry.usdMark}` : 'N/A',
    entry.usdValue !== null ? `${entry.usdValue}` : 'N/A',
    entry.txHash,
];

const JOURNAL_ENTRY_EXPORT_HEADERS = [
    'Date',
    'Creditor',
    'Debtor',
    'Instant Payment Id',
    'Claim Id',
    'Description',
    'Action',
    'Chain Id',
    'Chain',
    'Token Address',
    'Token Symbol',
    'Token Amount',
    'Usd Mark',
    'Usd Amount',
    'DR',
    'CR',
    'Tx Hash',
];

const claimEntryToExportRow = (entry: DomainClaimLedgerEntry) => [
    toReportDateTimeString(entry.timestamp),
    entry.creditor,
    entry.debtor,
    '',
    entry.claimId,
    entry.description,
    entry.eventName,
    entry.chainId,
    NETWORKS[entry.chainId].label,
    entry.token.token.address,
    entry.token.token.symbol,
    toUSD(entry.displayAmount, true).replace('$', '').replace(',', ''),
    entry.usdMark !== null ? `${entry.usdMark}` : 'N/A',
    entry.usdValue !== null ? `${entry.usdValue}` : 'N/A',
    ...fillToCount(entry.adjustments.dr, 1).map(text => toLedgerAccountNumber(text)),
    ...fillToCount(entry.adjustments.cr, 1).map(text => toLedgerAccountNumber(text)),
    entry.txHash,
];

const paymentEntryToExportRow = (entry: DomainPaymentLedgerEntry) => [
    toReportDateTimeString(entry.timestamp),
    entry.to,
    entry.from,
    entry.instantPaymentId,
    '',
    entry.description,
    !!entry.instantPaymentId ? 'Instant Payment' : 'Payment',
    entry.chainId,
    NETWORKS[entry.chainId].label,
    entry.token.token.address,
    entry.token.token.symbol,
    toUSD(entry.displayAmount, true).replace('$', '').replace(',', ''),
    entry.usdMark !== null ? `${entry.usdMark}` : 'N/A',
    entry.usdValue !== null ? `${entry.usdValue}` : 'N/A',
    ...fillToCount(entry.adjustments.dr, 1).map(text => toLedgerAccountNumber(text)),
    ...fillToCount(entry.adjustments.cr, 1).map(text => toLedgerAccountNumber(text)),
    entry.txHash,
];

export const LedgerExportModal = ({ triggerElement, allLedgerEntries, cashDisbursements }: LedgerExportModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [format, setFormat] = React.useState<ExportFileFormat>('csv');

    const [selectedExportTypes, setSelectedExportTypes] = React.useState<SelectedExportTypes>({
        cashIn: false,
        cashOut: false,
        journalEntries: false,
    });

    const onClick = async () => {
        setInProgress(true);
        const promises = [];

        if (selectedExportTypes.cashIn) {
            const inRows = cashDisbursements
                .filter(x => x.direction == 'Receivable' || x.direction == 'Transfer')
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map(entry => cashDisbursementsToExportRow(entry));

            promises.push(handleExport(format, 'ledger-cash-in', PAYMENT_EXPORT_HEADERS, inRows));
        }

        if (selectedExportTypes.cashOut) {
            const outRows = cashDisbursements
                .filter(x => x.direction == 'Payable' || x.direction == 'Transfer')
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map(entry => cashDisbursementsToExportRow(entry));

            promises.push(handleExport(format, 'ledger-cash-out', PAYMENT_EXPORT_HEADERS, outRows));
        }

        if (selectedExportTypes.journalEntries) {
            const journalRows = allLedgerEntries
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map(x => (x.__typename == 'Claim' ? claimEntryToExportRow(x) : paymentEntryToExportRow(x)));

            promises.push(handleExport(format, 'journal-entries', JOURNAL_ENTRY_EXPORT_HEADERS, journalRows));
        }

        await Promise.all(promises);
        setInProgress(false);
        onClose();
    };

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size="md" closeOnEsc scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent py="4" px="2" maxH="80%">
                    <CloseModalButton onClose={onClose} />
                    <ModalHeader pb={6} pt={6}>
                        <Text color="icon_dark" textStyle="labelLg">
                            Export
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <Stack>
                            {(Object.keys(selectedExportTypes) as (keyof SelectedExportTypes)[]).map(exportType => (
                                <HStack key={exportType}>
                                    <Checkbox
                                        isChecked={selectedExportTypes[exportType]}
                                        onChange={_ => setSelectedExportTypes(prev => ({ ...prev, [exportType]: !prev[exportType] }))}
                                    />
                                    <Text fontWeight={'bold'}>{toDisplayText(exportType)}</Text>
                                </HStack>
                            ))}
                            <HStack key={'format'} mt="4">
                                <ExportFileFormatSelector selectedFormat={format} onFormatSelected={setFormat} />
                                <Spacer />
                            </HStack>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                            Cancel
                        </Button>
                        <Spacer />
                        <Button px="8" py="6" fontWeight="500" colorScheme="accent" type="submit" isLoading={inProgress} onClick={onClick}>
                            Export
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

import {
    Box,
    Collapse,
    Flex,
    Grid,
    GridItem,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';
import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getInstantPaymentEvents } from '../../../data-lib/dto/event-filters';
import { getUniqueEventId } from '../../../data-lib/dto/events-dto';
import { EthAddress, addressEquality } from '../../../data-lib/ethereum';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS, TokenDto } from '../../../data-lib/networks';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useOpenInstantPaymentDetails } from '../../../hooks/useClaimDetailDisclosure';
import { getBackendTxIdForItem, useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useInstantPayment } from '../../../hooks/useInstantPayment';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useMembership } from '../../../hooks/useMembership';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { useAppState } from '../../../state/app-state';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { openCreateClaimParams, useUIState } from '../../../state/ui-state';
import { apply } from '../../../tools/common';
import { clearSessionStorage, STORAGE_KEYS } from '../../../tools/storage';
import { ChakraCompose } from '../../../tools/types';
import { InstantPaymentButton } from '../../display/claim-action-buttons';
import { SecondaryButton, SwitchNetworksButton } from '../../inputs/buttons';
import { CloseModalButton, LabelText, ModalFooterWithShadow } from '../common';
import {
    AccountTagField,
    AttachmentField,
    ChainSelector,
    ClaimAmountField,
    claimAmountValidationSchema,
    ClaimDescriptionField,
    ClaimNotesField,
    disabledInputProps,
    EmailField,
    RecipientField,
} from './create-claim-inputs';
import { PastPaymentsTable } from './create-link-handler';
import * as Yup from 'yup';

import { isAddress as validEthereumAddress } from '@ethersproject/address';
import { NewAddressAlert, BullaItemAttachment, ccEmailErrorSchema, recipientErrorSchema } from './create-claim-modal';
import { addDaysToToday } from '../../../data-lib/helpers';
import { useSendClaimEmail } from '../../../hooks/useEmail';

export type CreatePaymentFields = {
    paymentAmount: string;
    token: TokenDto;
    recipient: string;
    description: string;
    dueBy: Date;
    tags: string[];
    attachment?: BullaItemAttachment | undefined;
    notes?: string;
    emailAddress?: string;
    emailMessage?: string;
    emailCC?: string;
    customCreditorAndDebtor?: { creditor: string; debtor: string };
};

export const emptyPaymentFields = {
    paymentAmount: '0',
    recipient: '',
    description: '',
    dueBy: addDaysToToday(30),
    tags: [] as string[],
    attachment: undefined,
    notes: '',
    emailAddress: '',
    emailMessage: '',
    emailCC: '',
};

export const paymentSchemaFields: any = {
    description: Yup.string(),
    emailAddress: Yup.string().email('Invalid email address'),
    emailMessage: Yup.string().optional(),
    emailCC: Yup.string().email('Invalid email address').optional(),
};

const errorMessageSchemaPayment = (senderAddress: EthAddress, isNftInvoice?: boolean) =>
    Yup.object().shape({
        recipient: recipientErrorSchema(senderAddress)
            .required('Required')
            .test('is-valid-address', 'Invalid wallet address', value => validEthereumAddress(value || '')),
        paymentAmount: claimAmountValidationSchema,
        dueBy: Yup.date().typeError('Invalid date'),
        ...paymentSchemaFields,
        description: paymentSchemaFields.description,
        emailCC: ccEmailErrorSchema(),
        emailAddress: paymentSchemaFields.emailAddress.optional(),
    });

type CreatePaymentModalProps = ChakraCompose & {
    triggerElement?: (onOpen: () => void) => React.ReactNode;
    header?: React.ReactNode;
    defaults?: Partial<typeof emptyPaymentFields> & { network?: ChainId; token?: TokenDto };
    fromLink?: boolean;
};

export const CreatePaymentModal = ({ triggerElement, header, defaults, fromLink }: CreatePaymentModalProps) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const modalBodyRef = useRef<HTMLDivElement>(null);
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const { search } = useLocation();
    const navigate = useNavigate();
    const canChangeNetwork = useCanChangeNetwork();
    const { transactionPending } = useUIState();
    const { readyToTransact } = useAppState();
    const senderAddress = useActingWalletAddress();
    const { erc20sByChainId } = useTokenRepo();
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: !triggerElement });
    const openInstantPayment = useOpenInstantPaymentDetails();
    const [creatingInstantPayment, { createInstantPayment }] = useInstantPayment();
    const isMobile = useIsMobile();
    const { changeNetwork } = useOnboard();
    const isLoading = creatingInstantPayment;
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const { safeInfo } = useGnosisSafe();
    const membership = useMembership();
    const sendClaimEmail = useSendClaimEmail();

    const initialDefaultTag = defaults?.tags ?? [];
    const initialToken = (defaults?.network ? NETWORKS[defaults.network] : connectedNetworkConfig).nativeCurrency.tokenInfo.token;
    const { paidBullaItemsWithPayments } = useGlobalUserData('exclude-originating-claims');
    const pastPaymentsToRecipient =
        defaults?.recipient && paidBullaItemsWithPayments.filter(p => addressEquality(p.creditor, defaults.recipient!)).slice(0, 2);

    const handleSend = async (formikInputs: CreatePaymentFields) => {
        const values = {
            ...formikInputs,
            tags: formikInputs.tags.filter(x => x !== ''),
        };
        const result = await createInstantPayment(values);

        const sendNotes = async (id: string, notes: string) => {
            await saveExternalTransactions({
                [getBackendTxIdForItem(id, connectedNetwork)]: {
                    id,
                    notes: notes,
                    chainId: connectedNetwork,
                },
            });
        };
        if (result) {
            try {
                sendClaimEmail('Payment', [values], result);
                const paymentEvents = getInstantPaymentEvents(result.events);
                const id =
                    paymentEvents.length > 0
                        ? getUniqueEventId({ txHash: paymentEvents[0].txHash, logIndex: paymentEvents[0].logIndex })
                        : undefined;
                if (id && formikInputs.notes && formikInputs.notes !== '') {
                    await sendNotes(id, formikInputs.notes);
                }
                return { id, success: true };
            } catch (e) {
                console.warn('error sending claim email and getting events. Cant save notes', e);
                return { success: true };
            }
        }
        return { success: false };
    };

    const closeModal = () => {
        if (isOpen) onClose();
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        navigate({ search: `?${params.toString()}` }, { replace: true });
        clearSessionStorage(STORAGE_KEYS.capturedParams);
    };

    const handleComplete = (resetForm: () => void) => {
        closeModal();
        resetForm();
    };

    return (
        <>
            {triggerElement ? triggerElement(onOpen) : null}
            <Modal
                isCentered={!isMobile}
                isOpen={isOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                closeOnOverlayClick={false}
                closeOnEsc={false}
                size={isMobile ? 'full' : '2xl'}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <Formik
                    initialValues={{
                        ...emptyPaymentFields,
                        ...defaults,
                        token: defaults?.token ?? initialToken,
                        paymentAmount: defaults?.paymentAmount ?? '',
                        tags: initialDefaultTag,
                    }}
                    validate={values => {
                        try {
                            validateYupSchema(values, errorMessageSchemaPayment(senderAddress, true), true);
                            return {};
                        } catch (err) {
                            return yupToFormErrors(err);
                        }
                    }}
                    validateOnMount={!!defaults}
                    validateOnBlur
                    onSubmit={async (fieldValues, { resetForm }) => {
                        const { success, id } = await handleSend({
                            ...fieldValues,
                            paymentAmount: fieldValues.paymentAmount,
                        });
                        if (success && isOpen) {
                            handleComplete(resetForm);
                            if (id) openInstantPayment(id, connectedNetwork);
                        }
                    }}
                >
                    {({ errors, touched, setFieldValue, isValid, values, setFieldTouched, dirty, setStatus }) => {
                        const isDisabled = !(dirty || !!defaults) || !isValid || isLoading || !readyToTransact;

                        const modalModalWidth = useBreakpointValue({ base: 'md', md: '60%' }, { ssr: false });
                        const modalColumns = useBreakpointValue({ base: '1fr', md: '1fr 1fr' }, { ssr: false });

                        return (
                            <>
                                <Form placeholder={''}>
                                    <ModalContent py="4" px="2" bg={'white'} ref={modalContentRef} maxW={modalModalWidth}>
                                        <ModalHeader display="flex">
                                            {header ?? (
                                                <Text color="heading" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                                                    Create New Payment
                                                </Text>
                                            )}
                                        </ModalHeader>
                                        <CloseModalButton onClose={closeModal} />
                                        <ModalBody ref={modalBodyRef} py="0">
                                            <Grid templateColumns={modalColumns} columnGap="6" rowGap="3" mb={'6'}>
                                                <GridItem key={'left'}>
                                                    <Stack spacing="3">
                                                        {!safeInfo && (
                                                            <Box>
                                                                <LabelText pb="3">Chain</LabelText>
                                                                <ChainSelector
                                                                    chainId={defaults?.network ?? connectedNetwork}
                                                                    isDisabled={isLoading || !canChangeNetwork || !!defaults?.network}
                                                                    selectableChains={SUPPORTED_NETWORKS}
                                                                    w="100%"
                                                                    textAlign={'left'}
                                                                    onChainSelected={changeNetwork}
                                                                    {...disabledInputProps}
                                                                />
                                                            </Box>
                                                        )}
                                                        <LabelText>Recipient Info</LabelText>
                                                        <Collapse
                                                            in={
                                                                (!!touched.recipient && !!values.recipient && !errors.recipient) ||
                                                                (fromLink && !!values.recipient)
                                                            }
                                                            unmountOnExit
                                                        >
                                                            <NewAddressAlert newAddress={values.recipient} />
                                                        </Collapse>
                                                        <HStack>
                                                            <Field name="recipient">
                                                                {({ field }: FieldProps) => (
                                                                    <RecipientField
                                                                        {...{
                                                                            field,
                                                                            initialValue: defaults?.recipient,
                                                                            isDisabled: transactionPending || !!defaults?.recipient,
                                                                            error: errors.recipient,
                                                                            touched: touched.recipient,
                                                                            setRecipient: apply(setFieldValue, field.name),
                                                                            setEmailAddress: apply(setFieldValue, 'emailAddress'),
                                                                            label: 'Recipient Address',
                                                                            dropdownModalRef: modalContentRef,
                                                                            fromLink,
                                                                            chainId: defaults?.network,
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </HStack>
                                                        <Box>
                                                            <Field name="paymentAmount">
                                                                {({ field }: FieldProps) => (
                                                                    <ClaimAmountField
                                                                        {...{
                                                                            claimType: 'Payment',
                                                                            networkOverride: defaults?.network,
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            includeNativeToken: true,
                                                                            error: errors.paymentAmount,
                                                                            touched: touched.paymentAmount,
                                                                            setAmount: apply(setFieldValue, 'paymentAmount'),
                                                                            setToken: apply(setFieldValue, 'token'),
                                                                            amount: values.paymentAmount,
                                                                            token: values.token,
                                                                            setFieldTouched,
                                                                            lockToken: !!defaults?.token,
                                                                            lockAmount: !!defaults?.paymentAmount,
                                                                            label: 'Token',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Box>
                                                        <Field name="description">
                                                            {({ field }: FieldProps) => (
                                                                <ClaimDescriptionField
                                                                    {...{
                                                                        field,
                                                                        error: errors.description,
                                                                        touched: touched.description,
                                                                        label: 'Description',
                                                                        isDisabled:
                                                                            transactionPending || (!!fromLink && !!defaults?.description),
                                                                        required: false,
                                                                        fromLink: fromLink,
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Stack>
                                                </GridItem>
                                                <GridItem key="right">
                                                    <Stack spacing="3">
                                                        <LabelText>Additional Details</LabelText>

                                                        {!fromLink && (
                                                            <Field name="attachment">
                                                                {({ field }: FieldProps) => (
                                                                    <AttachmentField
                                                                        field={field}
                                                                        amount={values.paymentAmount}
                                                                        description={values.description}
                                                                        recipient={values.recipient}
                                                                        tokenSymbol={values.token.symbol}
                                                                        type={'Payment'}
                                                                        label="Attachment"
                                                                        transactionPending={transactionPending}
                                                                        attachment={values.attachment}
                                                                        setAttachment={(file: BullaItemAttachment | undefined) =>
                                                                            setFieldValue('attachment', file)
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        {!fromLink && (
                                                            <Field name="tags">
                                                                {({ field }: FieldProps) => (
                                                                    <AccountTagField
                                                                        {...{
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            error: errors.tags,
                                                                            touched: touched.tags,
                                                                            setTags: apply(setFieldValue, field.name),
                                                                            setStatus,
                                                                            label: 'Categories',
                                                                            creatingExpense: false,
                                                                            dropdownModalRef: modalContentRef,
                                                                            mb: '-2',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        {membership !== null && (
                                                            <Field name="notes">
                                                                {({ field }: FieldProps) => (
                                                                    <ClaimNotesField
                                                                        {...{
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            error: errors.notes,
                                                                            touched: touched.notes,
                                                                            label: 'Notes',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        <Field name="emailAddress">
                                                            {({ field }: FieldProps) => (
                                                                <Collapse in={!fromLink}>
                                                                    <EmailField
                                                                        {...{
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            error: errors.emailAddress,
                                                                            touched: touched.emailAddress,
                                                                            label: 'Recipient Email',
                                                                            required: false,
                                                                        }}
                                                                    />
                                                                </Collapse>
                                                            )}
                                                        </Field>
                                                        <Field name="emailCC" unmountOnExit>
                                                            {({ field }: FieldProps) => (
                                                                <EmailField
                                                                    {...{
                                                                        field,
                                                                        isDisabled: transactionPending,
                                                                        error: errors.emailCC,
                                                                        touched: touched.emailCC,
                                                                        label: 'Confirmation Email',
                                                                        required: false,
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        {fromLink && pastPaymentsToRecipient && (
                                                            <PastPaymentsTable
                                                                payments={pastPaymentsToRecipient}
                                                                readyToTransact={readyToTransact}
                                                                network={defaults?.network ?? connectedNetwork}
                                                                recipient={defaults.recipient!}
                                                            />
                                                        )}
                                                    </Stack>
                                                </GridItem>
                                            </Grid>
                                        </ModalBody>
                                        <ModalFooterWithShadow>
                                            <Spacer />
                                            {fromLink && (
                                                <Flex justifyContent={'flex-end'}>
                                                    <SecondaryButton
                                                        onClick={() => {
                                                            closeModal();
                                                            navigate('/');
                                                        }}
                                                        w="min-content"
                                                        mr="4"
                                                    >
                                                        Cancel
                                                    </SecondaryButton>
                                                </Flex>
                                            )}
                                            {defaults?.network !== undefined && connectedNetwork !== defaults.network ? (
                                                <SwitchNetworksButton requiredNetwork={defaults.network!} />
                                            ) : (
                                                <InstantPaymentButton
                                                    paymentInfo={[{ amount: values.paymentAmount, token: values.token }]}
                                                    isLoading={isLoading}
                                                    isDisabled={isDisabled}
                                                    w={['100%', 'inherit', 'inherit']}
                                                />
                                            )}
                                        </ModalFooterWithShadow>
                                    </ModalContent>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};

import _objectSpread from '@babel/runtime/helpers/objectSpread2';
import merge from 'lodash.merge';
import logLevel, { levels } from 'loglevel';

const log = logLevel.getLogger("http-helpers");
log.setLevel(levels.INFO);
let apiKey = "torus-default";
let embedHost = "";

// #region API Keys
const gatewayAuthHeader = "x-api-key";
const gatewayEmbedHostHeader = "x-embed-host";
let sentry = null;
const tracingOrigins = [];
const tracingPaths = [];
function enableSentryTracing(_sentry, _tracingOrigins, _tracingPaths) {
  sentry = _sentry;
  tracingOrigins.push(..._tracingOrigins);
  tracingPaths.push(..._tracingPaths);
}
function setEmbedHost(embedHost_) {
  embedHost = embedHost_;
}
function clearEmbedHost() {
  embedHost = "";
}
function getEmbedHost() {
  return embedHost;
}
function setAPIKey(apiKey_) {
  apiKey = apiKey_;
}
function clearAPIKey() {
  apiKey = "torus-default";
}
function getAPIKey() {
  return apiKey;
}

// #endregion

function setLogLevel(level) {
  log.setLevel(level);
}
async function fetchAndTrace(url, init) {
  let _url = null;
  try {
    _url = new URL(url);
  } catch (error) {}
  if (sentry && _url && (tracingOrigins.includes(_url.origin) || tracingPaths.includes(_url.pathname))) {
    const transaction = sentry.startTransaction({
      name: url
    });
    const span = transaction.startChild({
      op: "http"
    }); // This function returns a Span

    const response = await fetch(url, init);
    span.finish(); // Remember that only finished spans will be sent with the transaction

    transaction.finish(); // Finishing the transaction will send it to Sentry

    return response;
  }
  return fetch(url, init);
}
function getApiKeyHeaders() {
  const headers = {};
  if (apiKey) headers[gatewayAuthHeader] = apiKey;
  if (embedHost) headers[gatewayEmbedHostHeader] = embedHost;
  return headers;
}
function debugLogResponse(response) {
  log.info(`Response: ${response.status} ${response.statusText}`);
  log.info(`Url: ${response.url}`);
}
function logTracingHeader(response) {
  const tracingHeader = response.headers.get("x-web3-correlation-id");
  if (tracingHeader) log.info(`Request tracing with traceID = ${tracingHeader}`);
}
const promiseTimeout = async (ms, promise) => {
  let timeoutFunc = null;
  try {
    const timeout = new Promise((_resolve, reject) => {
      timeoutFunc = setTimeout(() => {
        reject(new Error(`Timed out in ${ms}ms`));
      }, ms);
    });
    const result = await Promise.race([promise, timeout]);
    // promise.race will return the first resolved promise
    // then we clear the timeout
    if (timeoutFunc != null) {
      clearTimeout(timeoutFunc);
    }
    return result;
  } catch (err) {
    // clear the timeout
    if (timeoutFunc != null) {
      clearTimeout(timeoutFunc);
    }
    // rethrow the original error
    throw err;
  }
};
const get = async function (url) {
  let options_ = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let customOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const defaultOptions = {
    mode: "cors",
    headers: {}
  };
  if (customOptions.useAPIKey) {
    defaultOptions.headers = _objectSpread(_objectSpread({}, defaultOptions.headers), getApiKeyHeaders());
  }
  const options = merge(defaultOptions, options_, {
    method: "GET"
  });
  const response = await fetchAndTrace(url, options);
  if (response.ok) {
    const responseContentType = response.headers.get("content-type");
    if (responseContentType !== null && responseContentType !== void 0 && responseContentType.includes("application/json")) {
      return response.json();
    }
    return response.text();
  }
  debugLogResponse(response);
  throw response;
};
const post = function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options_ = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let customOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const defaultOptions = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  };
  if (customOptions.useAPIKey) {
    defaultOptions.headers = _objectSpread(_objectSpread({}, defaultOptions.headers), getApiKeyHeaders());
  }
  const options = merge(defaultOptions, options_, {
    method: "POST"
  });

  // deep merge changes the structure of form data and url encoded data ,
  // so we should not deepmerge body data
  if (customOptions.isUrlEncodedData) {
    // for multipart request browser/client will add multipart content type
    // along with multipart boundary , so for multipart request send
    // content-type: undefined or send with multipart boundary if already known
    options.body = data;
    // If url encoded data, this must not be the content type
    if (options.headers["Content-Type"] === "application/json; charset=utf-8") delete options.headers["Content-Type"];
  } else {
    options.body = JSON.stringify(data);
  }
  return promiseTimeout(customOptions.timeout || 60000, fetchAndTrace(url, options).then(response => {
    if (customOptions.logTracingHeader) {
      logTracingHeader(response);
    }
    if (response.ok) {
      const responseContentType = response.headers.get("content-type");
      if (responseContentType !== null && responseContentType !== void 0 && responseContentType.includes("application/json")) {
        return response.json();
      }
      return response.text();
    }
    debugLogResponse(response);
    throw response;
  }));
};
const patch = async function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options_ = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let customOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const defaultOptions = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  };
  // for multipart request browser/client will add multipart content type
  // along with multipart boundary , so for multipart request send
  // content-type: undefined or send with multipart boundary if already known
  if (customOptions.useAPIKey) {
    defaultOptions.headers = _objectSpread(_objectSpread({}, defaultOptions.headers), getApiKeyHeaders());
  }
  const options = merge(defaultOptions, options_, {
    method: "PATCH"
  });
  // deep merge changes the structure of form data and url encoded data ,
  // so we should not deepmerge body data
  if (customOptions.isUrlEncodedData) {
    // for multipart request browser/client will add multipart content type
    // along with multipart boundary , so for multipart request send
    // content-type: undefined or send with multipart boundary if already known
    options.body = data;
    // If url encoded data, this must not be the content type
    if (options.headers["Content-Type"] === "application/json; charset=utf-8") delete options.headers["Content-Type"];
  } else {
    options.body = JSON.stringify(data);
  }
  const response = await fetchAndTrace(url, options);
  if (response.ok) {
    const responseContentType = response.headers.get("content-type");
    if (responseContentType !== null && responseContentType !== void 0 && responseContentType.includes("application/json")) {
      return response.json();
    }
    return response.text();
  }
  debugLogResponse(response);
  throw response;
};
const put = async function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options_ = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let customOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const defaultOptions = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  };
  // for multipart request browser/client will add multipart content type
  // along with multipart boundary , so for multipart request send
  // content-type: undefined or send with multipart boundary if already known
  if (customOptions.useAPIKey) {
    defaultOptions.headers = _objectSpread(_objectSpread({}, defaultOptions.headers), getApiKeyHeaders());
  }
  const options = merge(defaultOptions, options_, {
    method: "PUT"
  });
  // deep merge changes the structure of form data and url encoded data ,
  // so we should not deepmerge body data
  if (customOptions.isUrlEncodedData) {
    // for multipart request browser/client will add multipart content type
    // along with multipart boundary , so for multipart request send
    // content-type: undefined or send with multipart boundary if already known
    options.body = data;
    // If url encoded data, this must not be the content type
    if (options.headers["Content-Type"] === "application/json; charset=utf-8") delete options.headers["Content-Type"];
  } else {
    options.body = JSON.stringify(data);
  }
  const response = await fetchAndTrace(url, options);
  if (response.ok) {
    const responseContentType = response.headers.get("content-type");
    if (responseContentType !== null && responseContentType !== void 0 && responseContentType.includes("application/json")) {
      return response.json();
    }
    return response.text();
  }
  debugLogResponse(response);
  throw response;
};
const remove = async function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options_ = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let customOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const defaultOptions = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  };
  // for multipart request browser/client will add multipart content type
  // along with multipart boundary , so for multipart request send
  // content-type: undefined or send with multipart boundary if already known
  if (customOptions.useAPIKey) {
    defaultOptions.headers = _objectSpread(_objectSpread({}, defaultOptions.headers), getApiKeyHeaders());
  }
  const options = merge(defaultOptions, options_, {
    method: "DELETE"
  });
  if (customOptions.isUrlEncodedData) {
    // for multipart request browser/client will add multipart content type
    // along with multipart boundary , so for multipart request send
    // content-type: undefined or send with multipart boundary if already known
    options.body = data;
    // If url encoded data, this must not be the content type
    if (options.headers["Content-Type"] === "application/json; charset=utf-8") delete options.headers["Content-Type"];
  } else {
    options.body = JSON.stringify(data);
  }
  const response = await fetchAndTrace(url, options);
  if (response.ok) {
    const responseContentType = response.headers.get("content-type");
    if (responseContentType !== null && responseContentType !== void 0 && responseContentType.includes("application/json")) {
      return response.json();
    }
    return response.text();
  }
  debugLogResponse(response);
  throw response;
};
const generateJsonRPCObject = (method, parameters) => ({
  jsonrpc: "2.0",
  method,
  id: 10,
  params: parameters
});
const promiseRace = function (url, options) {
  let timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 60000;
  return Promise.race([get(url, options), new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new Error("timed out"));
    }, timeout);
  })]);
};

export { clearAPIKey, clearEmbedHost, enableSentryTracing, gatewayAuthHeader, gatewayEmbedHostHeader, generateJsonRPCObject, get, getAPIKey, getEmbedHost, patch, post, promiseRace, promiseTimeout, put, remove, setAPIKey, setEmbedHost, setLogLevel };

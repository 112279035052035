import { useLocation, useNavigate } from 'react-router-dom';
import { BullaLineItems, BullaSwapInfoWithUSDMark } from '../data-lib/data-model';
import { ChainId } from '../data-lib/networks';
import { openCreateClaimParams } from '../state/ui-state';

export const useOpenClaimDetails = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (tokenId: string, network: ChainId | string) => {
        const params = new URLSearchParams(search);
        console.log({ params, x: search });
        openCreateClaimParams.forEach(param => params.delete(param));
        params.set('claim', tokenId);
        params.set('network', network.toString());
        navigate({ search: `?${params.toString()}` }, { replace: true });
    };
};

export const useOpenLoanOffer = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (loanOfferId: string, network: ChainId | string, useFinancingPath: boolean = true) => {
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        params.set('loanOffer', loanOfferId);
        params.set('network', network.toString());
        const pathname = useFinancingPath ? '/financing' : undefined;
        navigate({ search: `?${params.toString()}`, pathname }, { replace: true });
    };
};

export const useOpenInstantPaymentDetails = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (itemId: string, network: ChainId | string) => {
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        params.set('instantPayment', itemId);
        params.set('network', network.toString());
        navigate({ search: `?${params.toString()}` }, { replace: true });
    };
};

export const useOpenExternalTransactionDetails = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (itemId: string, network: ChainId | string) => {
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        params.set('externalTransaction', itemId);
        params.set('network', network.toString());
        navigate({ search: `?${params.toString()}` }, { replace: true });
    };
};

export const useOpenOffchainInvoiceDetails = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (itemId: string, chainId: ChainId | string) => {
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        params.set('invoice', itemId);
        params.set('network', chainId.toString());
        navigate({ search: `?${params.toString()}` }, { replace: true });
    };
};

export const useCloseBullaItem = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return () => {
        const params = new URLSearchParams(search);
        params.delete('claim');
        params.delete('instantPayment');
        params.delete('loanOffer');
        params.delete('externalTransaction');
        params.delete('network');
        params.delete('swap');
        params.delete('invoice');
        params.delete('poolRedemptionId');
        params.delete('poolDepositId');
        const nextSearch = params.toString() === '' ? '' : `?${params.toString()}`;
        navigate({ search: nextSearch }, { replace: true });
    };
};

export const useCloseSpecificItem = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (itemType: 'claim' | 'instantPayment' | 'loanOffer' | 'externalTransaction', itemId: string) => {
        const params = new URLSearchParams(search);
        if (params.get(itemType) === itemId) {
            params.delete(itemType);
            params.delete('network');
            navigate({ search: `?${params.toString()}` }, { replace: true });
        }
    };
};

export const useOpenPoolTransaction = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (id: string, chainId: ChainId | string, type: 'poolDeposit' | 'poolRedemption') => {
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        if (type === 'poolDeposit') params.set('poolDepositId', id);
        if (type === 'poolRedemption') params.set('poolRedemptionId', id);
        params.set('network', chainId.toString());
        navigate({ search: `?${params.toString()}` }, { replace: true });
    };
};

export const useOpenSwapDetails = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    return (id: string, chainId: ChainId | string) => {
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        params.set('swap', id);
        params.set('network', chainId.toString());
        navigate({ search: `?${params.toString()}` }, { replace: true });
    };
};

export const useOpenBullaItem = () => {
    const openClaim = useOpenClaimDetails();
    const openInstantPayment = useOpenInstantPaymentDetails();
    const openExternalTransaction = useOpenExternalTransactionDetails();
    const openOffchainInvoice = useOpenOffchainInvoiceDetails();
    const openPoolTransaction = useOpenPoolTransaction();
    const openSwap = useOpenSwapDetails();

    return (item: BullaLineItems | BullaSwapInfoWithUSDMark) => {
        switch (item.__type) {
            case 'Claim':
                openClaim(item.id, item.chainId);
                break;
            case 'InstantPayment':
            case 'PendingInstantPayment':
                openInstantPayment(item.id, item.chainId);
                break;
            case 'OffchainInvoiceInfo':
                openOffchainInvoice(item.id, item.chainId);
                break;
            case 'ImportedExternalTransaction':
                openExternalTransaction(item.id, item.chainId);
                break;
            case 'PoolDeposit':
                openPoolTransaction(item.id, item.chainId, 'poolDeposit');
                break;
            case 'PoolRedemption':
                openPoolTransaction(item.id, item.chainId, 'poolRedemption');
                break;
            case 'Swap':
                openSwap(item.id, item.chainId);
                break;
        }
    };
};

import { Box, Heading, Input, Text } from '@chakra-ui/react';
import React from 'react';
import { OrangeButton } from '../../inputs/buttons';

interface ReportNameCardProps {
    image: React.ReactNode;
    welcomeMessage: string;
    explanation: string;
    reportName: string;
    setReportName: (name: string) => void;
    onStartExport: () => void;
    overrideButtonText?: string;
}

export const ReportNameCard: React.FC<ReportNameCardProps> = ({
    explanation,
    welcomeMessage,
    reportName,
    setReportName,
    onStartExport,
    overrideButtonText,
    image,
}) => {
    return (
        <Box maxW="400px" mx="auto" mt="8" textAlign="center" shadow={'xl'} p="5">
            <Box my="8" display="flex" justifyContent="center">
                {image}
            </Box>
            <Heading as="h2" size="md" mb="2">
                {welcomeMessage}
            </Heading>
            <Text mb="6">{explanation}</Text>
            <Input placeholder="Give your report a name" mb="8" value={reportName} onChange={e => setReportName(e.target.value)} />
            <OrangeButton width="full" onClick={onStartExport} isDisabled={!reportName}>
                {overrideButtonText ?? 'Start Export'}
            </OrangeButton>
        </Box>
    );
};

import { HStack, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, useBoolean } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Users } from 'phosphor-react';
import { CloseModalButton } from '../common';
import { IconBox } from '../common-reporting/progress-steps';

import { CreateOrangeButton, WhiteButton } from '../../inputs/buttons';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';

import _ from 'lodash';
import { useCompanyDetailsRepo } from '../../../hooks/useCompanyDetailsRepo';
import { useSessionStorage } from '../../../hooks/useStorage';
import {
    Fields,
    mapCompanyDetailsToFields,
    countAddedLines,
    emptyFields,
    mapFieldsToCompanyDetails,
    CompanyDetails,
} from '../../../pages/settings/company-details/common';
import {
    areDetailsReady,
    areDetailsLoading,
    EditButton,
    CompanyDetailsForm,
} from '../../../pages/settings/company-details/company-details-page';
import { STORAGE_KEYS } from '../../../tools/storage';
import { useBullaToast } from '../../base/toast';
import { CaughtError } from '../../../tools/types';

export const AddDetailsButton = ({ isLoading, isDisabled, w }: { isLoading: boolean; isDisabled: boolean; w?: string }) =>
    CreateOrangeButton('Add Company Details')({
        isDisabled: isDisabled,
        isLoading,
        type: 'submit',
        w: w ?? undefined,
    });

type CompanyDetailsModalProps = { isOpen: boolean; onClose: VoidFunction };

type SubmissionState = boolean | CaughtError;
type CompanyDetailsState = 'init' | 'fetching' | null | CompanyDetails | CaughtError;

export const CompanyDetailsModal = ({ isOpen, onClose }: CompanyDetailsModalProps) => {
    const actingAddress = useActingWalletAddress();
    const { getCompanyDetails, postCompanyDetails } = useCompanyDetailsRepo();
    const triggerSuccessToast = useBullaToast();

    const gridRef = useRef<HTMLDivElement>(null);
    const [companyDetails, setCompanyDetails] = useState<CompanyDetailsState>('init');
    const [submissionState, setSubmissionState] = useState<SubmissionState>(false);
    const [unsavedChanges, setUnsavedChanges, clearUnsavedChanges] = useSessionStorage<Fields | undefined>(
        `${actingAddress}:${STORAGE_KEYS.companyDetails}`,
    );
    const [editing, editFields] = useBoolean(!!unsavedChanges);

    const defaultFieldValues: Fields = areDetailsReady(companyDetails) ? mapCompanyDetailsToFields(companyDetails) : emptyFields;
    const initialValues: Fields = unsavedChanges ?? defaultFieldValues;
    const [_addedLineCount, setAddedLineCount] = React.useState(1);
    const [, addedLineCount] = [areDetailsReady(companyDetails) ? countAddedLines(initialValues) : _addedLineCount, _addedLineCount].sort();

    useEffect(() => {
        setCompanyDetails('fetching');
        getCompanyDetails()
            .then(setCompanyDetails)
            .catch(e => setCompanyDetails({ errorMessage: e.message }));
    }, [actingAddress]);

    const incrementAdditionalLines = () => setAddedLineCount(lns => (lns < 3 ? lns + 1 : lns));

    const detailsFetchError = !!companyDetails && typeof companyDetails === 'object' && 'errorMessage' in companyDetails;
    const hasUnsavedChanges = !!unsavedChanges && !_.isEqual(defaultFieldValues, unsavedChanges);
    const submissionError = typeof submissionState === 'object' && 'errorMessage' in submissionState;

    const isLoading = areDetailsLoading(companyDetails);
    const isFirstTimeUse = _.isEqual(initialValues, emptyFields);
    const isSubmitting = submissionState === true;
    const areFieldsLocked = !editing && !hasUnsavedChanges && !isFirstTimeUse;

    const completeForm = () => {
        editFields.off();
        clearUnsavedChanges();
    };

    const handleSubmit = useCallback(
        async (values: Fields) => {
            setSubmissionState(true);
            try {
                const companyDetails = mapFieldsToCompanyDetails(values);
                await postCompanyDetails(companyDetails);
                setCompanyDetails(companyDetails);

                completeForm();
                setSubmissionState(false);
                triggerSuccessToast({ title: 'Company details successfully added.' });
                onClose();
            } catch (e: any) {
                console.error('🚨 POST failed 🚨');
                console.log(e);
                setSubmissionState({ errorMessage: e.message });
            }
        },
        [actingAddress],
    );

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader display="flex" flexDirection="row">
                    <IconBox icon={Users} boxShadow="none" w="50px" h="50px" />
                </ModalHeader>

                <ModalBody>
                    <Text color="heading" fontWeight={'700'} fontSize="18px">
                        Add Company Details
                    </Text>
                    <CompanyDetailsForm
                        initialValues={initialValues}
                        defaultFieldValues={defaultFieldValues}
                        isLoading={isLoading}
                        isSubmitting={isSubmitting}
                        areFieldsLocked={areFieldsLocked}
                        submissionError={submissionError ? { errorMessage: submissionState.errorMessage } : undefined}
                        detailsFetchError={detailsFetchError ? (companyDetails as CaughtError) : undefined}
                        onSubmit={handleSubmit}
                        onPersist={setUnsavedChanges}
                        renderActions={({ values, hasUnsavedChanges, isSubmitting, resetForm, completeForm }) => (
                            <HStack w="100%">
                                {areFieldsLocked ? (
                                    <EditButton onClick={editFields.on} w="100%" />
                                ) : (
                                    <HStack w="100%" spacing={4}>
                                        {!_.isEqual(emptyFields, values) && (
                                            <WhiteButton
                                                onClick={() => {
                                                    completeForm();
                                                    resetForm();
                                                }}
                                                isDisabled={isSubmitting}
                                                h="12"
                                                w="50%"
                                            >
                                                Cancel
                                            </WhiteButton>
                                        )}
                                        <AddDetailsButton
                                            isLoading={isSubmitting}
                                            isDisabled={isLoading || isSubmitting || !hasUnsavedChanges}
                                            w="50%"
                                        />
                                    </HStack>
                                )}
                            </HStack>
                        )}
                    />
                </ModalBody>
                <CloseModalButton onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};

import axios, { AxiosError } from 'axios';
import { ClaimType } from '../data-lib/data-model';
import { CompanyDetails } from '../pages/settings/company-details/common';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';
import { Contact } from './useExtendedContacts';
import { CreateClaimFieldsNew } from '../components/display/views/new-invoice';
import { LineItemDto, TaxDto } from './useCustodianApi';
import moment from 'moment';

export type AttachmentLinkGenerator = (
    type: ClaimType,
    recipient: string,
    amount: string,
    tokenSymbol: string,
    description: string,
) => string;

export type AttachmentLinkGeneratorNew = (formikInputs: CreateClaimFieldsNew, contact?: Contact) => Promise<string>;

interface InvoicePreviewPayload {
    recipientAddress: string;
    recipientContactName?: string;
    recipientContactEmail?: string;
    amount: string;
    symbol: string;
    claimDate: string;
    dueDate: string;
    lineItems: LineItemDto[];
    description?: string;
    tax?: TaxDto;
}

export const useCompanyDetailsRepo = () => {
    const { connectedNetwork, userAddress } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';
    const path = !!connectedSafeAddress ? `${connectedSafeAddress}?account_type=gnosis&chain_id=${connectedNetwork}` : userAddress;
    const pathWithAdditionalQueryParams = !!connectedSafeAddress ? `${path}&` : `${path}?`;

    const getCompanyDetails = async () =>
        await axios
            .get<CompanyDetails>(`${endpoints.settingsApi}/company-details/${path}`, {
                withCredentials: true,
            })
            .then(({ data }) => data)
            .catch((err: AxiosError) => {
                if (err.response?.status == 404) return null;
                else throw err;
            });

    const postCompanyDetails = async (companyDetails: CompanyDetails) =>
        await axios.post(`${endpoints.settingsApi}/company-details/${path}`, companyDetails, {
            withCredentials: true,
        });

    const getAttachmentGenerationLink: AttachmentLinkGenerator = (
        type: ClaimType,
        recipient: string,
        amount: string,
        tokenSymbol: string,
        description: string,
    ) =>
        `${endpoints.settingsApi}/invoice/${pathWithAdditionalQueryParams}kind=${type.toLowerCase()}&recipientAddress=${
            type == 'Invoice' ? actingWallet : recipient
        }&amount=${amount}&symbol=${tokenSymbol}&date=${Math.floor(new Date().getTime() / 1000)}&description=${description}`;

    const getAttachmentGenerationLinkNew: AttachmentLinkGeneratorNew = async (formikInputs: CreateClaimFieldsNew, contact?: Contact) => {
        const contactName = contact?.name;
        const contactEmail = contact?.emailAddress;

        const invoiceData: InvoicePreviewPayload = {
            recipientAddress: formikInputs.recipient,
            recipientContactName: contactName,
            recipientContactEmail: contactEmail === '' ? undefined : contactEmail,
            amount: formikInputs.claimAmount,
            symbol: formikInputs.token.symbol,
            claimDate: Math.floor(Date.now() / 1000).toString(),
            dueDate: Math.floor(new Date(formikInputs.dueBy).getTime() / 1000).toString(),
            lineItems: formikInputs.lineItems.map(item => ({
                description: item.description,
                referenceNumber: item.referenceNumber || undefined,
                quantity: Number(item.quantity),
                unitPrice: Number(item.unitPrice),
            })),
            description: formikInputs.description,
            tax: formikInputs.tax
                ? {
                      rate: Number(formikInputs.tax.taxRate),
                      type: formikInputs.tax.taxType,
                  }
                : undefined,
        };

        const payload = { invoiceData: JSON.stringify(invoiceData) };

        const { data } = await axios.post(`${endpoints.settingsApi}/invoice-new/${actingWallet}${gnosisSafeQueryArgs}`, payload, {
            withCredentials: true,
            headers: { 'content-type': 'application/json' },
        });

        return `data:text/html;charset=utf-8,${encodeURIComponent(data)}`;
    };

    return { getCompanyDetails, postCompanyDetails, getAttachmentGenerationLink, getAttachmentGenerationLinkNew };
};

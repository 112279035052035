import { Box, Collapse, Flex, Heading, HStack, Image, Stack, Tag, Text, useBreakpointValue, useDisclosure, Link } from '@chakra-ui/react';
import { ArrowsLeftRight, Books, Note, PlayCircle, Receipt, UsersThree } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../../state/app-state';
import AddContactsIcon from '../../base/add-contacts-icon';
import { CreateOrangeButton, CreateSecondaryButton, TextButton } from '../../inputs/buttons';
import { ShadowBox, shadowProps } from '../../layout/cards';
import { MaxWidthWrapper } from '../../layout/page-layout';
import { CloseModalButton } from '../../modals/common';
import { CreateClaimModal } from '../../modals/create-claim-modal/create-claim-modal';
import { ResponsiveStack } from '../responsive-stack';
import Web3Accounting from 'url:../../../assets/Web3 Accounting.png';
import { NewTag } from '../../base/status-badge';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { enableNewInvoiceCreation } from '../../../tools/featureFlags';
import { CreatePaymentModal } from '../../modals/create-claim-modal/create-payment-modal';

const CreateInvoiceButton = CreateOrangeButton('Create an invoice');
const CreatePaymentButton = CreateSecondaryButton('Send a payment');
const CreateBatchPaymentButton = CreateOrangeButton('Create batch payment');
const GoToContactsButton = CreateOrangeButton('Go to contacts');
const SkipForNowButton = CreateSecondaryButton('Skip for now');

type InfoCardProps = {
    icon: React.ReactNode;
    title: string;
    description: string;
    actionButton: React.ReactNode;
    links?: React.ReactNode;
    tag?: boolean;
};
const InfoCard = ({ icon, title, description, actionButton, links, tag }: InfoCardProps) => {
    const { isOpen, onToggle } = useDisclosure();
    const titleFontSize = useBreakpointValue({ base: '14px', md: '16px' }, { ssr: false });
    const buttonFontSize = useBreakpointValue({ base: '12px', md: '14px' }, { ssr: false });

    return (
        <>
            <ShadowBox p="0">
                <HStack spacing={5} p="5">
                    <Box h="36px" w="36px" p="6px" bg="#EDEDED" borderRadius={'2px'}>
                        {icon}
                    </Box>
                    <Stack spacing={'1px'}>
                        <Text fontSize={titleFontSize} fontWeight="700">
                            {title} {!!tag && <NewTag />}
                        </Text>
                        <TextButton
                            fontSize={buttonFontSize}
                            fontWeight="400"
                            color="#3376C2"
                            textDecoration={'initial'}
                            w="fit-content"
                            onClick={_ => onToggle()}
                        >
                            {isOpen ? 'Hide' : 'Show'} details
                        </TextButton>
                    </Stack>
                </HStack>
                <Collapse in={isOpen}>
                    <Box borderTop={'1px solid #E3E3E3'} p="5">
                        <Stack spacing={5} fontSize={buttonFontSize}>
                            <Text>{description}</Text>
                            {links}
                            {actionButton}
                        </Stack>
                    </Box>
                </Collapse>
            </ShadowBox>
        </>
    );
};

const GettingStartedLink =
    (icon: React.ReactNode, isMobile: boolean) =>
    ({ text, href }: GettingStartedLinkProps) =>
        (
            <HStack>
                {icon}
                <Link
                    fontSize={isMobile ? '12px' : '14px'}
                    fontWeight="400"
                    color="#3376C2"
                    textDecoration={'initial'}
                    width="fit-content"
                    href={href}
                    target="_blank"
                >
                    {text}
                </Link>
            </HStack>
        );

const VideoLink = (isMobile: boolean) => GettingStartedLink(<PlayCircle color="#3376C2" size={'18px'} />, isMobile);
const GitbookLink = (isMobile: boolean) => GettingStartedLink(<Books color="#3376C2" size={'18px'} />, isMobile);

type GettingStartedLinkProps = { text: string; href: string };

export const GettingStartedCard = ({ isOpen, onClose }: { isOpen: boolean; onClose: VoidFunction }) => {
    const { readyToTransact } = useAppState();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const Video = VideoLink(isMobile);
    const Gitbook = GitbookLink(isMobile);

    const headingSize = useBreakpointValue({ base: '3xl', md: '34px' }, { ssr: false });
    const textSize = useBreakpointValue({ base: 'md', md: '18px' }, { ssr: false });
    const maxWValue = useBreakpointValue({ base: '90%', md: '450px' }, { ssr: false });
    const buttonFontSize = useBreakpointValue({ base: 'xs', md: 'md' }, { ssr: false });

    return (
        <Box px="12" pt={isOpen ? '8' : ''}>
            <Box {...(isOpen ? shadowProps : {})}>
                <Collapse in={isOpen} unmountOnExit>
                    <Flex p="6">
                        <CloseModalButton onClose={() => onClose()} />
                    </Flex>

                    <MaxWidthWrapper overflow={'hidden'} mx="10" mt="-12">
                        <Flex maxHeight="263px" pb="8">
                            <Stack flex={1} py="3" spacing={4} my="auto">
                                <Heading color="heading" fontSize={headingSize}>
                                    Getting started
                                </Heading>

                                <Text fontSize={textSize} fontWeight="400" maxW={maxWValue}>
                                    Use Bulla to see an organized overview of your web3 operations and transactions.
                                </Text>

                                <SkipForNowButton onClick={onClose} maxWidth="fit-content" fontSize={buttonFontSize} />
                            </Stack>
                            {!isMobile && (
                                <Box flex={1}>
                                    <Image src={Web3Accounting} maxH={'100%'} mx="auto" />
                                </Box>
                            )}
                        </Flex>
                    </MaxWidthWrapper>
                    <ResponsiveStack spacing={5} w="100%" alignItems={'start'} px="9" pt="-6" pb="10">
                        <Stack flex={1} spacing={5} mb="auto" h="100%" w="100%">
                            <InfoCard
                                icon={<Note width={'24px'} height={'24px'} />}
                                title={'Create an invoice'}
                                description={
                                    'A Bulla invoice lets you request crypto from a recipient and add details for your convenience. Every Bulla invoice creates an NFT that can be used as a non fungible receipt.'
                                }
                                actionButton={
                                    enableNewInvoiceCreation ? (
                                        <CreateInvoiceButton
                                            isDisabled={!readyToTransact}
                                            onClick={() => navigate('/new-invoice')}
                                            w="fit-content"
                                            fontSize={buttonFontSize}
                                        />
                                    ) : (
                                        <CreateClaimModal
                                            claimType="Invoice"
                                            triggerElement={onOpen => (
                                                <CreateInvoiceButton
                                                    isDisabled={!readyToTransact}
                                                    onClick={onOpen}
                                                    w="fit-content"
                                                    fontSize={buttonFontSize}
                                                />
                                            )}
                                        />
                                    )
                                }
                                links={
                                    <>
                                        <Video
                                            text={'Watch how to create and send invoices (6:18)'}
                                            href={'https://www.youtube.com/watch?v=D_EiDgco7zo'}
                                        />
                                        <Gitbook
                                            text={'Learn more about invoicing with Bulla'}
                                            href={'https://bulla-network.gitbook.io/bulla-network/welcome-to-bullanetwork/bulla-banker'}
                                        />
                                    </>
                                }
                            />
                        </Stack>
                        <Stack flex={1} spacing={5} mb="auto" h="100%" w="100%">
                            <InfoCard
                                icon={<Receipt width={'24px'} height={'24px'} />}
                                title={'Make a payment'}
                                description={
                                    'Remit payment in crypto to anyone with a wallet and keep track of all your on-chain accounts.'
                                }
                                actionButton={
                                    <CreatePaymentModal
                                        triggerElement={onOpen => (
                                            <CreatePaymentButton
                                                isDisabled={!readyToTransact}
                                                onClick={onOpen}
                                                w="fit-content"
                                                fontSize={buttonFontSize}
                                            />
                                        )}
                                    />
                                }
                            />
                            <InfoCard
                                icon={<UsersThree width={'24px'} height={'24px'} />}
                                title={'Manage your payroll'}
                                description={
                                    'Our Web3 payroll solution helps you manage staff and freelancers who want to get paid in crypto.'
                                }
                                actionButton={
                                    <CreateBatchPaymentButton
                                        isDisabled={!readyToTransact}
                                        onClick={() => navigate('/batch-payment')}
                                        w="fit-content"
                                        fontSize={buttonFontSize}
                                    />
                                }
                            />
                            <InfoCard
                                icon={<AddContactsIcon />}
                                title={'Add contacts'}
                                description={
                                    'Add a contact by their wallet address or import multiple contacts from csv files to streamline your payment process.'
                                }
                                actionButton={
                                    <GoToContactsButton
                                        isDisabled={!readyToTransact}
                                        onClick={() => navigate('/contacts')}
                                        w="fit-content"
                                        fontSize={buttonFontSize}
                                    />
                                }
                            />
                        </Stack>
                    </ResponsiveStack>
                </Collapse>
            </Box>
        </Box>
    );
};

import * as r from "./ssr/index.mjs";
import { Acorn as t } from "./csr/Acorn.mjs";
import { AddressBook as m } from "./csr/AddressBook.mjs";
import { AddressBookTabs as x } from "./csr/AddressBookTabs.mjs";
import { AirTrafficControl as a } from "./csr/AirTrafficControl.mjs";
import { Airplane as n } from "./csr/Airplane.mjs";
import { AirplaneInFlight as S } from "./csr/AirplaneInFlight.mjs";
import { AirplaneLanding as u } from "./csr/AirplaneLanding.mjs";
import { AirplaneTakeoff as C } from "./csr/AirplaneTakeoff.mjs";
import { AirplaneTaxiing as d } from "./csr/AirplaneTaxiing.mjs";
import { AirplaneTilt as L } from "./csr/AirplaneTilt.mjs";
import { Airplay as A } from "./csr/Airplay.mjs";
import { Alarm as k } from "./csr/Alarm.mjs";
import { Alien as B } from "./csr/Alien.mjs";
import { AlignBottom as D } from "./csr/AlignBottom.mjs";
import { AlignBottomSimple as H } from "./csr/AlignBottomSimple.mjs";
import { AlignCenterHorizontal as R } from "./csr/AlignCenterHorizontal.mjs";
import { AlignCenterHorizontalSimple as N } from "./csr/AlignCenterHorizontalSimple.mjs";
import { AlignCenterVertical as G } from "./csr/AlignCenterVertical.mjs";
import { AlignCenterVerticalSimple as E } from "./csr/AlignCenterVerticalSimple.mjs";
import { AlignLeft as q } from "./csr/AlignLeft.mjs";
import { AlignLeftSimple as I } from "./csr/AlignLeftSimple.mjs";
import { AlignRight as X } from "./csr/AlignRight.mjs";
import { AlignRightSimple as J } from "./csr/AlignRightSimple.mjs";
import { AlignTop as Z } from "./csr/AlignTop.mjs";
import { AlignTopSimple as Y } from "./csr/AlignTopSimple.mjs";
import { AmazonLogo as $ } from "./csr/AmazonLogo.mjs";
import { Ambulance as or } from "./csr/Ambulance.mjs";
import { Anchor as tr } from "./csr/Anchor.mjs";
import { AnchorSimple as mr } from "./csr/AnchorSimple.mjs";
import { AndroidLogo as xr } from "./csr/AndroidLogo.mjs";
import { Angle as ar } from "./csr/Angle.mjs";
import { AngularLogo as nr } from "./csr/AngularLogo.mjs";
import { Aperture as Sr } from "./csr/Aperture.mjs";
import { AppStoreLogo as ur } from "./csr/AppStoreLogo.mjs";
import { AppWindow as Cr } from "./csr/AppWindow.mjs";
import { AppleLogo as dr } from "./csr/AppleLogo.mjs";
import { ApplePodcastsLogo as Lr } from "./csr/ApplePodcastsLogo.mjs";
import { ApproximateEquals as Ar } from "./csr/ApproximateEquals.mjs";
import { Archive as kr } from "./csr/Archive.mjs";
import { Armchair as Br } from "./csr/Armchair.mjs";
import { ArrowArcLeft as Dr } from "./csr/ArrowArcLeft.mjs";
import { ArrowArcRight as Hr } from "./csr/ArrowArcRight.mjs";
import { ArrowBendDoubleUpLeft as Rr } from "./csr/ArrowBendDoubleUpLeft.mjs";
import { ArrowBendDoubleUpRight as Nr } from "./csr/ArrowBendDoubleUpRight.mjs";
import { ArrowBendDownLeft as Gr } from "./csr/ArrowBendDownLeft.mjs";
import { ArrowBendDownRight as Er } from "./csr/ArrowBendDownRight.mjs";
import { ArrowBendLeftDown as qr } from "./csr/ArrowBendLeftDown.mjs";
import { ArrowBendLeftUp as Ir } from "./csr/ArrowBendLeftUp.mjs";
import { ArrowBendRightDown as Xr } from "./csr/ArrowBendRightDown.mjs";
import { ArrowBendRightUp as Jr } from "./csr/ArrowBendRightUp.mjs";
import { ArrowBendUpLeft as Zr } from "./csr/ArrowBendUpLeft.mjs";
import { ArrowBendUpRight as Yr } from "./csr/ArrowBendUpRight.mjs";
import { ArrowCircleDown as $r } from "./csr/ArrowCircleDown.mjs";
import { ArrowCircleDownLeft as oo } from "./csr/ArrowCircleDownLeft.mjs";
import { ArrowCircleDownRight as to } from "./csr/ArrowCircleDownRight.mjs";
import { ArrowCircleLeft as mo } from "./csr/ArrowCircleLeft.mjs";
import { ArrowCircleRight as xo } from "./csr/ArrowCircleRight.mjs";
import { ArrowCircleUp as ao } from "./csr/ArrowCircleUp.mjs";
import { ArrowCircleUpLeft as no } from "./csr/ArrowCircleUpLeft.mjs";
import { ArrowCircleUpRight as So } from "./csr/ArrowCircleUpRight.mjs";
import { ArrowClockwise as uo } from "./csr/ArrowClockwise.mjs";
import { ArrowCounterClockwise as Co } from "./csr/ArrowCounterClockwise.mjs";
import { ArrowDown as wo } from "./csr/ArrowDown.mjs";
import { ArrowDownLeft as Po } from "./csr/ArrowDownLeft.mjs";
import { ArrowDownRight as To } from "./csr/ArrowDownRight.mjs";
import { ArrowElbowDownLeft as Fo } from "./csr/ArrowElbowDownLeft.mjs";
import { ArrowElbowDownRight as bo } from "./csr/ArrowElbowDownRight.mjs";
import { ArrowElbowLeft as yo } from "./csr/ArrowElbowLeft.mjs";
import { ArrowElbowLeftDown as Mo } from "./csr/ArrowElbowLeftDown.mjs";
import { ArrowElbowLeftUp as Uo } from "./csr/ArrowElbowLeftUp.mjs";
import { ArrowElbowRight as vo } from "./csr/ArrowElbowRight.mjs";
import { ArrowElbowRightDown as Oo } from "./csr/ArrowElbowRightDown.mjs";
import { ArrowElbowRightUp as Wo } from "./csr/ArrowElbowRightUp.mjs";
import { ArrowElbowUpLeft as Vo } from "./csr/ArrowElbowUpLeft.mjs";
import { ArrowElbowUpRight as zo } from "./csr/ArrowElbowUpRight.mjs";
import { ArrowFatDown as Ko } from "./csr/ArrowFatDown.mjs";
import { ArrowFatLeft as Qo } from "./csr/ArrowFatLeft.mjs";
import { ArrowFatLineDown as jo } from "./csr/ArrowFatLineDown.mjs";
import { ArrowFatLineLeft as _o } from "./csr/ArrowFatLineLeft.mjs";
import { ArrowFatLineRight as re } from "./csr/ArrowFatLineRight.mjs";
import { ArrowFatLineUp as ee } from "./csr/ArrowFatLineUp.mjs";
import { ArrowFatLinesDown as pe } from "./csr/ArrowFatLinesDown.mjs";
import { ArrowFatLinesLeft as fe } from "./csr/ArrowFatLinesLeft.mjs";
import { ArrowFatLinesRight as ie } from "./csr/ArrowFatLinesRight.mjs";
import { ArrowFatLinesUp as le } from "./csr/ArrowFatLinesUp.mjs";
import { ArrowFatRight as se } from "./csr/ArrowFatRight.mjs";
import { ArrowFatUp as ce } from "./csr/ArrowFatUp.mjs";
import { ArrowLeft as ge } from "./csr/ArrowLeft.mjs";
import { ArrowLineDown as he } from "./csr/ArrowLineDown.mjs";
import { ArrowLineDownLeft as we } from "./csr/ArrowLineDownLeft.mjs";
import { ArrowLineDownRight as Pe } from "./csr/ArrowLineDownRight.mjs";
import { ArrowLineLeft as Te } from "./csr/ArrowLineLeft.mjs";
import { ArrowLineRight as Fe } from "./csr/ArrowLineRight.mjs";
import { ArrowLineUp as be } from "./csr/ArrowLineUp.mjs";
import { ArrowLineUpLeft as ye } from "./csr/ArrowLineUpLeft.mjs";
import { ArrowLineUpRight as Me } from "./csr/ArrowLineUpRight.mjs";
import { ArrowRight as Ue } from "./csr/ArrowRight.mjs";
import { ArrowSquareDown as ve } from "./csr/ArrowSquareDown.mjs";
import { ArrowSquareDownLeft as Oe } from "./csr/ArrowSquareDownLeft.mjs";
import { ArrowSquareDownRight as We } from "./csr/ArrowSquareDownRight.mjs";
import { ArrowSquareIn as Ve } from "./csr/ArrowSquareIn.mjs";
import { ArrowSquareLeft as ze } from "./csr/ArrowSquareLeft.mjs";
import { ArrowSquareOut as Ke } from "./csr/ArrowSquareOut.mjs";
import { ArrowSquareRight as Qe } from "./csr/ArrowSquareRight.mjs";
import { ArrowSquareUp as je } from "./csr/ArrowSquareUp.mjs";
import { ArrowSquareUpLeft as _e } from "./csr/ArrowSquareUpLeft.mjs";
import { ArrowSquareUpRight as rt } from "./csr/ArrowSquareUpRight.mjs";
import { ArrowUDownLeft as et } from "./csr/ArrowUDownLeft.mjs";
import { ArrowUDownRight as pt } from "./csr/ArrowUDownRight.mjs";
import { ArrowULeftDown as ft } from "./csr/ArrowULeftDown.mjs";
import { ArrowULeftUp as it } from "./csr/ArrowULeftUp.mjs";
import { ArrowURightDown as lt } from "./csr/ArrowURightDown.mjs";
import { ArrowURightUp as st } from "./csr/ArrowURightUp.mjs";
import { ArrowUUpLeft as ct } from "./csr/ArrowUUpLeft.mjs";
import { ArrowUUpRight as gt } from "./csr/ArrowUUpRight.mjs";
import { ArrowUp as ht } from "./csr/ArrowUp.mjs";
import { ArrowUpLeft as wt } from "./csr/ArrowUpLeft.mjs";
import { ArrowUpRight as Pt } from "./csr/ArrowUpRight.mjs";
import { ArrowsClockwise as Tt } from "./csr/ArrowsClockwise.mjs";
import { ArrowsCounterClockwise as Ft } from "./csr/ArrowsCounterClockwise.mjs";
import { ArrowsDownUp as bt } from "./csr/ArrowsDownUp.mjs";
import { ArrowsHorizontal as yt } from "./csr/ArrowsHorizontal.mjs";
import { ArrowsIn as Mt } from "./csr/ArrowsIn.mjs";
import { ArrowsInCardinal as Ut } from "./csr/ArrowsInCardinal.mjs";
import { ArrowsInLineHorizontal as vt } from "./csr/ArrowsInLineHorizontal.mjs";
import { ArrowsInLineVertical as Ot } from "./csr/ArrowsInLineVertical.mjs";
import { ArrowsInSimple as Wt } from "./csr/ArrowsInSimple.mjs";
import { ArrowsLeftRight as Vt } from "./csr/ArrowsLeftRight.mjs";
import { ArrowsMerge as zt } from "./csr/ArrowsMerge.mjs";
import { ArrowsOut as Kt } from "./csr/ArrowsOut.mjs";
import { ArrowsOutCardinal as Qt } from "./csr/ArrowsOutCardinal.mjs";
import { ArrowsOutLineHorizontal as jt } from "./csr/ArrowsOutLineHorizontal.mjs";
import { ArrowsOutLineVertical as _t } from "./csr/ArrowsOutLineVertical.mjs";
import { ArrowsOutSimple as rp } from "./csr/ArrowsOutSimple.mjs";
import { ArrowsSplit as ep } from "./csr/ArrowsSplit.mjs";
import { ArrowsVertical as pp } from "./csr/ArrowsVertical.mjs";
import { Article as fp } from "./csr/Article.mjs";
import { ArticleMedium as ip } from "./csr/ArticleMedium.mjs";
import { ArticleNyTimes as lp } from "./csr/ArticleNyTimes.mjs";
import { Asclepius as sp, Asclepius as Sp } from "./csr/Asclepius.mjs";
import { Asterisk as up } from "./csr/Asterisk.mjs";
import { AsteriskSimple as Cp } from "./csr/AsteriskSimple.mjs";
import { At as dp } from "./csr/At.mjs";
import { Atom as Lp } from "./csr/Atom.mjs";
import { Avocado as Ap } from "./csr/Avocado.mjs";
import { Axe as kp } from "./csr/Axe.mjs";
import { Baby as Bp } from "./csr/Baby.mjs";
import { BabyCarriage as Dp } from "./csr/BabyCarriage.mjs";
import { Backpack as Hp } from "./csr/Backpack.mjs";
import { Backspace as Rp } from "./csr/Backspace.mjs";
import { Bag as Np } from "./csr/Bag.mjs";
import { BagSimple as Gp } from "./csr/BagSimple.mjs";
import { Balloon as Ep } from "./csr/Balloon.mjs";
import { Bandaids as qp } from "./csr/Bandaids.mjs";
import { Bank as Ip } from "./csr/Bank.mjs";
import { Barbell as Xp } from "./csr/Barbell.mjs";
import { Barcode as Jp } from "./csr/Barcode.mjs";
import { Barn as Zp } from "./csr/Barn.mjs";
import { Barricade as Yp } from "./csr/Barricade.mjs";
import { Baseball as $p } from "./csr/Baseball.mjs";
import { BaseballCap as om } from "./csr/BaseballCap.mjs";
import { BaseballHelmet as tm } from "./csr/BaseballHelmet.mjs";
import { Basket as mm } from "./csr/Basket.mjs";
import { Basketball as xm } from "./csr/Basketball.mjs";
import { Bathtub as am } from "./csr/Bathtub.mjs";
import { BatteryCharging as nm } from "./csr/BatteryCharging.mjs";
import { BatteryChargingVertical as Sm } from "./csr/BatteryChargingVertical.mjs";
import { BatteryEmpty as um } from "./csr/BatteryEmpty.mjs";
import { BatteryFull as Cm } from "./csr/BatteryFull.mjs";
import { BatteryHigh as dm } from "./csr/BatteryHigh.mjs";
import { BatteryLow as Lm } from "./csr/BatteryLow.mjs";
import { BatteryMedium as Am } from "./csr/BatteryMedium.mjs";
import { BatteryPlus as km } from "./csr/BatteryPlus.mjs";
import { BatteryPlusVertical as Bm } from "./csr/BatteryPlusVertical.mjs";
import { BatteryVerticalEmpty as Dm } from "./csr/BatteryVerticalEmpty.mjs";
import { BatteryVerticalFull as Hm } from "./csr/BatteryVerticalFull.mjs";
import { BatteryVerticalHigh as Rm } from "./csr/BatteryVerticalHigh.mjs";
import { BatteryVerticalLow as Nm } from "./csr/BatteryVerticalLow.mjs";
import { BatteryVerticalMedium as Gm } from "./csr/BatteryVerticalMedium.mjs";
import { BatteryWarning as Em } from "./csr/BatteryWarning.mjs";
import { BatteryWarningVertical as qm } from "./csr/BatteryWarningVertical.mjs";
import { BeachBall as Im } from "./csr/BeachBall.mjs";
import { Beanie as Xm } from "./csr/Beanie.mjs";
import { Bed as Jm } from "./csr/Bed.mjs";
import { BeerBottle as Zm } from "./csr/BeerBottle.mjs";
import { BeerStein as Ym } from "./csr/BeerStein.mjs";
import { BehanceLogo as $m } from "./csr/BehanceLogo.mjs";
import { Bell as of } from "./csr/Bell.mjs";
import { BellRinging as tf } from "./csr/BellRinging.mjs";
import { BellSimple as mf } from "./csr/BellSimple.mjs";
import { BellSimpleRinging as xf } from "./csr/BellSimpleRinging.mjs";
import { BellSimpleSlash as lf } from "./csr/BellSimpleSlash.mjs";
import { BellSimpleZ as sf } from "./csr/BellSimpleZ.mjs";
import { BellSlash as cf } from "./csr/BellSlash.mjs";
import { BellZ as gf } from "./csr/BellZ.mjs";
import { Belt as hf } from "./csr/Belt.mjs";
import { BezierCurve as wf } from "./csr/BezierCurve.mjs";
import { Bicycle as Pf } from "./csr/Bicycle.mjs";
import { Binary as Tf } from "./csr/Binary.mjs";
import { Binoculars as Ff } from "./csr/Binoculars.mjs";
import { Biohazard as bf } from "./csr/Biohazard.mjs";
import { Bird as yf } from "./csr/Bird.mjs";
import { Blueprint as Mf } from "./csr/Blueprint.mjs";
import { Bluetooth as Uf } from "./csr/Bluetooth.mjs";
import { BluetoothConnected as vf } from "./csr/BluetoothConnected.mjs";
import { BluetoothSlash as Of } from "./csr/BluetoothSlash.mjs";
import { BluetoothX as Wf } from "./csr/BluetoothX.mjs";
import { Boat as Vf } from "./csr/Boat.mjs";
import { Bomb as zf } from "./csr/Bomb.mjs";
import { Bone as Kf } from "./csr/Bone.mjs";
import { Book as Qf } from "./csr/Book.mjs";
import { BookBookmark as jf } from "./csr/BookBookmark.mjs";
import { BookOpen as _f } from "./csr/BookOpen.mjs";
import { BookOpenText as rx } from "./csr/BookOpenText.mjs";
import { BookOpenUser as ex } from "./csr/BookOpenUser.mjs";
import { Bookmark as px } from "./csr/Bookmark.mjs";
import { BookmarkSimple as fx } from "./csr/BookmarkSimple.mjs";
import { Bookmarks as ix } from "./csr/Bookmarks.mjs";
import { BookmarksSimple as lx } from "./csr/BookmarksSimple.mjs";
import { Books as sx } from "./csr/Books.mjs";
import { Boot as cx } from "./csr/Boot.mjs";
import { Boules as gx } from "./csr/Boules.mjs";
import { BoundingBox as hx } from "./csr/BoundingBox.mjs";
import { BowlFood as wx } from "./csr/BowlFood.mjs";
import { BowlSteam as Px } from "./csr/BowlSteam.mjs";
import { BowlingBall as Tx } from "./csr/BowlingBall.mjs";
import { BoxArrowDown as Fx, BoxArrowDown as Bx } from "./csr/BoxArrowDown.mjs";
import { BoxArrowUp as Dx } from "./csr/BoxArrowUp.mjs";
import { BoxingGlove as Hx } from "./csr/BoxingGlove.mjs";
import { BracketsAngle as Rx } from "./csr/BracketsAngle.mjs";
import { BracketsCurly as Nx } from "./csr/BracketsCurly.mjs";
import { BracketsRound as Gx } from "./csr/BracketsRound.mjs";
import { BracketsSquare as Ex } from "./csr/BracketsSquare.mjs";
import { Brain as qx } from "./csr/Brain.mjs";
import { Brandy as Ix } from "./csr/Brandy.mjs";
import { Bread as Xx } from "./csr/Bread.mjs";
import { Bridge as Jx } from "./csr/Bridge.mjs";
import { Briefcase as Zx } from "./csr/Briefcase.mjs";
import { BriefcaseMetal as Yx } from "./csr/BriefcaseMetal.mjs";
import { Broadcast as $x } from "./csr/Broadcast.mjs";
import { Broom as oi } from "./csr/Broom.mjs";
import { Browser as ti } from "./csr/Browser.mjs";
import { Browsers as mi } from "./csr/Browsers.mjs";
import { BugBeetle as xi } from "./csr/BugBeetle.mjs";
import { Bug as ai } from "./csr/Bug.mjs";
import { BugDroid as ni } from "./csr/BugDroid.mjs";
import { BuildingApartment as Si } from "./csr/BuildingApartment.mjs";
import { Building as ui } from "./csr/Building.mjs";
import { BuildingOffice as Ci } from "./csr/BuildingOffice.mjs";
import { Buildings as di } from "./csr/Buildings.mjs";
import { Bulldozer as Li } from "./csr/Bulldozer.mjs";
import { Bus as Ai } from "./csr/Bus.mjs";
import { Butterfly as ki } from "./csr/Butterfly.mjs";
import { CableCar as Bi } from "./csr/CableCar.mjs";
import { Cactus as Di } from "./csr/Cactus.mjs";
import { Cake as Hi } from "./csr/Cake.mjs";
import { Calculator as Ri } from "./csr/Calculator.mjs";
import { CalendarBlank as Ni } from "./csr/CalendarBlank.mjs";
import { Calendar as Gi } from "./csr/Calendar.mjs";
import { CalendarCheck as Ei } from "./csr/CalendarCheck.mjs";
import { CalendarDot as qi } from "./csr/CalendarDot.mjs";
import { CalendarDots as Ii } from "./csr/CalendarDots.mjs";
import { CalendarHeart as Xi } from "./csr/CalendarHeart.mjs";
import { CalendarMinus as Ji } from "./csr/CalendarMinus.mjs";
import { CalendarPlus as Zi } from "./csr/CalendarPlus.mjs";
import { CalendarSlash as Yi } from "./csr/CalendarSlash.mjs";
import { CalendarStar as $i } from "./csr/CalendarStar.mjs";
import { CalendarX as oa } from "./csr/CalendarX.mjs";
import { CallBell as ta } from "./csr/CallBell.mjs";
import { Camera as ma } from "./csr/Camera.mjs";
import { CameraPlus as xa } from "./csr/CameraPlus.mjs";
import { CameraRotate as aa } from "./csr/CameraRotate.mjs";
import { CameraSlash as na } from "./csr/CameraSlash.mjs";
import { Campfire as Sa } from "./csr/Campfire.mjs";
import { CarBattery as ua } from "./csr/CarBattery.mjs";
import { Car as Ca } from "./csr/Car.mjs";
import { CarProfile as da } from "./csr/CarProfile.mjs";
import { CarSimple as La } from "./csr/CarSimple.mjs";
import { Cardholder as Aa } from "./csr/Cardholder.mjs";
import { Cards as ka } from "./csr/Cards.mjs";
import { CardsThree as Ba } from "./csr/CardsThree.mjs";
import { CaretCircleDoubleDown as Da } from "./csr/CaretCircleDoubleDown.mjs";
import { CaretCircleDoubleLeft as Ha } from "./csr/CaretCircleDoubleLeft.mjs";
import { CaretCircleDoubleRight as Ra } from "./csr/CaretCircleDoubleRight.mjs";
import { CaretCircleDoubleUp as Na } from "./csr/CaretCircleDoubleUp.mjs";
import { CaretCircleDown as Ga } from "./csr/CaretCircleDown.mjs";
import { CaretCircleLeft as Ea } from "./csr/CaretCircleLeft.mjs";
import { CaretCircleRight as qa } from "./csr/CaretCircleRight.mjs";
import { CaretCircleUp as Ia } from "./csr/CaretCircleUp.mjs";
import { CaretCircleUpDown as Xa } from "./csr/CaretCircleUpDown.mjs";
import { CaretDoubleDown as Ja } from "./csr/CaretDoubleDown.mjs";
import { CaretDoubleLeft as Za } from "./csr/CaretDoubleLeft.mjs";
import { CaretDoubleRight as Ya } from "./csr/CaretDoubleRight.mjs";
import { CaretDoubleUp as $a } from "./csr/CaretDoubleUp.mjs";
import { CaretDown as ol } from "./csr/CaretDown.mjs";
import { CaretLeft as tl } from "./csr/CaretLeft.mjs";
import { CaretLineDown as ml } from "./csr/CaretLineDown.mjs";
import { CaretLineLeft as xl } from "./csr/CaretLineLeft.mjs";
import { CaretLineRight as al } from "./csr/CaretLineRight.mjs";
import { CaretLineUp as nl } from "./csr/CaretLineUp.mjs";
import { CaretRight as Sl } from "./csr/CaretRight.mjs";
import { CaretUp as ul } from "./csr/CaretUp.mjs";
import { CaretUpDown as Cl } from "./csr/CaretUpDown.mjs";
import { Carrot as dl } from "./csr/Carrot.mjs";
import { CashRegister as Ll } from "./csr/CashRegister.mjs";
import { CassetteTape as Al } from "./csr/CassetteTape.mjs";
import { CastleTurret as kl } from "./csr/CastleTurret.mjs";
import { Cat as Bl } from "./csr/Cat.mjs";
import { CellSignalFull as Dl } from "./csr/CellSignalFull.mjs";
import { CellSignalHigh as Hl } from "./csr/CellSignalHigh.mjs";
import { CellSignalLow as Rl } from "./csr/CellSignalLow.mjs";
import { CellSignalMedium as Nl } from "./csr/CellSignalMedium.mjs";
import { CellSignalNone as Gl } from "./csr/CellSignalNone.mjs";
import { CellSignalSlash as El } from "./csr/CellSignalSlash.mjs";
import { CellSignalX as ql } from "./csr/CellSignalX.mjs";
import { CellTower as Il } from "./csr/CellTower.mjs";
import { Certificate as Xl } from "./csr/Certificate.mjs";
import { Chair as Jl } from "./csr/Chair.mjs";
import { Chalkboard as Zl } from "./csr/Chalkboard.mjs";
import { ChalkboardSimple as Yl } from "./csr/ChalkboardSimple.mjs";
import { ChalkboardTeacher as $l } from "./csr/ChalkboardTeacher.mjs";
import { Champagne as on } from "./csr/Champagne.mjs";
import { ChargingStation as tn } from "./csr/ChargingStation.mjs";
import { ChartBar as mn } from "./csr/ChartBar.mjs";
import { ChartBarHorizontal as xn } from "./csr/ChartBarHorizontal.mjs";
import { ChartDonut as ln } from "./csr/ChartDonut.mjs";
import { ChartLine as sn } from "./csr/ChartLine.mjs";
import { ChartLineDown as cn } from "./csr/ChartLineDown.mjs";
import { ChartLineUp as gn } from "./csr/ChartLineUp.mjs";
import { ChartPie as hn } from "./csr/ChartPie.mjs";
import { ChartPieSlice as wn } from "./csr/ChartPieSlice.mjs";
import { ChartPolar as Pn } from "./csr/ChartPolar.mjs";
import { ChartScatter as Tn } from "./csr/ChartScatter.mjs";
import { Chat as Fn } from "./csr/Chat.mjs";
import { ChatCentered as bn } from "./csr/ChatCentered.mjs";
import { ChatCenteredDots as yn } from "./csr/ChatCenteredDots.mjs";
import { ChatCenteredSlash as Mn } from "./csr/ChatCenteredSlash.mjs";
import { ChatCenteredText as Un } from "./csr/ChatCenteredText.mjs";
import { ChatCircle as vn } from "./csr/ChatCircle.mjs";
import { ChatCircleDots as On } from "./csr/ChatCircleDots.mjs";
import { ChatCircleSlash as Wn } from "./csr/ChatCircleSlash.mjs";
import { ChatCircleText as Vn } from "./csr/ChatCircleText.mjs";
import { ChatDots as zn } from "./csr/ChatDots.mjs";
import { ChatSlash as Kn } from "./csr/ChatSlash.mjs";
import { ChatTeardrop as Qn } from "./csr/ChatTeardrop.mjs";
import { ChatTeardropDots as jn } from "./csr/ChatTeardropDots.mjs";
import { ChatTeardropSlash as _n } from "./csr/ChatTeardropSlash.mjs";
import { ChatTeardropText as rs } from "./csr/ChatTeardropText.mjs";
import { ChatText as es } from "./csr/ChatText.mjs";
import { Chats as ps } from "./csr/Chats.mjs";
import { ChatsCircle as fs } from "./csr/ChatsCircle.mjs";
import { ChatsTeardrop as is } from "./csr/ChatsTeardrop.mjs";
import { Check as ls } from "./csr/Check.mjs";
import { CheckCircle as ss } from "./csr/CheckCircle.mjs";
import { CheckFat as cs } from "./csr/CheckFat.mjs";
import { CheckSquare as gs } from "./csr/CheckSquare.mjs";
import { CheckSquareOffset as hs } from "./csr/CheckSquareOffset.mjs";
import { Checkerboard as ws } from "./csr/Checkerboard.mjs";
import { Checks as Ps } from "./csr/Checks.mjs";
import { Cheers as Ts } from "./csr/Cheers.mjs";
import { Cheese as Fs } from "./csr/Cheese.mjs";
import { ChefHat as bs } from "./csr/ChefHat.mjs";
import { Cherries as ys } from "./csr/Cherries.mjs";
import { Church as Ms } from "./csr/Church.mjs";
import { Cigarette as Us } from "./csr/Cigarette.mjs";
import { CigaretteSlash as vs } from "./csr/CigaretteSlash.mjs";
import { Circle as Os } from "./csr/Circle.mjs";
import { CircleDashed as Ws } from "./csr/CircleDashed.mjs";
import { CircleHalf as Vs } from "./csr/CircleHalf.mjs";
import { CircleHalfTilt as zs } from "./csr/CircleHalfTilt.mjs";
import { CircleNotch as Ks } from "./csr/CircleNotch.mjs";
import { CirclesFour as Qs } from "./csr/CirclesFour.mjs";
import { CirclesThree as js } from "./csr/CirclesThree.mjs";
import { CirclesThreePlus as _s } from "./csr/CirclesThreePlus.mjs";
import { Circuitry as rS } from "./csr/Circuitry.mjs";
import { City as eS } from "./csr/City.mjs";
import { Clipboard as pS } from "./csr/Clipboard.mjs";
import { ClipboardText as fS } from "./csr/ClipboardText.mjs";
import { ClockAfternoon as iS } from "./csr/ClockAfternoon.mjs";
import { Clock as lS } from "./csr/Clock.mjs";
import { ClockClockwise as sS } from "./csr/ClockClockwise.mjs";
import { ClockCountdown as cS } from "./csr/ClockCountdown.mjs";
import { ClockCounterClockwise as gS } from "./csr/ClockCounterClockwise.mjs";
import { ClockUser as hS } from "./csr/ClockUser.mjs";
import { ClosedCaptioning as wS } from "./csr/ClosedCaptioning.mjs";
import { CloudArrowDown as PS } from "./csr/CloudArrowDown.mjs";
import { CloudArrowUp as TS } from "./csr/CloudArrowUp.mjs";
import { Cloud as FS } from "./csr/Cloud.mjs";
import { CloudCheck as bS } from "./csr/CloudCheck.mjs";
import { CloudFog as yS } from "./csr/CloudFog.mjs";
import { CloudLightning as MS } from "./csr/CloudLightning.mjs";
import { CloudMoon as US } from "./csr/CloudMoon.mjs";
import { CloudRain as vS } from "./csr/CloudRain.mjs";
import { CloudSlash as OS } from "./csr/CloudSlash.mjs";
import { CloudSnow as WS } from "./csr/CloudSnow.mjs";
import { CloudSun as VS } from "./csr/CloudSun.mjs";
import { CloudWarning as zS } from "./csr/CloudWarning.mjs";
import { CloudX as KS } from "./csr/CloudX.mjs";
import { Clover as QS } from "./csr/Clover.mjs";
import { Club as jS } from "./csr/Club.mjs";
import { CoatHanger as _S } from "./csr/CoatHanger.mjs";
import { CodaLogo as rc } from "./csr/CodaLogo.mjs";
import { CodeBlock as ec } from "./csr/CodeBlock.mjs";
import { Code as pc } from "./csr/Code.mjs";
import { CodeSimple as fc } from "./csr/CodeSimple.mjs";
import { CodepenLogo as ic } from "./csr/CodepenLogo.mjs";
import { CodesandboxLogo as lc } from "./csr/CodesandboxLogo.mjs";
import { CoffeeBean as sc } from "./csr/CoffeeBean.mjs";
import { Coffee as cc } from "./csr/Coffee.mjs";
import { Coin as gc } from "./csr/Coin.mjs";
import { CoinVertical as hc } from "./csr/CoinVertical.mjs";
import { Coins as wc } from "./csr/Coins.mjs";
import { Columns as Pc } from "./csr/Columns.mjs";
import { ColumnsPlusLeft as Tc } from "./csr/ColumnsPlusLeft.mjs";
import { ColumnsPlusRight as Fc } from "./csr/ColumnsPlusRight.mjs";
import { Command as bc } from "./csr/Command.mjs";
import { Compass as yc } from "./csr/Compass.mjs";
import { CompassRose as Mc } from "./csr/CompassRose.mjs";
import { CompassTool as Uc } from "./csr/CompassTool.mjs";
import { ComputerTower as vc } from "./csr/ComputerTower.mjs";
import { Confetti as Oc } from "./csr/Confetti.mjs";
import { ContactlessPayment as Wc } from "./csr/ContactlessPayment.mjs";
import { Control as Vc } from "./csr/Control.mjs";
import { Cookie as zc } from "./csr/Cookie.mjs";
import { CookingPot as Kc } from "./csr/CookingPot.mjs";
import { Copy as Qc } from "./csr/Copy.mjs";
import { CopySimple as jc } from "./csr/CopySimple.mjs";
import { Copyleft as _c } from "./csr/Copyleft.mjs";
import { Copyright as ru } from "./csr/Copyright.mjs";
import { CornersIn as eu } from "./csr/CornersIn.mjs";
import { CornersOut as pu } from "./csr/CornersOut.mjs";
import { Couch as fu } from "./csr/Couch.mjs";
import { CourtBasketball as iu } from "./csr/CourtBasketball.mjs";
import { Cow as lu } from "./csr/Cow.mjs";
import { CowboyHat as su } from "./csr/CowboyHat.mjs";
import { Cpu as cu } from "./csr/Cpu.mjs";
import { Crane as gu } from "./csr/Crane.mjs";
import { CraneTower as hu } from "./csr/CraneTower.mjs";
import { CreditCard as wu } from "./csr/CreditCard.mjs";
import { Cricket as Pu } from "./csr/Cricket.mjs";
import { Crop as Tu } from "./csr/Crop.mjs";
import { Cross as Fu } from "./csr/Cross.mjs";
import { Crosshair as bu } from "./csr/Crosshair.mjs";
import { CrosshairSimple as yu } from "./csr/CrosshairSimple.mjs";
import { Crown as Mu } from "./csr/Crown.mjs";
import { CrownCross as Uu } from "./csr/CrownCross.mjs";
import { CrownSimple as vu } from "./csr/CrownSimple.mjs";
import { Cube as Ou } from "./csr/Cube.mjs";
import { CubeFocus as Wu } from "./csr/CubeFocus.mjs";
import { CubeTransparent as Vu } from "./csr/CubeTransparent.mjs";
import { CurrencyBtc as zu } from "./csr/CurrencyBtc.mjs";
import { CurrencyCircleDollar as Ku } from "./csr/CurrencyCircleDollar.mjs";
import { CurrencyCny as Qu } from "./csr/CurrencyCny.mjs";
import { CurrencyDollar as ju } from "./csr/CurrencyDollar.mjs";
import { CurrencyDollarSimple as _u } from "./csr/CurrencyDollarSimple.mjs";
import { CurrencyEth as rg } from "./csr/CurrencyEth.mjs";
import { CurrencyEur as eg } from "./csr/CurrencyEur.mjs";
import { CurrencyGbp as pg } from "./csr/CurrencyGbp.mjs";
import { CurrencyInr as fg } from "./csr/CurrencyInr.mjs";
import { CurrencyJpy as ig } from "./csr/CurrencyJpy.mjs";
import { CurrencyKrw as lg } from "./csr/CurrencyKrw.mjs";
import { CurrencyKzt as sg } from "./csr/CurrencyKzt.mjs";
import { CurrencyNgn as cg } from "./csr/CurrencyNgn.mjs";
import { CurrencyRub as gg } from "./csr/CurrencyRub.mjs";
import { Cursor as hg } from "./csr/Cursor.mjs";
import { CursorClick as wg } from "./csr/CursorClick.mjs";
import { CursorText as Pg } from "./csr/CursorText.mjs";
import { Cylinder as Tg } from "./csr/Cylinder.mjs";
import { Database as Fg } from "./csr/Database.mjs";
import { Desk as bg } from "./csr/Desk.mjs";
import { Desktop as yg } from "./csr/Desktop.mjs";
import { DesktopTower as Mg } from "./csr/DesktopTower.mjs";
import { Detective as Ug } from "./csr/Detective.mjs";
import { DevToLogo as vg } from "./csr/DevToLogo.mjs";
import { DeviceMobile as Og } from "./csr/DeviceMobile.mjs";
import { DeviceMobileCamera as Wg } from "./csr/DeviceMobileCamera.mjs";
import { DeviceMobileSlash as Vg } from "./csr/DeviceMobileSlash.mjs";
import { DeviceMobileSpeaker as zg } from "./csr/DeviceMobileSpeaker.mjs";
import { DeviceRotate as Kg } from "./csr/DeviceRotate.mjs";
import { DeviceTablet as Qg } from "./csr/DeviceTablet.mjs";
import { DeviceTabletCamera as jg } from "./csr/DeviceTabletCamera.mjs";
import { DeviceTabletSpeaker as _g } from "./csr/DeviceTabletSpeaker.mjs";
import { Devices as rC } from "./csr/Devices.mjs";
import { Diamond as eC } from "./csr/Diamond.mjs";
import { DiamondsFour as pC } from "./csr/DiamondsFour.mjs";
import { DiceFive as fC } from "./csr/DiceFive.mjs";
import { DiceFour as iC } from "./csr/DiceFour.mjs";
import { DiceOne as lC } from "./csr/DiceOne.mjs";
import { DiceSix as sC } from "./csr/DiceSix.mjs";
import { DiceThree as cC } from "./csr/DiceThree.mjs";
import { DiceTwo as gC } from "./csr/DiceTwo.mjs";
import { Disc as hC } from "./csr/Disc.mjs";
import { DiscoBall as wC } from "./csr/DiscoBall.mjs";
import { DiscordLogo as PC } from "./csr/DiscordLogo.mjs";
import { Divide as TC } from "./csr/Divide.mjs";
import { Dna as FC } from "./csr/Dna.mjs";
import { Dog as bC } from "./csr/Dog.mjs";
import { Door as yC } from "./csr/Door.mjs";
import { DoorOpen as MC } from "./csr/DoorOpen.mjs";
import { Dot as UC } from "./csr/Dot.mjs";
import { DotOutline as vC } from "./csr/DotOutline.mjs";
import { DotsNine as OC } from "./csr/DotsNine.mjs";
import { DotsSix as WC } from "./csr/DotsSix.mjs";
import { DotsSixVertical as VC } from "./csr/DotsSixVertical.mjs";
import { DotsThree as zC } from "./csr/DotsThree.mjs";
import { DotsThreeCircle as KC } from "./csr/DotsThreeCircle.mjs";
import { DotsThreeCircleVertical as QC } from "./csr/DotsThreeCircleVertical.mjs";
import { DotsThreeOutline as jC } from "./csr/DotsThreeOutline.mjs";
import { DotsThreeOutlineVertical as _C } from "./csr/DotsThreeOutlineVertical.mjs";
import { DotsThreeVertical as rh } from "./csr/DotsThreeVertical.mjs";
import { Download as eh } from "./csr/Download.mjs";
import { DownloadSimple as ph } from "./csr/DownloadSimple.mjs";
import { Dress as fh } from "./csr/Dress.mjs";
import { Dresser as ih } from "./csr/Dresser.mjs";
import { DribbbleLogo as lh } from "./csr/DribbbleLogo.mjs";
import { Drone as sh } from "./csr/Drone.mjs";
import { Drop as ch } from "./csr/Drop.mjs";
import { DropHalf as gh } from "./csr/DropHalf.mjs";
import { DropHalfBottom as hh } from "./csr/DropHalfBottom.mjs";
import { DropSimple as wh } from "./csr/DropSimple.mjs";
import { DropSlash as Ph } from "./csr/DropSlash.mjs";
import { DropboxLogo as Th } from "./csr/DropboxLogo.mjs";
import { Ear as Fh } from "./csr/Ear.mjs";
import { EarSlash as bh } from "./csr/EarSlash.mjs";
import { Egg as yh } from "./csr/Egg.mjs";
import { EggCrack as Mh } from "./csr/EggCrack.mjs";
import { Eject as Uh } from "./csr/Eject.mjs";
import { EjectSimple as vh } from "./csr/EjectSimple.mjs";
import { Elevator as Oh } from "./csr/Elevator.mjs";
import { Empty as Wh } from "./csr/Empty.mjs";
import { Engine as Vh } from "./csr/Engine.mjs";
import { Envelope as zh } from "./csr/Envelope.mjs";
import { EnvelopeOpen as Kh } from "./csr/EnvelopeOpen.mjs";
import { EnvelopeSimple as Qh } from "./csr/EnvelopeSimple.mjs";
import { EnvelopeSimpleOpen as jh } from "./csr/EnvelopeSimpleOpen.mjs";
import { Equalizer as _h } from "./csr/Equalizer.mjs";
import { Equals as rd } from "./csr/Equals.mjs";
import { Eraser as ed } from "./csr/Eraser.mjs";
import { EscalatorDown as pd } from "./csr/EscalatorDown.mjs";
import { EscalatorUp as fd } from "./csr/EscalatorUp.mjs";
import { Exam as id } from "./csr/Exam.mjs";
import { ExclamationMark as ld } from "./csr/ExclamationMark.mjs";
import { Exclude as sd } from "./csr/Exclude.mjs";
import { ExcludeSquare as cd } from "./csr/ExcludeSquare.mjs";
import { Export as gd } from "./csr/Export.mjs";
import { Eye as hd } from "./csr/Eye.mjs";
import { EyeClosed as wd } from "./csr/EyeClosed.mjs";
import { EyeSlash as Pd } from "./csr/EyeSlash.mjs";
import { Eyedropper as Td } from "./csr/Eyedropper.mjs";
import { EyedropperSample as Fd } from "./csr/EyedropperSample.mjs";
import { Eyeglasses as bd } from "./csr/Eyeglasses.mjs";
import { Eyes as yd } from "./csr/Eyes.mjs";
import { FaceMask as Md } from "./csr/FaceMask.mjs";
import { FacebookLogo as Ud } from "./csr/FacebookLogo.mjs";
import { Factory as vd } from "./csr/Factory.mjs";
import { Faders as Od } from "./csr/Faders.mjs";
import { FadersHorizontal as Wd } from "./csr/FadersHorizontal.mjs";
import { FalloutShelter as Vd } from "./csr/FalloutShelter.mjs";
import { Fan as zd } from "./csr/Fan.mjs";
import { Farm as Kd } from "./csr/Farm.mjs";
import { FastForward as Qd } from "./csr/FastForward.mjs";
import { FastForwardCircle as jd } from "./csr/FastForwardCircle.mjs";
import { Feather as _d } from "./csr/Feather.mjs";
import { FediverseLogo as rw } from "./csr/FediverseLogo.mjs";
import { FigmaLogo as ew } from "./csr/FigmaLogo.mjs";
import { FileArchive as pw } from "./csr/FileArchive.mjs";
import { FileArrowDown as fw } from "./csr/FileArrowDown.mjs";
import { FileArrowUp as iw } from "./csr/FileArrowUp.mjs";
import { FileAudio as lw } from "./csr/FileAudio.mjs";
import { File as sw } from "./csr/File.mjs";
import { FileC as cw } from "./csr/FileC.mjs";
import { FileCSharp as gw } from "./csr/FileCSharp.mjs";
import { FileCloud as hw } from "./csr/FileCloud.mjs";
import { FileCode as ww } from "./csr/FileCode.mjs";
import { FileCpp as Pw } from "./csr/FileCpp.mjs";
import { FileCss as Tw } from "./csr/FileCss.mjs";
import { FileCsv as Fw } from "./csr/FileCsv.mjs";
import { FileDashed as bw, FileDashed as Dw } from "./csr/FileDashed.mjs";
import { FileDoc as Hw } from "./csr/FileDoc.mjs";
import { FileHtml as Rw } from "./csr/FileHtml.mjs";
import { FileImage as Nw } from "./csr/FileImage.mjs";
import { FileIni as Gw } from "./csr/FileIni.mjs";
import { FileJpg as Ew } from "./csr/FileJpg.mjs";
import { FileJs as qw } from "./csr/FileJs.mjs";
import { FileJsx as Iw } from "./csr/FileJsx.mjs";
import { FileLock as Xw } from "./csr/FileLock.mjs";
import { FileMagnifyingGlass as Jw, FileMagnifyingGlass as Qw } from "./csr/FileMagnifyingGlass.mjs";
import { FileMd as jw } from "./csr/FileMd.mjs";
import { FileMinus as _w } from "./csr/FileMinus.mjs";
import { FilePdf as rL } from "./csr/FilePdf.mjs";
import { FilePlus as eL } from "./csr/FilePlus.mjs";
import { FilePng as pL } from "./csr/FilePng.mjs";
import { FilePpt as fL } from "./csr/FilePpt.mjs";
import { FilePy as iL } from "./csr/FilePy.mjs";
import { FileRs as lL } from "./csr/FileRs.mjs";
import { FileSql as sL } from "./csr/FileSql.mjs";
import { FileSvg as cL } from "./csr/FileSvg.mjs";
import { FileText as gL } from "./csr/FileText.mjs";
import { FileTs as hL } from "./csr/FileTs.mjs";
import { FileTsx as wL } from "./csr/FileTsx.mjs";
import { FileTxt as PL } from "./csr/FileTxt.mjs";
import { FileVideo as TL } from "./csr/FileVideo.mjs";
import { FileVue as FL } from "./csr/FileVue.mjs";
import { FileX as bL } from "./csr/FileX.mjs";
import { FileXls as yL } from "./csr/FileXls.mjs";
import { FileZip as ML } from "./csr/FileZip.mjs";
import { Files as UL } from "./csr/Files.mjs";
import { FilmReel as vL } from "./csr/FilmReel.mjs";
import { FilmScript as OL } from "./csr/FilmScript.mjs";
import { FilmSlate as WL } from "./csr/FilmSlate.mjs";
import { FilmStrip as VL } from "./csr/FilmStrip.mjs";
import { Fingerprint as zL } from "./csr/Fingerprint.mjs";
import { FingerprintSimple as KL } from "./csr/FingerprintSimple.mjs";
import { FinnTheHuman as QL } from "./csr/FinnTheHuman.mjs";
import { Fire as jL } from "./csr/Fire.mjs";
import { FireExtinguisher as _L } from "./csr/FireExtinguisher.mjs";
import { FireSimple as rP } from "./csr/FireSimple.mjs";
import { FireTruck as eP } from "./csr/FireTruck.mjs";
import { FirstAid as pP } from "./csr/FirstAid.mjs";
import { FirstAidKit as fP } from "./csr/FirstAidKit.mjs";
import { Fish as iP } from "./csr/Fish.mjs";
import { FishSimple as lP } from "./csr/FishSimple.mjs";
import { FlagBanner as sP } from "./csr/FlagBanner.mjs";
import { FlagBannerFold as cP } from "./csr/FlagBannerFold.mjs";
import { Flag as gP } from "./csr/Flag.mjs";
import { FlagCheckered as hP } from "./csr/FlagCheckered.mjs";
import { FlagPennant as wP } from "./csr/FlagPennant.mjs";
import { Flame as PP } from "./csr/Flame.mjs";
import { Flashlight as TP } from "./csr/Flashlight.mjs";
import { Flask as FP } from "./csr/Flask.mjs";
import { FlipHorizontal as bP } from "./csr/FlipHorizontal.mjs";
import { FlipVertical as yP } from "./csr/FlipVertical.mjs";
import { FloppyDiskBack as MP } from "./csr/FloppyDiskBack.mjs";
import { FloppyDisk as UP } from "./csr/FloppyDisk.mjs";
import { FlowArrow as vP } from "./csr/FlowArrow.mjs";
import { Flower as OP } from "./csr/Flower.mjs";
import { FlowerLotus as WP } from "./csr/FlowerLotus.mjs";
import { FlowerTulip as VP } from "./csr/FlowerTulip.mjs";
import { FlyingSaucer as zP } from "./csr/FlyingSaucer.mjs";
import { Folder as KP, Folder as JP } from "./csr/Folder.mjs";
import { FolderDashed as ZP, FolderDashed as jP } from "./csr/FolderDashed.mjs";
import { FolderLock as _P } from "./csr/FolderLock.mjs";
import { FolderMinus as rA, FolderMinus as oA } from "./csr/FolderMinus.mjs";
import { FolderOpen as tA, FolderOpen as pA } from "./csr/FolderOpen.mjs";
import { FolderPlus as fA, FolderPlus as xA } from "./csr/FolderPlus.mjs";
import { FolderSimple as aA } from "./csr/FolderSimple.mjs";
import { FolderSimpleDashed as nA, FolderSimpleDashed as sA } from "./csr/FolderSimpleDashed.mjs";
import { FolderSimpleLock as cA } from "./csr/FolderSimpleLock.mjs";
import { FolderSimpleMinus as gA } from "./csr/FolderSimpleMinus.mjs";
import { FolderSimplePlus as hA } from "./csr/FolderSimplePlus.mjs";
import { FolderSimpleStar as wA } from "./csr/FolderSimpleStar.mjs";
import { FolderSimpleUser as PA } from "./csr/FolderSimpleUser.mjs";
import { FolderStar as TA } from "./csr/FolderStar.mjs";
import { FolderUser as FA } from "./csr/FolderUser.mjs";
import { Folders as bA } from "./csr/Folders.mjs";
import { Football as yA } from "./csr/Football.mjs";
import { FootballHelmet as MA } from "./csr/FootballHelmet.mjs";
import { Footprints as UA } from "./csr/Footprints.mjs";
import { ForkKnife as vA } from "./csr/ForkKnife.mjs";
import { FourK as OA } from "./csr/FourK.mjs";
import { FrameCorners as WA } from "./csr/FrameCorners.mjs";
import { FramerLogo as VA } from "./csr/FramerLogo.mjs";
import { Function as zA } from "./csr/Function.mjs";
import { Funnel as KA } from "./csr/Funnel.mjs";
import { FunnelSimple as QA } from "./csr/FunnelSimple.mjs";
import { FunnelSimpleX as jA } from "./csr/FunnelSimpleX.mjs";
import { FunnelX as _A } from "./csr/FunnelX.mjs";
import { GameController as rT } from "./csr/GameController.mjs";
import { Garage as eT } from "./csr/Garage.mjs";
import { GasCan as pT } from "./csr/GasCan.mjs";
import { GasPump as fT } from "./csr/GasPump.mjs";
import { Gauge as iT } from "./csr/Gauge.mjs";
import { Gavel as lT } from "./csr/Gavel.mjs";
import { Gear as sT } from "./csr/Gear.mjs";
import { GearFine as cT } from "./csr/GearFine.mjs";
import { GearSix as gT } from "./csr/GearSix.mjs";
import { GenderFemale as hT } from "./csr/GenderFemale.mjs";
import { GenderIntersex as wT } from "./csr/GenderIntersex.mjs";
import { GenderMale as PT } from "./csr/GenderMale.mjs";
import { GenderNeuter as TT } from "./csr/GenderNeuter.mjs";
import { GenderNonbinary as FT } from "./csr/GenderNonbinary.mjs";
import { GenderTransgender as bT } from "./csr/GenderTransgender.mjs";
import { Ghost as yT } from "./csr/Ghost.mjs";
import { Gif as MT } from "./csr/Gif.mjs";
import { Gift as UT } from "./csr/Gift.mjs";
import { GitBranch as vT } from "./csr/GitBranch.mjs";
import { GitCommit as OT } from "./csr/GitCommit.mjs";
import { GitDiff as WT } from "./csr/GitDiff.mjs";
import { GitFork as VT } from "./csr/GitFork.mjs";
import { GitMerge as zT } from "./csr/GitMerge.mjs";
import { GitPullRequest as KT } from "./csr/GitPullRequest.mjs";
import { GithubLogo as QT } from "./csr/GithubLogo.mjs";
import { GitlabLogo as jT } from "./csr/GitlabLogo.mjs";
import { GitlabLogoSimple as _T } from "./csr/GitlabLogoSimple.mjs";
import { Globe as rk } from "./csr/Globe.mjs";
import { GlobeHemisphereEast as ek } from "./csr/GlobeHemisphereEast.mjs";
import { GlobeHemisphereWest as pk } from "./csr/GlobeHemisphereWest.mjs";
import { GlobeSimple as fk } from "./csr/GlobeSimple.mjs";
import { GlobeSimpleX as ik } from "./csr/GlobeSimpleX.mjs";
import { GlobeStand as lk } from "./csr/GlobeStand.mjs";
import { GlobeX as sk } from "./csr/GlobeX.mjs";
import { Goggles as ck } from "./csr/Goggles.mjs";
import { Golf as gk } from "./csr/Golf.mjs";
import { GoodreadsLogo as hk } from "./csr/GoodreadsLogo.mjs";
import { GoogleCardboardLogo as wk } from "./csr/GoogleCardboardLogo.mjs";
import { GoogleChromeLogo as Pk } from "./csr/GoogleChromeLogo.mjs";
import { GoogleDriveLogo as Tk } from "./csr/GoogleDriveLogo.mjs";
import { GoogleLogo as Fk } from "./csr/GoogleLogo.mjs";
import { GooglePhotosLogo as bk } from "./csr/GooglePhotosLogo.mjs";
import { GooglePlayLogo as yk } from "./csr/GooglePlayLogo.mjs";
import { GooglePodcastsLogo as Mk } from "./csr/GooglePodcastsLogo.mjs";
import { Gps as Uk } from "./csr/Gps.mjs";
import { GpsFix as vk } from "./csr/GpsFix.mjs";
import { GpsSlash as Ok } from "./csr/GpsSlash.mjs";
import { Gradient as Wk } from "./csr/Gradient.mjs";
import { GraduationCap as Vk } from "./csr/GraduationCap.mjs";
import { Grains as zk } from "./csr/Grains.mjs";
import { GrainsSlash as Kk } from "./csr/GrainsSlash.mjs";
import { Graph as Qk } from "./csr/Graph.mjs";
import { GraphicsCard as jk } from "./csr/GraphicsCard.mjs";
import { GreaterThan as _k } from "./csr/GreaterThan.mjs";
import { GreaterThanOrEqual as rF } from "./csr/GreaterThanOrEqual.mjs";
import { GridFour as eF } from "./csr/GridFour.mjs";
import { GridNine as pF } from "./csr/GridNine.mjs";
import { Guitar as fF } from "./csr/Guitar.mjs";
import { HairDryer as iF } from "./csr/HairDryer.mjs";
import { Hamburger as lF } from "./csr/Hamburger.mjs";
import { Hammer as sF } from "./csr/Hammer.mjs";
import { HandArrowDown as cF } from "./csr/HandArrowDown.mjs";
import { HandArrowUp as gF } from "./csr/HandArrowUp.mjs";
import { Hand as hF } from "./csr/Hand.mjs";
import { HandCoins as wF } from "./csr/HandCoins.mjs";
import { HandDeposit as PF } from "./csr/HandDeposit.mjs";
import { HandEye as TF } from "./csr/HandEye.mjs";
import { HandFist as FF } from "./csr/HandFist.mjs";
import { HandGrabbing as bF } from "./csr/HandGrabbing.mjs";
import { HandHeart as yF } from "./csr/HandHeart.mjs";
import { HandPalm as MF } from "./csr/HandPalm.mjs";
import { HandPeace as UF } from "./csr/HandPeace.mjs";
import { HandPointing as vF } from "./csr/HandPointing.mjs";
import { HandSoap as OF } from "./csr/HandSoap.mjs";
import { HandSwipeLeft as WF } from "./csr/HandSwipeLeft.mjs";
import { HandSwipeRight as VF } from "./csr/HandSwipeRight.mjs";
import { HandTap as zF } from "./csr/HandTap.mjs";
import { HandWaving as KF } from "./csr/HandWaving.mjs";
import { HandWithdraw as QF } from "./csr/HandWithdraw.mjs";
import { Handbag as jF } from "./csr/Handbag.mjs";
import { HandbagSimple as _F } from "./csr/HandbagSimple.mjs";
import { HandsClapping as rB } from "./csr/HandsClapping.mjs";
import { HandsPraying as eB } from "./csr/HandsPraying.mjs";
import { Handshake as pB } from "./csr/Handshake.mjs";
import { HardDrive as fB } from "./csr/HardDrive.mjs";
import { HardDrives as iB } from "./csr/HardDrives.mjs";
import { HardHat as lB } from "./csr/HardHat.mjs";
import { Hash as sB } from "./csr/Hash.mjs";
import { HashStraight as cB } from "./csr/HashStraight.mjs";
import { HeadCircuit as gB } from "./csr/HeadCircuit.mjs";
import { Headlights as hB } from "./csr/Headlights.mjs";
import { Headphones as wB } from "./csr/Headphones.mjs";
import { Headset as PB } from "./csr/Headset.mjs";
import { Heart as TB } from "./csr/Heart.mjs";
import { HeartBreak as FB } from "./csr/HeartBreak.mjs";
import { HeartHalf as bB } from "./csr/HeartHalf.mjs";
import { HeartStraight as yB } from "./csr/HeartStraight.mjs";
import { HeartStraightBreak as MB } from "./csr/HeartStraightBreak.mjs";
import { Heartbeat as UB } from "./csr/Heartbeat.mjs";
import { Hexagon as vB } from "./csr/Hexagon.mjs";
import { HighDefinition as OB } from "./csr/HighDefinition.mjs";
import { HighHeel as WB } from "./csr/HighHeel.mjs";
import { Highlighter as VB } from "./csr/Highlighter.mjs";
import { HighlighterCircle as zB } from "./csr/HighlighterCircle.mjs";
import { Hockey as KB } from "./csr/Hockey.mjs";
import { Hoodie as QB } from "./csr/Hoodie.mjs";
import { Horse as jB } from "./csr/Horse.mjs";
import { Hospital as _B } from "./csr/Hospital.mjs";
import { Hourglass as rb } from "./csr/Hourglass.mjs";
import { HourglassHigh as eb } from "./csr/HourglassHigh.mjs";
import { HourglassLow as pb } from "./csr/HourglassLow.mjs";
import { HourglassMedium as fb } from "./csr/HourglassMedium.mjs";
import { HourglassSimple as ib } from "./csr/HourglassSimple.mjs";
import { HourglassSimpleHigh as lb } from "./csr/HourglassSimpleHigh.mjs";
import { HourglassSimpleLow as sb } from "./csr/HourglassSimpleLow.mjs";
import { HourglassSimpleMedium as cb } from "./csr/HourglassSimpleMedium.mjs";
import { House as gb } from "./csr/House.mjs";
import { HouseLine as hb } from "./csr/HouseLine.mjs";
import { HouseSimple as wb } from "./csr/HouseSimple.mjs";
import { Hurricane as Pb } from "./csr/Hurricane.mjs";
import { IceCream as Tb } from "./csr/IceCream.mjs";
import { IdentificationBadge as Fb } from "./csr/IdentificationBadge.mjs";
import { IdentificationCard as bb } from "./csr/IdentificationCard.mjs";
import { Image as yb } from "./csr/Image.mjs";
import { ImageBroken as Mb } from "./csr/ImageBroken.mjs";
import { ImageSquare as Ub } from "./csr/ImageSquare.mjs";
import { Images as vb } from "./csr/Images.mjs";
import { ImagesSquare as Ob } from "./csr/ImagesSquare.mjs";
import { Infinity as Wb, Infinity as qb } from "./csr/Infinity.mjs";
import { Info as Ib } from "./csr/Info.mjs";
import { InstagramLogo as Xb } from "./csr/InstagramLogo.mjs";
import { Intersect as Jb } from "./csr/Intersect.mjs";
import { IntersectSquare as Zb } from "./csr/IntersectSquare.mjs";
import { IntersectThree as Yb } from "./csr/IntersectThree.mjs";
import { Intersection as $b } from "./csr/Intersection.mjs";
import { Invoice as oD } from "./csr/Invoice.mjs";
import { Island as tD } from "./csr/Island.mjs";
import { Jar as mD } from "./csr/Jar.mjs";
import { JarLabel as xD } from "./csr/JarLabel.mjs";
import { Jeep as aD } from "./csr/Jeep.mjs";
import { Joystick as nD } from "./csr/Joystick.mjs";
import { Kanban as SD } from "./csr/Kanban.mjs";
import { Key as uD } from "./csr/Key.mjs";
import { KeyReturn as CD } from "./csr/KeyReturn.mjs";
import { Keyboard as dD } from "./csr/Keyboard.mjs";
import { Keyhole as LD } from "./csr/Keyhole.mjs";
import { Knife as AD } from "./csr/Knife.mjs";
import { Ladder as kD } from "./csr/Ladder.mjs";
import { LadderSimple as BD } from "./csr/LadderSimple.mjs";
import { Lamp as DD } from "./csr/Lamp.mjs";
import { LampPendant as HD } from "./csr/LampPendant.mjs";
import { Laptop as RD } from "./csr/Laptop.mjs";
import { Lasso as ND } from "./csr/Lasso.mjs";
import { LastfmLogo as GD } from "./csr/LastfmLogo.mjs";
import { Layout as ED } from "./csr/Layout.mjs";
import { Leaf as qD } from "./csr/Leaf.mjs";
import { Lectern as ID } from "./csr/Lectern.mjs";
import { Lego as XD } from "./csr/Lego.mjs";
import { LegoSmiley as JD } from "./csr/LegoSmiley.mjs";
import { LessThan as ZD } from "./csr/LessThan.mjs";
import { LessThanOrEqual as YD } from "./csr/LessThanOrEqual.mjs";
import { LetterCircleH as $D } from "./csr/LetterCircleH.mjs";
import { LetterCircleP as oy } from "./csr/LetterCircleP.mjs";
import { LetterCircleV as ty } from "./csr/LetterCircleV.mjs";
import { Lifebuoy as my } from "./csr/Lifebuoy.mjs";
import { Lightbulb as xy } from "./csr/Lightbulb.mjs";
import { LightbulbFilament as ay } from "./csr/LightbulbFilament.mjs";
import { Lighthouse as ny } from "./csr/Lighthouse.mjs";
import { LightningA as Sy } from "./csr/LightningA.mjs";
import { Lightning as uy } from "./csr/Lightning.mjs";
import { LightningSlash as Cy } from "./csr/LightningSlash.mjs";
import { LineSegment as dy } from "./csr/LineSegment.mjs";
import { LineSegments as Ly } from "./csr/LineSegments.mjs";
import { LineVertical as Ay } from "./csr/LineVertical.mjs";
import { Link as ky } from "./csr/Link.mjs";
import { LinkBreak as By } from "./csr/LinkBreak.mjs";
import { LinkSimple as Dy } from "./csr/LinkSimple.mjs";
import { LinkSimpleBreak as Hy } from "./csr/LinkSimpleBreak.mjs";
import { LinkSimpleHorizontal as Ry } from "./csr/LinkSimpleHorizontal.mjs";
import { LinkSimpleHorizontalBreak as Ny } from "./csr/LinkSimpleHorizontalBreak.mjs";
import { LinkedinLogo as Gy } from "./csr/LinkedinLogo.mjs";
import { LinktreeLogo as Ey } from "./csr/LinktreeLogo.mjs";
import { LinuxLogo as qy } from "./csr/LinuxLogo.mjs";
import { List as Iy } from "./csr/List.mjs";
import { ListBullets as Xy } from "./csr/ListBullets.mjs";
import { ListChecks as Jy } from "./csr/ListChecks.mjs";
import { ListDashes as Zy } from "./csr/ListDashes.mjs";
import { ListHeart as Yy } from "./csr/ListHeart.mjs";
import { ListMagnifyingGlass as $y } from "./csr/ListMagnifyingGlass.mjs";
import { ListNumbers as oH } from "./csr/ListNumbers.mjs";
import { ListPlus as tH } from "./csr/ListPlus.mjs";
import { ListStar as mH } from "./csr/ListStar.mjs";
import { Lock as xH } from "./csr/Lock.mjs";
import { LockKey as aH } from "./csr/LockKey.mjs";
import { LockKeyOpen as nH } from "./csr/LockKeyOpen.mjs";
import { LockLaminated as SH } from "./csr/LockLaminated.mjs";
import { LockLaminatedOpen as uH } from "./csr/LockLaminatedOpen.mjs";
import { LockOpen as CH } from "./csr/LockOpen.mjs";
import { LockSimple as dH } from "./csr/LockSimple.mjs";
import { LockSimpleOpen as LH } from "./csr/LockSimpleOpen.mjs";
import { Lockers as AH } from "./csr/Lockers.mjs";
import { Log as kH } from "./csr/Log.mjs";
import { MagicWand as BH } from "./csr/MagicWand.mjs";
import { Magnet as DH } from "./csr/Magnet.mjs";
import { MagnetStraight as HH } from "./csr/MagnetStraight.mjs";
import { MagnifyingGlass as RH } from "./csr/MagnifyingGlass.mjs";
import { MagnifyingGlassMinus as NH } from "./csr/MagnifyingGlassMinus.mjs";
import { MagnifyingGlassPlus as GH } from "./csr/MagnifyingGlassPlus.mjs";
import { Mailbox as EH } from "./csr/Mailbox.mjs";
import { MapPinArea as qH } from "./csr/MapPinArea.mjs";
import { MapPin as IH } from "./csr/MapPin.mjs";
import { MapPinLine as XH } from "./csr/MapPinLine.mjs";
import { MapPinPlus as JH } from "./csr/MapPinPlus.mjs";
import { MapPinSimpleArea as ZH } from "./csr/MapPinSimpleArea.mjs";
import { MapPinSimple as YH } from "./csr/MapPinSimple.mjs";
import { MapPinSimpleLine as $H } from "./csr/MapPinSimpleLine.mjs";
import { MapTrifold as oM } from "./csr/MapTrifold.mjs";
import { MarkdownLogo as tM } from "./csr/MarkdownLogo.mjs";
import { MarkerCircle as mM } from "./csr/MarkerCircle.mjs";
import { Martini as xM } from "./csr/Martini.mjs";
import { MaskHappy as aM } from "./csr/MaskHappy.mjs";
import { MaskSad as nM } from "./csr/MaskSad.mjs";
import { MastodonLogo as SM } from "./csr/MastodonLogo.mjs";
import { MathOperations as uM } from "./csr/MathOperations.mjs";
import { MatrixLogo as CM } from "./csr/MatrixLogo.mjs";
import { Medal as dM } from "./csr/Medal.mjs";
import { MedalMilitary as LM } from "./csr/MedalMilitary.mjs";
import { MediumLogo as AM } from "./csr/MediumLogo.mjs";
import { Megaphone as kM } from "./csr/Megaphone.mjs";
import { MegaphoneSimple as BM } from "./csr/MegaphoneSimple.mjs";
import { MemberOf as DM } from "./csr/MemberOf.mjs";
import { Memory as HM } from "./csr/Memory.mjs";
import { MessengerLogo as RM } from "./csr/MessengerLogo.mjs";
import { MetaLogo as NM } from "./csr/MetaLogo.mjs";
import { Meteor as GM } from "./csr/Meteor.mjs";
import { Metronome as EM } from "./csr/Metronome.mjs";
import { Microphone as qM } from "./csr/Microphone.mjs";
import { MicrophoneSlash as IM } from "./csr/MicrophoneSlash.mjs";
import { MicrophoneStage as XM } from "./csr/MicrophoneStage.mjs";
import { Microscope as JM } from "./csr/Microscope.mjs";
import { MicrosoftExcelLogo as ZM } from "./csr/MicrosoftExcelLogo.mjs";
import { MicrosoftOutlookLogo as YM } from "./csr/MicrosoftOutlookLogo.mjs";
import { MicrosoftPowerpointLogo as $M } from "./csr/MicrosoftPowerpointLogo.mjs";
import { MicrosoftTeamsLogo as oR } from "./csr/MicrosoftTeamsLogo.mjs";
import { MicrosoftWordLogo as tR } from "./csr/MicrosoftWordLogo.mjs";
import { Minus as mR } from "./csr/Minus.mjs";
import { MinusCircle as xR } from "./csr/MinusCircle.mjs";
import { MinusSquare as aR } from "./csr/MinusSquare.mjs";
import { Money as nR } from "./csr/Money.mjs";
import { MoneyWavy as SR } from "./csr/MoneyWavy.mjs";
import { MonitorArrowUp as uR } from "./csr/MonitorArrowUp.mjs";
import { Monitor as CR } from "./csr/Monitor.mjs";
import { MonitorPlay as dR } from "./csr/MonitorPlay.mjs";
import { Moon as LR } from "./csr/Moon.mjs";
import { MoonStars as AR } from "./csr/MoonStars.mjs";
import { Moped as kR } from "./csr/Moped.mjs";
import { MopedFront as BR } from "./csr/MopedFront.mjs";
import { Mosque as DR } from "./csr/Mosque.mjs";
import { Motorcycle as HR } from "./csr/Motorcycle.mjs";
import { Mountains as RR } from "./csr/Mountains.mjs";
import { Mouse as NR } from "./csr/Mouse.mjs";
import { MouseLeftClick as GR } from "./csr/MouseLeftClick.mjs";
import { MouseMiddleClick as ER } from "./csr/MouseMiddleClick.mjs";
import { MouseRightClick as qR } from "./csr/MouseRightClick.mjs";
import { MouseScroll as IR } from "./csr/MouseScroll.mjs";
import { MouseSimple as XR } from "./csr/MouseSimple.mjs";
import { MusicNote as JR } from "./csr/MusicNote.mjs";
import { MusicNoteSimple as ZR } from "./csr/MusicNoteSimple.mjs";
import { MusicNotes as YR } from "./csr/MusicNotes.mjs";
import { MusicNotesMinus as $R } from "./csr/MusicNotesMinus.mjs";
import { MusicNotesPlus as oU } from "./csr/MusicNotesPlus.mjs";
import { MusicNotesSimple as tU } from "./csr/MusicNotesSimple.mjs";
import { NavigationArrow as mU } from "./csr/NavigationArrow.mjs";
import { Needle as xU } from "./csr/Needle.mjs";
import { Network as aU } from "./csr/Network.mjs";
import { NetworkSlash as nU } from "./csr/NetworkSlash.mjs";
import { NetworkX as SU } from "./csr/NetworkX.mjs";
import { Newspaper as uU } from "./csr/Newspaper.mjs";
import { NewspaperClipping as CU } from "./csr/NewspaperClipping.mjs";
import { NotEquals as dU } from "./csr/NotEquals.mjs";
import { NotMemberOf as LU } from "./csr/NotMemberOf.mjs";
import { NotSubsetOf as AU } from "./csr/NotSubsetOf.mjs";
import { NotSupersetOf as kU } from "./csr/NotSupersetOf.mjs";
import { Notches as BU } from "./csr/Notches.mjs";
import { NoteBlank as DU } from "./csr/NoteBlank.mjs";
import { Note as HU } from "./csr/Note.mjs";
import { NotePencil as RU } from "./csr/NotePencil.mjs";
import { Notebook as NU } from "./csr/Notebook.mjs";
import { Notepad as GU } from "./csr/Notepad.mjs";
import { Notification as EU } from "./csr/Notification.mjs";
import { NotionLogo as qU } from "./csr/NotionLogo.mjs";
import { NuclearPlant as IU } from "./csr/NuclearPlant.mjs";
import { NumberCircleEight as XU } from "./csr/NumberCircleEight.mjs";
import { NumberCircleFive as JU } from "./csr/NumberCircleFive.mjs";
import { NumberCircleFour as ZU } from "./csr/NumberCircleFour.mjs";
import { NumberCircleNine as YU } from "./csr/NumberCircleNine.mjs";
import { NumberCircleOne as $U } from "./csr/NumberCircleOne.mjs";
import { NumberCircleSeven as oN } from "./csr/NumberCircleSeven.mjs";
import { NumberCircleSix as tN } from "./csr/NumberCircleSix.mjs";
import { NumberCircleThree as mN } from "./csr/NumberCircleThree.mjs";
import { NumberCircleTwo as xN } from "./csr/NumberCircleTwo.mjs";
import { NumberCircleZero as aN } from "./csr/NumberCircleZero.mjs";
import { NumberEight as nN } from "./csr/NumberEight.mjs";
import { NumberFive as SN } from "./csr/NumberFive.mjs";
import { NumberFour as uN } from "./csr/NumberFour.mjs";
import { NumberNine as CN } from "./csr/NumberNine.mjs";
import { NumberOne as dN } from "./csr/NumberOne.mjs";
import { NumberSeven as LN } from "./csr/NumberSeven.mjs";
import { NumberSix as AN } from "./csr/NumberSix.mjs";
import { NumberSquareEight as kN } from "./csr/NumberSquareEight.mjs";
import { NumberSquareFive as BN } from "./csr/NumberSquareFive.mjs";
import { NumberSquareFour as DN } from "./csr/NumberSquareFour.mjs";
import { NumberSquareNine as HN } from "./csr/NumberSquareNine.mjs";
import { NumberSquareOne as RN } from "./csr/NumberSquareOne.mjs";
import { NumberSquareSeven as NN } from "./csr/NumberSquareSeven.mjs";
import { NumberSquareSix as GN } from "./csr/NumberSquareSix.mjs";
import { NumberSquareThree as EN } from "./csr/NumberSquareThree.mjs";
import { NumberSquareTwo as qN } from "./csr/NumberSquareTwo.mjs";
import { NumberSquareZero as IN } from "./csr/NumberSquareZero.mjs";
import { NumberThree as XN } from "./csr/NumberThree.mjs";
import { NumberTwo as JN } from "./csr/NumberTwo.mjs";
import { NumberZero as ZN } from "./csr/NumberZero.mjs";
import { Numpad as YN } from "./csr/Numpad.mjs";
import { Nut as $N } from "./csr/Nut.mjs";
import { NyTimesLogo as ov } from "./csr/NyTimesLogo.mjs";
import { Octagon as tv } from "./csr/Octagon.mjs";
import { OfficeChair as mv } from "./csr/OfficeChair.mjs";
import { Onigiri as xv } from "./csr/Onigiri.mjs";
import { OpenAiLogo as av } from "./csr/OpenAiLogo.mjs";
import { Option as nv } from "./csr/Option.mjs";
import { Orange as Sv } from "./csr/Orange.mjs";
import { OrangeSlice as uv } from "./csr/OrangeSlice.mjs";
import { Oven as Cv } from "./csr/Oven.mjs";
import { Package as dv } from "./csr/Package.mjs";
import { PaintBrush as Lv } from "./csr/PaintBrush.mjs";
import { PaintBrushBroad as Av } from "./csr/PaintBrushBroad.mjs";
import { PaintBrushHousehold as kv } from "./csr/PaintBrushHousehold.mjs";
import { PaintBucket as Bv } from "./csr/PaintBucket.mjs";
import { PaintRoller as Dv } from "./csr/PaintRoller.mjs";
import { Palette as Hv } from "./csr/Palette.mjs";
import { Panorama as Rv } from "./csr/Panorama.mjs";
import { Pants as Nv } from "./csr/Pants.mjs";
import { PaperPlane as Gv } from "./csr/PaperPlane.mjs";
import { PaperPlaneRight as Ev } from "./csr/PaperPlaneRight.mjs";
import { PaperPlaneTilt as qv } from "./csr/PaperPlaneTilt.mjs";
import { Paperclip as Iv } from "./csr/Paperclip.mjs";
import { PaperclipHorizontal as Xv } from "./csr/PaperclipHorizontal.mjs";
import { Parachute as Jv } from "./csr/Parachute.mjs";
import { Paragraph as Zv } from "./csr/Paragraph.mjs";
import { Parallelogram as Yv } from "./csr/Parallelogram.mjs";
import { Park as $v } from "./csr/Park.mjs";
import { Password as oG } from "./csr/Password.mjs";
import { Path as tG } from "./csr/Path.mjs";
import { PatreonLogo as mG } from "./csr/PatreonLogo.mjs";
import { Pause as xG } from "./csr/Pause.mjs";
import { PauseCircle as aG } from "./csr/PauseCircle.mjs";
import { PawPrint as nG } from "./csr/PawPrint.mjs";
import { PaypalLogo as SG } from "./csr/PaypalLogo.mjs";
import { Peace as uG } from "./csr/Peace.mjs";
import { Pen as CG } from "./csr/Pen.mjs";
import { PenNib as dG } from "./csr/PenNib.mjs";
import { PenNibStraight as LG } from "./csr/PenNibStraight.mjs";
import { Pencil as AG } from "./csr/Pencil.mjs";
import { PencilCircle as kG } from "./csr/PencilCircle.mjs";
import { PencilLine as BG } from "./csr/PencilLine.mjs";
import { PencilRuler as DG } from "./csr/PencilRuler.mjs";
import { PencilSimple as HG } from "./csr/PencilSimple.mjs";
import { PencilSimpleLine as RG } from "./csr/PencilSimpleLine.mjs";
import { PencilSimpleSlash as NG } from "./csr/PencilSimpleSlash.mjs";
import { PencilSlash as GG } from "./csr/PencilSlash.mjs";
import { Pentagon as EG } from "./csr/Pentagon.mjs";
import { Pentagram as qG } from "./csr/Pentagram.mjs";
import { Pepper as IG } from "./csr/Pepper.mjs";
import { Percent as XG } from "./csr/Percent.mjs";
import { PersonArmsSpread as JG } from "./csr/PersonArmsSpread.mjs";
import { Person as ZG } from "./csr/Person.mjs";
import { PersonSimpleBike as YG } from "./csr/PersonSimpleBike.mjs";
import { PersonSimple as $G } from "./csr/PersonSimple.mjs";
import { PersonSimpleCircle as oO } from "./csr/PersonSimpleCircle.mjs";
import { PersonSimpleHike as tO } from "./csr/PersonSimpleHike.mjs";
import { PersonSimpleRun as mO } from "./csr/PersonSimpleRun.mjs";
import { PersonSimpleSki as xO } from "./csr/PersonSimpleSki.mjs";
import { PersonSimpleSnowboard as aO } from "./csr/PersonSimpleSnowboard.mjs";
import { PersonSimpleSwim as nO } from "./csr/PersonSimpleSwim.mjs";
import { PersonSimpleTaiChi as SO } from "./csr/PersonSimpleTaiChi.mjs";
import { PersonSimpleThrow as uO } from "./csr/PersonSimpleThrow.mjs";
import { PersonSimpleWalk as CO } from "./csr/PersonSimpleWalk.mjs";
import { Perspective as dO } from "./csr/Perspective.mjs";
import { Phone as LO } from "./csr/Phone.mjs";
import { PhoneCall as AO } from "./csr/PhoneCall.mjs";
import { PhoneDisconnect as kO } from "./csr/PhoneDisconnect.mjs";
import { PhoneIncoming as BO } from "./csr/PhoneIncoming.mjs";
import { PhoneList as DO } from "./csr/PhoneList.mjs";
import { PhoneOutgoing as HO } from "./csr/PhoneOutgoing.mjs";
import { PhonePause as RO } from "./csr/PhonePause.mjs";
import { PhonePlus as NO } from "./csr/PhonePlus.mjs";
import { PhoneSlash as GO } from "./csr/PhoneSlash.mjs";
import { PhoneTransfer as EO } from "./csr/PhoneTransfer.mjs";
import { PhoneX as qO } from "./csr/PhoneX.mjs";
import { PhosphorLogo as IO } from "./csr/PhosphorLogo.mjs";
import { Pi as XO } from "./csr/Pi.mjs";
import { PianoKeys as JO } from "./csr/PianoKeys.mjs";
import { PicnicTable as ZO } from "./csr/PicnicTable.mjs";
import { PictureInPicture as YO } from "./csr/PictureInPicture.mjs";
import { PiggyBank as $O } from "./csr/PiggyBank.mjs";
import { Pill as oE } from "./csr/Pill.mjs";
import { PingPong as tE } from "./csr/PingPong.mjs";
import { PintGlass as mE } from "./csr/PintGlass.mjs";
import { PinterestLogo as xE } from "./csr/PinterestLogo.mjs";
import { Pinwheel as aE } from "./csr/Pinwheel.mjs";
import { Pipe as nE } from "./csr/Pipe.mjs";
import { PipeWrench as SE } from "./csr/PipeWrench.mjs";
import { PixLogo as uE } from "./csr/PixLogo.mjs";
import { Pizza as CE } from "./csr/Pizza.mjs";
import { Placeholder as dE } from "./csr/Placeholder.mjs";
import { Planet as LE } from "./csr/Planet.mjs";
import { Plant as AE } from "./csr/Plant.mjs";
import { Play as kE } from "./csr/Play.mjs";
import { PlayCircle as BE } from "./csr/PlayCircle.mjs";
import { PlayPause as DE } from "./csr/PlayPause.mjs";
import { Playlist as HE } from "./csr/Playlist.mjs";
import { Plug as RE } from "./csr/Plug.mjs";
import { PlugCharging as NE } from "./csr/PlugCharging.mjs";
import { Plugs as GE } from "./csr/Plugs.mjs";
import { PlugsConnected as EE } from "./csr/PlugsConnected.mjs";
import { Plus as qE } from "./csr/Plus.mjs";
import { PlusCircle as IE } from "./csr/PlusCircle.mjs";
import { PlusMinus as XE } from "./csr/PlusMinus.mjs";
import { PlusSquare as JE } from "./csr/PlusSquare.mjs";
import { PokerChip as ZE } from "./csr/PokerChip.mjs";
import { PoliceCar as YE } from "./csr/PoliceCar.mjs";
import { Polygon as $E } from "./csr/Polygon.mjs";
import { Popcorn as oW } from "./csr/Popcorn.mjs";
import { Popsicle as tW } from "./csr/Popsicle.mjs";
import { PottedPlant as mW } from "./csr/PottedPlant.mjs";
import { Power as xW } from "./csr/Power.mjs";
import { Prescription as aW } from "./csr/Prescription.mjs";
import { Presentation as nW } from "./csr/Presentation.mjs";
import { PresentationChart as SW } from "./csr/PresentationChart.mjs";
import { Printer as uW } from "./csr/Printer.mjs";
import { Prohibit as CW } from "./csr/Prohibit.mjs";
import { ProhibitInset as dW } from "./csr/ProhibitInset.mjs";
import { ProjectorScreen as LW } from "./csr/ProjectorScreen.mjs";
import { ProjectorScreenChart as AW } from "./csr/ProjectorScreenChart.mjs";
import { Pulse as kW, Pulse as FW } from "./csr/Pulse.mjs";
import { PushPin as bW } from "./csr/PushPin.mjs";
import { PushPinSimple as yW } from "./csr/PushPinSimple.mjs";
import { PushPinSimpleSlash as MW } from "./csr/PushPinSimpleSlash.mjs";
import { PushPinSlash as UW } from "./csr/PushPinSlash.mjs";
import { PuzzlePiece as vW } from "./csr/PuzzlePiece.mjs";
import { QrCode as OW } from "./csr/QrCode.mjs";
import { Question as WW } from "./csr/Question.mjs";
import { QuestionMark as VW } from "./csr/QuestionMark.mjs";
import { Queue as zW } from "./csr/Queue.mjs";
import { Quotes as KW } from "./csr/Quotes.mjs";
import { Rabbit as QW } from "./csr/Rabbit.mjs";
import { Racquet as jW } from "./csr/Racquet.mjs";
import { Radical as _W } from "./csr/Radical.mjs";
import { Radio as rq } from "./csr/Radio.mjs";
import { RadioButton as eq } from "./csr/RadioButton.mjs";
import { Radioactive as pq } from "./csr/Radioactive.mjs";
import { Rainbow as fq } from "./csr/Rainbow.mjs";
import { RainbowCloud as iq } from "./csr/RainbowCloud.mjs";
import { Ranking as lq } from "./csr/Ranking.mjs";
import { ReadCvLogo as sq } from "./csr/ReadCvLogo.mjs";
import { Receipt as cq } from "./csr/Receipt.mjs";
import { ReceiptX as gq } from "./csr/ReceiptX.mjs";
import { Record as hq } from "./csr/Record.mjs";
import { Rectangle as wq } from "./csr/Rectangle.mjs";
import { RectangleDashed as Pq } from "./csr/RectangleDashed.mjs";
import { Recycle as Tq } from "./csr/Recycle.mjs";
import { RedditLogo as Fq } from "./csr/RedditLogo.mjs";
import { Repeat as bq } from "./csr/Repeat.mjs";
import { RepeatOnce as yq } from "./csr/RepeatOnce.mjs";
import { ReplitLogo as Mq } from "./csr/ReplitLogo.mjs";
import { Resize as Uq } from "./csr/Resize.mjs";
import { Rewind as vq } from "./csr/Rewind.mjs";
import { RewindCircle as Oq } from "./csr/RewindCircle.mjs";
import { RoadHorizon as Wq } from "./csr/RoadHorizon.mjs";
import { Robot as Vq } from "./csr/Robot.mjs";
import { Rocket as zq } from "./csr/Rocket.mjs";
import { RocketLaunch as Kq } from "./csr/RocketLaunch.mjs";
import { Rows as Qq } from "./csr/Rows.mjs";
import { RowsPlusBottom as jq } from "./csr/RowsPlusBottom.mjs";
import { RowsPlusTop as _q } from "./csr/RowsPlusTop.mjs";
import { Rss as rV } from "./csr/Rss.mjs";
import { RssSimple as eV } from "./csr/RssSimple.mjs";
import { Rug as pV } from "./csr/Rug.mjs";
import { Ruler as fV } from "./csr/Ruler.mjs";
import { Sailboat as iV } from "./csr/Sailboat.mjs";
import { Scales as lV } from "./csr/Scales.mjs";
import { Scan as sV } from "./csr/Scan.mjs";
import { ScanSmiley as cV } from "./csr/ScanSmiley.mjs";
import { Scissors as gV } from "./csr/Scissors.mjs";
import { Scooter as hV } from "./csr/Scooter.mjs";
import { Screencast as wV } from "./csr/Screencast.mjs";
import { Screwdriver as PV } from "./csr/Screwdriver.mjs";
import { Scribble as TV } from "./csr/Scribble.mjs";
import { ScribbleLoop as FV } from "./csr/ScribbleLoop.mjs";
import { Scroll as bV } from "./csr/Scroll.mjs";
import { Seal as yV, Seal as HV } from "./csr/Seal.mjs";
import { SealCheck as RV, SealCheck as UV } from "./csr/SealCheck.mjs";
import { SealPercent as vV } from "./csr/SealPercent.mjs";
import { SealQuestion as OV, SealQuestion as EV } from "./csr/SealQuestion.mjs";
import { SealWarning as qV, SealWarning as VV } from "./csr/SealWarning.mjs";
import { Seat as zV } from "./csr/Seat.mjs";
import { Seatbelt as KV } from "./csr/Seatbelt.mjs";
import { SecurityCamera as QV } from "./csr/SecurityCamera.mjs";
import { SelectionAll as jV } from "./csr/SelectionAll.mjs";
import { SelectionBackground as _V } from "./csr/SelectionBackground.mjs";
import { Selection as rI } from "./csr/Selection.mjs";
import { SelectionForeground as eI } from "./csr/SelectionForeground.mjs";
import { SelectionInverse as pI } from "./csr/SelectionInverse.mjs";
import { SelectionPlus as fI } from "./csr/SelectionPlus.mjs";
import { SelectionSlash as iI } from "./csr/SelectionSlash.mjs";
import { Shapes as lI } from "./csr/Shapes.mjs";
import { Share as sI } from "./csr/Share.mjs";
import { ShareFat as cI } from "./csr/ShareFat.mjs";
import { ShareNetwork as gI } from "./csr/ShareNetwork.mjs";
import { Shield as hI } from "./csr/Shield.mjs";
import { ShieldCheck as wI } from "./csr/ShieldCheck.mjs";
import { ShieldCheckered as PI } from "./csr/ShieldCheckered.mjs";
import { ShieldChevron as TI } from "./csr/ShieldChevron.mjs";
import { ShieldPlus as FI } from "./csr/ShieldPlus.mjs";
import { ShieldSlash as bI } from "./csr/ShieldSlash.mjs";
import { ShieldStar as yI } from "./csr/ShieldStar.mjs";
import { ShieldWarning as MI } from "./csr/ShieldWarning.mjs";
import { ShippingContainer as UI } from "./csr/ShippingContainer.mjs";
import { ShirtFolded as vI } from "./csr/ShirtFolded.mjs";
import { ShootingStar as OI } from "./csr/ShootingStar.mjs";
import { ShoppingBag as WI } from "./csr/ShoppingBag.mjs";
import { ShoppingBagOpen as VI } from "./csr/ShoppingBagOpen.mjs";
import { ShoppingCart as zI } from "./csr/ShoppingCart.mjs";
import { ShoppingCartSimple as KI } from "./csr/ShoppingCartSimple.mjs";
import { Shovel as QI } from "./csr/Shovel.mjs";
import { Shower as jI } from "./csr/Shower.mjs";
import { Shrimp as _I } from "./csr/Shrimp.mjs";
import { ShuffleAngular as rz } from "./csr/ShuffleAngular.mjs";
import { Shuffle as ez } from "./csr/Shuffle.mjs";
import { ShuffleSimple as pz } from "./csr/ShuffleSimple.mjs";
import { Sidebar as fz } from "./csr/Sidebar.mjs";
import { SidebarSimple as iz } from "./csr/SidebarSimple.mjs";
import { Sigma as lz } from "./csr/Sigma.mjs";
import { SignIn as sz } from "./csr/SignIn.mjs";
import { SignOut as cz } from "./csr/SignOut.mjs";
import { Signature as gz } from "./csr/Signature.mjs";
import { Signpost as hz } from "./csr/Signpost.mjs";
import { SimCard as wz } from "./csr/SimCard.mjs";
import { Siren as Pz } from "./csr/Siren.mjs";
import { SketchLogo as Tz } from "./csr/SketchLogo.mjs";
import { SkipBack as Fz } from "./csr/SkipBack.mjs";
import { SkipBackCircle as bz } from "./csr/SkipBackCircle.mjs";
import { SkipForward as yz } from "./csr/SkipForward.mjs";
import { SkipForwardCircle as Mz } from "./csr/SkipForwardCircle.mjs";
import { Skull as Uz } from "./csr/Skull.mjs";
import { SkypeLogo as vz } from "./csr/SkypeLogo.mjs";
import { SlackLogo as Oz } from "./csr/SlackLogo.mjs";
import { Sliders as Wz } from "./csr/Sliders.mjs";
import { SlidersHorizontal as Vz } from "./csr/SlidersHorizontal.mjs";
import { Slideshow as zz } from "./csr/Slideshow.mjs";
import { SmileyAngry as Kz } from "./csr/SmileyAngry.mjs";
import { SmileyBlank as Qz } from "./csr/SmileyBlank.mjs";
import { Smiley as jz } from "./csr/Smiley.mjs";
import { SmileyMeh as _z } from "./csr/SmileyMeh.mjs";
import { SmileyMelting as rX } from "./csr/SmileyMelting.mjs";
import { SmileyNervous as eX } from "./csr/SmileyNervous.mjs";
import { SmileySad as pX } from "./csr/SmileySad.mjs";
import { SmileySticker as fX } from "./csr/SmileySticker.mjs";
import { SmileyWink as iX } from "./csr/SmileyWink.mjs";
import { SmileyXEyes as lX } from "./csr/SmileyXEyes.mjs";
import { SnapchatLogo as sX } from "./csr/SnapchatLogo.mjs";
import { Sneaker as cX } from "./csr/Sneaker.mjs";
import { SneakerMove as gX } from "./csr/SneakerMove.mjs";
import { Snowflake as hX } from "./csr/Snowflake.mjs";
import { SoccerBall as wX } from "./csr/SoccerBall.mjs";
import { Sock as PX } from "./csr/Sock.mjs";
import { SolarPanel as TX } from "./csr/SolarPanel.mjs";
import { SolarRoof as FX } from "./csr/SolarRoof.mjs";
import { SortAscending as bX } from "./csr/SortAscending.mjs";
import { SortDescending as yX } from "./csr/SortDescending.mjs";
import { SoundcloudLogo as MX } from "./csr/SoundcloudLogo.mjs";
import { Spade as UX } from "./csr/Spade.mjs";
import { Sparkle as vX } from "./csr/Sparkle.mjs";
import { SpeakerHifi as OX } from "./csr/SpeakerHifi.mjs";
import { SpeakerHigh as WX } from "./csr/SpeakerHigh.mjs";
import { SpeakerLow as VX } from "./csr/SpeakerLow.mjs";
import { SpeakerNone as zX } from "./csr/SpeakerNone.mjs";
import { SpeakerSimpleHigh as KX } from "./csr/SpeakerSimpleHigh.mjs";
import { SpeakerSimpleLow as QX } from "./csr/SpeakerSimpleLow.mjs";
import { SpeakerSimpleNone as jX } from "./csr/SpeakerSimpleNone.mjs";
import { SpeakerSimpleSlash as _X } from "./csr/SpeakerSimpleSlash.mjs";
import { SpeakerSimpleX as rK } from "./csr/SpeakerSimpleX.mjs";
import { SpeakerSlash as eK } from "./csr/SpeakerSlash.mjs";
import { SpeakerX as pK } from "./csr/SpeakerX.mjs";
import { Speedometer as fK } from "./csr/Speedometer.mjs";
import { Sphere as iK } from "./csr/Sphere.mjs";
import { SpinnerBall as lK } from "./csr/SpinnerBall.mjs";
import { Spinner as sK } from "./csr/Spinner.mjs";
import { SpinnerGap as cK } from "./csr/SpinnerGap.mjs";
import { Spiral as gK } from "./csr/Spiral.mjs";
import { SplitHorizontal as hK } from "./csr/SplitHorizontal.mjs";
import { SplitVertical as wK } from "./csr/SplitVertical.mjs";
import { SpotifyLogo as PK } from "./csr/SpotifyLogo.mjs";
import { SprayBottle as TK } from "./csr/SprayBottle.mjs";
import { Square as FK } from "./csr/Square.mjs";
import { SquareHalf as bK } from "./csr/SquareHalf.mjs";
import { SquareHalfBottom as yK } from "./csr/SquareHalfBottom.mjs";
import { SquareLogo as MK } from "./csr/SquareLogo.mjs";
import { SquareSplitHorizontal as UK } from "./csr/SquareSplitHorizontal.mjs";
import { SquareSplitVertical as vK } from "./csr/SquareSplitVertical.mjs";
import { SquaresFour as OK } from "./csr/SquaresFour.mjs";
import { Stack as WK } from "./csr/Stack.mjs";
import { StackMinus as VK } from "./csr/StackMinus.mjs";
import { StackOverflowLogo as zK } from "./csr/StackOverflowLogo.mjs";
import { StackPlus as KK } from "./csr/StackPlus.mjs";
import { StackSimple as QK } from "./csr/StackSimple.mjs";
import { Stairs as jK } from "./csr/Stairs.mjs";
import { Stamp as _K } from "./csr/Stamp.mjs";
import { StandardDefinition as rJ } from "./csr/StandardDefinition.mjs";
import { StarAndCrescent as eJ } from "./csr/StarAndCrescent.mjs";
import { Star as pJ } from "./csr/Star.mjs";
import { StarFour as fJ } from "./csr/StarFour.mjs";
import { StarHalf as iJ } from "./csr/StarHalf.mjs";
import { StarOfDavid as lJ } from "./csr/StarOfDavid.mjs";
import { SteamLogo as sJ } from "./csr/SteamLogo.mjs";
import { SteeringWheel as cJ } from "./csr/SteeringWheel.mjs";
import { Steps as gJ } from "./csr/Steps.mjs";
import { Stethoscope as hJ } from "./csr/Stethoscope.mjs";
import { Sticker as wJ } from "./csr/Sticker.mjs";
import { Stool as PJ } from "./csr/Stool.mjs";
import { Stop as TJ } from "./csr/Stop.mjs";
import { StopCircle as FJ } from "./csr/StopCircle.mjs";
import { Storefront as bJ } from "./csr/Storefront.mjs";
import { Strategy as yJ } from "./csr/Strategy.mjs";
import { StripeLogo as MJ } from "./csr/StripeLogo.mjs";
import { Student as UJ } from "./csr/Student.mjs";
import { SubsetOf as vJ } from "./csr/SubsetOf.mjs";
import { SubsetProperOf as OJ } from "./csr/SubsetProperOf.mjs";
import { Subtitles as WJ } from "./csr/Subtitles.mjs";
import { SubtitlesSlash as VJ } from "./csr/SubtitlesSlash.mjs";
import { Subtract as zJ } from "./csr/Subtract.mjs";
import { SubtractSquare as KJ } from "./csr/SubtractSquare.mjs";
import { Subway as QJ } from "./csr/Subway.mjs";
import { Suitcase as jJ } from "./csr/Suitcase.mjs";
import { SuitcaseRolling as _J } from "./csr/SuitcaseRolling.mjs";
import { SuitcaseSimple as rQ } from "./csr/SuitcaseSimple.mjs";
import { Sun as eQ } from "./csr/Sun.mjs";
import { SunDim as pQ } from "./csr/SunDim.mjs";
import { SunHorizon as fQ } from "./csr/SunHorizon.mjs";
import { Sunglasses as iQ } from "./csr/Sunglasses.mjs";
import { SupersetOf as lQ } from "./csr/SupersetOf.mjs";
import { SupersetProperOf as sQ } from "./csr/SupersetProperOf.mjs";
import { Swap as cQ } from "./csr/Swap.mjs";
import { Swatches as gQ } from "./csr/Swatches.mjs";
import { SwimmingPool as hQ } from "./csr/SwimmingPool.mjs";
import { Sword as wQ } from "./csr/Sword.mjs";
import { Synagogue as PQ } from "./csr/Synagogue.mjs";
import { Syringe as TQ } from "./csr/Syringe.mjs";
import { TShirt as FQ } from "./csr/TShirt.mjs";
import { Table as bQ } from "./csr/Table.mjs";
import { Tabs as yQ } from "./csr/Tabs.mjs";
import { Tag as MQ } from "./csr/Tag.mjs";
import { TagChevron as UQ } from "./csr/TagChevron.mjs";
import { TagSimple as vQ } from "./csr/TagSimple.mjs";
import { Target as OQ } from "./csr/Target.mjs";
import { Taxi as WQ } from "./csr/Taxi.mjs";
import { TeaBag as VQ } from "./csr/TeaBag.mjs";
import { TelegramLogo as zQ } from "./csr/TelegramLogo.mjs";
import { Television as KQ } from "./csr/Television.mjs";
import { TelevisionSimple as QQ } from "./csr/TelevisionSimple.mjs";
import { TennisBall as jQ } from "./csr/TennisBall.mjs";
import { Tent as _Q } from "./csr/Tent.mjs";
import { Terminal as rZ } from "./csr/Terminal.mjs";
import { TerminalWindow as eZ } from "./csr/TerminalWindow.mjs";
import { TestTube as pZ } from "./csr/TestTube.mjs";
import { TextAUnderline as fZ } from "./csr/TextAUnderline.mjs";
import { TextAa as iZ } from "./csr/TextAa.mjs";
import { TextAlignCenter as lZ } from "./csr/TextAlignCenter.mjs";
import { TextAlignJustify as sZ } from "./csr/TextAlignJustify.mjs";
import { TextAlignLeft as cZ } from "./csr/TextAlignLeft.mjs";
import { TextAlignRight as gZ } from "./csr/TextAlignRight.mjs";
import { TextB as hZ, TextB as dZ } from "./csr/TextB.mjs";
import { TextColumns as LZ } from "./csr/TextColumns.mjs";
import { TextH as AZ } from "./csr/TextH.mjs";
import { TextHFive as kZ } from "./csr/TextHFive.mjs";
import { TextHFour as BZ } from "./csr/TextHFour.mjs";
import { TextHOne as DZ } from "./csr/TextHOne.mjs";
import { TextHSix as HZ } from "./csr/TextHSix.mjs";
import { TextHThree as RZ } from "./csr/TextHThree.mjs";
import { TextHTwo as NZ } from "./csr/TextHTwo.mjs";
import { TextIndent as GZ } from "./csr/TextIndent.mjs";
import { TextItalic as EZ } from "./csr/TextItalic.mjs";
import { TextOutdent as qZ } from "./csr/TextOutdent.mjs";
import { TextStrikethrough as IZ } from "./csr/TextStrikethrough.mjs";
import { TextSubscript as XZ } from "./csr/TextSubscript.mjs";
import { TextSuperscript as JZ } from "./csr/TextSuperscript.mjs";
import { TextT as ZZ } from "./csr/TextT.mjs";
import { TextTSlash as YZ } from "./csr/TextTSlash.mjs";
import { TextUnderline as $Z } from "./csr/TextUnderline.mjs";
import { Textbox as oj } from "./csr/Textbox.mjs";
import { Thermometer as tj } from "./csr/Thermometer.mjs";
import { ThermometerCold as mj } from "./csr/ThermometerCold.mjs";
import { ThermometerHot as xj } from "./csr/ThermometerHot.mjs";
import { ThermometerSimple as aj } from "./csr/ThermometerSimple.mjs";
import { ThreadsLogo as nj } from "./csr/ThreadsLogo.mjs";
import { ThreeD as Sj } from "./csr/ThreeD.mjs";
import { ThumbsDown as uj } from "./csr/ThumbsDown.mjs";
import { ThumbsUp as Cj } from "./csr/ThumbsUp.mjs";
import { Ticket as dj } from "./csr/Ticket.mjs";
import { TidalLogo as Lj } from "./csr/TidalLogo.mjs";
import { TiktokLogo as Aj } from "./csr/TiktokLogo.mjs";
import { Tilde as kj } from "./csr/Tilde.mjs";
import { Timer as Bj } from "./csr/Timer.mjs";
import { TipJar as Dj } from "./csr/TipJar.mjs";
import { Tipi as Hj } from "./csr/Tipi.mjs";
import { Tire as Rj } from "./csr/Tire.mjs";
import { ToggleLeft as Nj } from "./csr/ToggleLeft.mjs";
import { ToggleRight as Gj } from "./csr/ToggleRight.mjs";
import { Toilet as Ej } from "./csr/Toilet.mjs";
import { ToiletPaper as qj } from "./csr/ToiletPaper.mjs";
import { Toolbox as Ij } from "./csr/Toolbox.mjs";
import { Tooth as Xj } from "./csr/Tooth.mjs";
import { Tornado as Jj } from "./csr/Tornado.mjs";
import { Tote as Zj } from "./csr/Tote.mjs";
import { ToteSimple as Yj } from "./csr/ToteSimple.mjs";
import { Towel as $j } from "./csr/Towel.mjs";
import { Tractor as oY } from "./csr/Tractor.mjs";
import { Trademark as tY } from "./csr/Trademark.mjs";
import { TrademarkRegistered as mY } from "./csr/TrademarkRegistered.mjs";
import { TrafficCone as xY } from "./csr/TrafficCone.mjs";
import { TrafficSign as aY } from "./csr/TrafficSign.mjs";
import { TrafficSignal as nY } from "./csr/TrafficSignal.mjs";
import { Train as SY } from "./csr/Train.mjs";
import { TrainRegional as uY } from "./csr/TrainRegional.mjs";
import { TrainSimple as CY } from "./csr/TrainSimple.mjs";
import { Tram as dY } from "./csr/Tram.mjs";
import { Translate as LY } from "./csr/Translate.mjs";
import { Trash as AY } from "./csr/Trash.mjs";
import { TrashSimple as kY } from "./csr/TrashSimple.mjs";
import { TrayArrowDown as BY, TrayArrowDown as bY } from "./csr/TrayArrowDown.mjs";
import { TrayArrowUp as yY } from "./csr/TrayArrowUp.mjs";
import { Tray as MY } from "./csr/Tray.mjs";
import { TreasureChest as UY } from "./csr/TreasureChest.mjs";
import { Tree as vY } from "./csr/Tree.mjs";
import { TreeEvergreen as OY } from "./csr/TreeEvergreen.mjs";
import { TreePalm as WY } from "./csr/TreePalm.mjs";
import { TreeStructure as VY } from "./csr/TreeStructure.mjs";
import { TreeView as zY } from "./csr/TreeView.mjs";
import { TrendDown as KY } from "./csr/TrendDown.mjs";
import { TrendUp as QY } from "./csr/TrendUp.mjs";
import { Triangle as jY } from "./csr/Triangle.mjs";
import { TriangleDashed as _Y } from "./csr/TriangleDashed.mjs";
import { Trolley as r0 } from "./csr/Trolley.mjs";
import { TrolleySuitcase as e0 } from "./csr/TrolleySuitcase.mjs";
import { Trophy as p0 } from "./csr/Trophy.mjs";
import { Truck as f0 } from "./csr/Truck.mjs";
import { TruckTrailer as i0 } from "./csr/TruckTrailer.mjs";
import { TumblrLogo as l0 } from "./csr/TumblrLogo.mjs";
import { TwitchLogo as s0 } from "./csr/TwitchLogo.mjs";
import { TwitterLogo as c0 } from "./csr/TwitterLogo.mjs";
import { Umbrella as g0 } from "./csr/Umbrella.mjs";
import { UmbrellaSimple as h0 } from "./csr/UmbrellaSimple.mjs";
import { Union as w0 } from "./csr/Union.mjs";
import { Unite as P0 } from "./csr/Unite.mjs";
import { UniteSquare as T0 } from "./csr/UniteSquare.mjs";
import { Upload as F0 } from "./csr/Upload.mjs";
import { UploadSimple as b0 } from "./csr/UploadSimple.mjs";
import { Usb as y0 } from "./csr/Usb.mjs";
import { User as M0 } from "./csr/User.mjs";
import { UserCheck as U0 } from "./csr/UserCheck.mjs";
import { UserCircle as v0 } from "./csr/UserCircle.mjs";
import { UserCircleCheck as O0 } from "./csr/UserCircleCheck.mjs";
import { UserCircleDashed as W0 } from "./csr/UserCircleDashed.mjs";
import { UserCircleGear as V0 } from "./csr/UserCircleGear.mjs";
import { UserCircleMinus as z0 } from "./csr/UserCircleMinus.mjs";
import { UserCirclePlus as K0 } from "./csr/UserCirclePlus.mjs";
import { UserFocus as Q0 } from "./csr/UserFocus.mjs";
import { UserGear as j0 } from "./csr/UserGear.mjs";
import { UserList as _0 } from "./csr/UserList.mjs";
import { UserMinus as r1 } from "./csr/UserMinus.mjs";
import { UserPlus as e1 } from "./csr/UserPlus.mjs";
import { UserRectangle as p1 } from "./csr/UserRectangle.mjs";
import { UserSound as f1 } from "./csr/UserSound.mjs";
import { UserSquare as i1 } from "./csr/UserSquare.mjs";
import { UserSwitch as l1 } from "./csr/UserSwitch.mjs";
import { Users as s1 } from "./csr/Users.mjs";
import { UsersFour as c1 } from "./csr/UsersFour.mjs";
import { UsersThree as g1 } from "./csr/UsersThree.mjs";
import { Van as h1 } from "./csr/Van.mjs";
import { Vault as w1 } from "./csr/Vault.mjs";
import { VectorThree as P1 } from "./csr/VectorThree.mjs";
import { VectorTwo as T1 } from "./csr/VectorTwo.mjs";
import { Vibrate as F1 } from "./csr/Vibrate.mjs";
import { Video as b1 } from "./csr/Video.mjs";
import { VideoCamera as y1 } from "./csr/VideoCamera.mjs";
import { VideoCameraSlash as M1 } from "./csr/VideoCameraSlash.mjs";
import { VideoConference as U1 } from "./csr/VideoConference.mjs";
import { Vignette as v1 } from "./csr/Vignette.mjs";
import { VinylRecord as O1 } from "./csr/VinylRecord.mjs";
import { VirtualReality as W1 } from "./csr/VirtualReality.mjs";
import { Virus as V1 } from "./csr/Virus.mjs";
import { Visor as z1 } from "./csr/Visor.mjs";
import { Voicemail as K1 } from "./csr/Voicemail.mjs";
import { Volleyball as Q1 } from "./csr/Volleyball.mjs";
import { Wall as j1 } from "./csr/Wall.mjs";
import { Wallet as _1 } from "./csr/Wallet.mjs";
import { Warehouse as r2 } from "./csr/Warehouse.mjs";
import { Warning as e2 } from "./csr/Warning.mjs";
import { WarningCircle as p2 } from "./csr/WarningCircle.mjs";
import { WarningDiamond as f2 } from "./csr/WarningDiamond.mjs";
import { WarningOctagon as i2 } from "./csr/WarningOctagon.mjs";
import { WashingMachine as l2 } from "./csr/WashingMachine.mjs";
import { Watch as s2 } from "./csr/Watch.mjs";
import { WaveSawtooth as c2 } from "./csr/WaveSawtooth.mjs";
import { WaveSine as g2 } from "./csr/WaveSine.mjs";
import { WaveSquare as h2 } from "./csr/WaveSquare.mjs";
import { WaveTriangle as w2 } from "./csr/WaveTriangle.mjs";
import { Waveform as P2 } from "./csr/Waveform.mjs";
import { WaveformSlash as T2 } from "./csr/WaveformSlash.mjs";
import { Waves as F2 } from "./csr/Waves.mjs";
import { Webcam as b2 } from "./csr/Webcam.mjs";
import { WebcamSlash as y2 } from "./csr/WebcamSlash.mjs";
import { WebhooksLogo as M2 } from "./csr/WebhooksLogo.mjs";
import { WechatLogo as U2 } from "./csr/WechatLogo.mjs";
import { WhatsappLogo as v2 } from "./csr/WhatsappLogo.mjs";
import { Wheelchair as O2 } from "./csr/Wheelchair.mjs";
import { WheelchairMotion as W2 } from "./csr/WheelchairMotion.mjs";
import { WifiHigh as V2 } from "./csr/WifiHigh.mjs";
import { WifiLow as z2 } from "./csr/WifiLow.mjs";
import { WifiMedium as K2 } from "./csr/WifiMedium.mjs";
import { WifiNone as Q2 } from "./csr/WifiNone.mjs";
import { WifiSlash as j2 } from "./csr/WifiSlash.mjs";
import { WifiX as _2 } from "./csr/WifiX.mjs";
import { Wind as r3 } from "./csr/Wind.mjs";
import { Windmill as e3 } from "./csr/Windmill.mjs";
import { WindowsLogo as p3 } from "./csr/WindowsLogo.mjs";
import { Wine as f3 } from "./csr/Wine.mjs";
import { Wrench as i3 } from "./csr/Wrench.mjs";
import { X as l3 } from "./csr/X.mjs";
import { XCircle as s3 } from "./csr/XCircle.mjs";
import { XLogo as c3 } from "./csr/XLogo.mjs";
import { XSquare as g3 } from "./csr/XSquare.mjs";
import { Yarn as h3 } from "./csr/Yarn.mjs";
import { YinYang as w3 } from "./csr/YinYang.mjs";
import { YoutubeLogo as P3 } from "./csr/YoutubeLogo.mjs";
import { IconContext as T3 } from "./lib/context.mjs";
import { default as F3 } from "./lib/IconBase.mjs";
export {
  t as Acorn,
  kW as Activity,
  m as AddressBook,
  x as AddressBookTabs,
  a as AirTrafficControl,
  n as Airplane,
  S as AirplaneInFlight,
  u as AirplaneLanding,
  C as AirplaneTakeoff,
  d as AirplaneTaxiing,
  L as AirplaneTilt,
  A as Airplay,
  k as Alarm,
  B as Alien,
  D as AlignBottom,
  H as AlignBottomSimple,
  R as AlignCenterHorizontal,
  N as AlignCenterHorizontalSimple,
  G as AlignCenterVertical,
  E as AlignCenterVerticalSimple,
  q as AlignLeft,
  I as AlignLeftSimple,
  X as AlignRight,
  J as AlignRightSimple,
  Z as AlignTop,
  Y as AlignTopSimple,
  $ as AmazonLogo,
  or as Ambulance,
  tr as Anchor,
  mr as AnchorSimple,
  xr as AndroidLogo,
  ar as Angle,
  nr as AngularLogo,
  Sr as Aperture,
  ur as AppStoreLogo,
  Cr as AppWindow,
  dr as AppleLogo,
  Lr as ApplePodcastsLogo,
  Ar as ApproximateEquals,
  kr as Archive,
  Fx as ArchiveBox,
  BY as ArchiveTray,
  Br as Armchair,
  Dr as ArrowArcLeft,
  Hr as ArrowArcRight,
  Rr as ArrowBendDoubleUpLeft,
  Nr as ArrowBendDoubleUpRight,
  Gr as ArrowBendDownLeft,
  Er as ArrowBendDownRight,
  qr as ArrowBendLeftDown,
  Ir as ArrowBendLeftUp,
  Xr as ArrowBendRightDown,
  Jr as ArrowBendRightUp,
  Zr as ArrowBendUpLeft,
  Yr as ArrowBendUpRight,
  $r as ArrowCircleDown,
  oo as ArrowCircleDownLeft,
  to as ArrowCircleDownRight,
  mo as ArrowCircleLeft,
  xo as ArrowCircleRight,
  ao as ArrowCircleUp,
  no as ArrowCircleUpLeft,
  So as ArrowCircleUpRight,
  uo as ArrowClockwise,
  Co as ArrowCounterClockwise,
  wo as ArrowDown,
  Po as ArrowDownLeft,
  To as ArrowDownRight,
  Fo as ArrowElbowDownLeft,
  bo as ArrowElbowDownRight,
  yo as ArrowElbowLeft,
  Mo as ArrowElbowLeftDown,
  Uo as ArrowElbowLeftUp,
  vo as ArrowElbowRight,
  Oo as ArrowElbowRightDown,
  Wo as ArrowElbowRightUp,
  Vo as ArrowElbowUpLeft,
  zo as ArrowElbowUpRight,
  Ko as ArrowFatDown,
  Qo as ArrowFatLeft,
  jo as ArrowFatLineDown,
  _o as ArrowFatLineLeft,
  re as ArrowFatLineRight,
  ee as ArrowFatLineUp,
  pe as ArrowFatLinesDown,
  fe as ArrowFatLinesLeft,
  ie as ArrowFatLinesRight,
  le as ArrowFatLinesUp,
  se as ArrowFatRight,
  ce as ArrowFatUp,
  ge as ArrowLeft,
  he as ArrowLineDown,
  we as ArrowLineDownLeft,
  Pe as ArrowLineDownRight,
  Te as ArrowLineLeft,
  Fe as ArrowLineRight,
  be as ArrowLineUp,
  ye as ArrowLineUpLeft,
  Me as ArrowLineUpRight,
  Ue as ArrowRight,
  ve as ArrowSquareDown,
  Oe as ArrowSquareDownLeft,
  We as ArrowSquareDownRight,
  Ve as ArrowSquareIn,
  ze as ArrowSquareLeft,
  Ke as ArrowSquareOut,
  Qe as ArrowSquareRight,
  je as ArrowSquareUp,
  _e as ArrowSquareUpLeft,
  rt as ArrowSquareUpRight,
  et as ArrowUDownLeft,
  pt as ArrowUDownRight,
  ft as ArrowULeftDown,
  it as ArrowULeftUp,
  lt as ArrowURightDown,
  st as ArrowURightUp,
  ct as ArrowUUpLeft,
  gt as ArrowUUpRight,
  ht as ArrowUp,
  wt as ArrowUpLeft,
  Pt as ArrowUpRight,
  Tt as ArrowsClockwise,
  Ft as ArrowsCounterClockwise,
  bt as ArrowsDownUp,
  yt as ArrowsHorizontal,
  Mt as ArrowsIn,
  Ut as ArrowsInCardinal,
  vt as ArrowsInLineHorizontal,
  Ot as ArrowsInLineVertical,
  Wt as ArrowsInSimple,
  Vt as ArrowsLeftRight,
  zt as ArrowsMerge,
  Kt as ArrowsOut,
  Qt as ArrowsOutCardinal,
  jt as ArrowsOutLineHorizontal,
  _t as ArrowsOutLineVertical,
  rp as ArrowsOutSimple,
  ep as ArrowsSplit,
  pp as ArrowsVertical,
  fp as Article,
  ip as ArticleMedium,
  lp as ArticleNyTimes,
  sp as Asclepius,
  up as Asterisk,
  Cp as AsteriskSimple,
  dp as At,
  Lp as Atom,
  Ap as Avocado,
  kp as Axe,
  Bp as Baby,
  Dp as BabyCarriage,
  Hp as Backpack,
  Rp as Backspace,
  Np as Bag,
  Gp as BagSimple,
  Ep as Balloon,
  qp as Bandaids,
  Ip as Bank,
  Xp as Barbell,
  Jp as Barcode,
  Zp as Barn,
  Yp as Barricade,
  $p as Baseball,
  om as BaseballCap,
  tm as BaseballHelmet,
  mm as Basket,
  xm as Basketball,
  am as Bathtub,
  nm as BatteryCharging,
  Sm as BatteryChargingVertical,
  um as BatteryEmpty,
  Cm as BatteryFull,
  dm as BatteryHigh,
  Lm as BatteryLow,
  Am as BatteryMedium,
  km as BatteryPlus,
  Bm as BatteryPlusVertical,
  Dm as BatteryVerticalEmpty,
  Hm as BatteryVerticalFull,
  Rm as BatteryVerticalHigh,
  Nm as BatteryVerticalLow,
  Gm as BatteryVerticalMedium,
  Em as BatteryWarning,
  qm as BatteryWarningVertical,
  Im as BeachBall,
  Xm as Beanie,
  Jm as Bed,
  Zm as BeerBottle,
  Ym as BeerStein,
  $m as BehanceLogo,
  of as Bell,
  tf as BellRinging,
  mf as BellSimple,
  xf as BellSimpleRinging,
  lf as BellSimpleSlash,
  sf as BellSimpleZ,
  cf as BellSlash,
  gf as BellZ,
  hf as Belt,
  wf as BezierCurve,
  Pf as Bicycle,
  Tf as Binary,
  Ff as Binoculars,
  bf as Biohazard,
  yf as Bird,
  Mf as Blueprint,
  Uf as Bluetooth,
  vf as BluetoothConnected,
  Of as BluetoothSlash,
  Wf as BluetoothX,
  Vf as Boat,
  zf as Bomb,
  Kf as Bone,
  Qf as Book,
  jf as BookBookmark,
  _f as BookOpen,
  rx as BookOpenText,
  ex as BookOpenUser,
  px as Bookmark,
  fx as BookmarkSimple,
  ix as Bookmarks,
  lx as BookmarksSimple,
  sx as Books,
  cx as Boot,
  gx as Boules,
  hx as BoundingBox,
  wx as BowlFood,
  Px as BowlSteam,
  Tx as BowlingBall,
  Bx as BoxArrowDown,
  Dx as BoxArrowUp,
  Hx as BoxingGlove,
  Rx as BracketsAngle,
  Nx as BracketsCurly,
  Gx as BracketsRound,
  Ex as BracketsSquare,
  qx as Brain,
  Ix as Brandy,
  Xx as Bread,
  Jx as Bridge,
  Zx as Briefcase,
  Yx as BriefcaseMetal,
  $x as Broadcast,
  oi as Broom,
  ti as Browser,
  mi as Browsers,
  ai as Bug,
  xi as BugBeetle,
  ni as BugDroid,
  ui as Building,
  Si as BuildingApartment,
  Ci as BuildingOffice,
  di as Buildings,
  Li as Bulldozer,
  Ai as Bus,
  ki as Butterfly,
  Bi as CableCar,
  Di as Cactus,
  Sp as Caduceus,
  Hi as Cake,
  Ri as Calculator,
  Gi as Calendar,
  Ni as CalendarBlank,
  Ei as CalendarCheck,
  qi as CalendarDot,
  Ii as CalendarDots,
  Xi as CalendarHeart,
  Ji as CalendarMinus,
  Zi as CalendarPlus,
  Yi as CalendarSlash,
  $i as CalendarStar,
  oa as CalendarX,
  ta as CallBell,
  ma as Camera,
  xa as CameraPlus,
  aa as CameraRotate,
  na as CameraSlash,
  Sa as Campfire,
  Ca as Car,
  ua as CarBattery,
  da as CarProfile,
  La as CarSimple,
  Aa as Cardholder,
  ka as Cards,
  Ba as CardsThree,
  Da as CaretCircleDoubleDown,
  Ha as CaretCircleDoubleLeft,
  Ra as CaretCircleDoubleRight,
  Na as CaretCircleDoubleUp,
  Ga as CaretCircleDown,
  Ea as CaretCircleLeft,
  qa as CaretCircleRight,
  Ia as CaretCircleUp,
  Xa as CaretCircleUpDown,
  Ja as CaretDoubleDown,
  Za as CaretDoubleLeft,
  Ya as CaretDoubleRight,
  $a as CaretDoubleUp,
  ol as CaretDown,
  tl as CaretLeft,
  ml as CaretLineDown,
  xl as CaretLineLeft,
  al as CaretLineRight,
  nl as CaretLineUp,
  Sl as CaretRight,
  ul as CaretUp,
  Cl as CaretUpDown,
  dl as Carrot,
  Ll as CashRegister,
  Al as CassetteTape,
  kl as CastleTurret,
  Bl as Cat,
  Dl as CellSignalFull,
  Hl as CellSignalHigh,
  Rl as CellSignalLow,
  Nl as CellSignalMedium,
  Gl as CellSignalNone,
  El as CellSignalSlash,
  ql as CellSignalX,
  Il as CellTower,
  Xl as Certificate,
  Jl as Chair,
  Zl as Chalkboard,
  Yl as ChalkboardSimple,
  $l as ChalkboardTeacher,
  on as Champagne,
  tn as ChargingStation,
  mn as ChartBar,
  xn as ChartBarHorizontal,
  ln as ChartDonut,
  sn as ChartLine,
  cn as ChartLineDown,
  gn as ChartLineUp,
  hn as ChartPie,
  wn as ChartPieSlice,
  Pn as ChartPolar,
  Tn as ChartScatter,
  Fn as Chat,
  bn as ChatCentered,
  yn as ChatCenteredDots,
  Mn as ChatCenteredSlash,
  Un as ChatCenteredText,
  vn as ChatCircle,
  On as ChatCircleDots,
  Wn as ChatCircleSlash,
  Vn as ChatCircleText,
  zn as ChatDots,
  Kn as ChatSlash,
  Qn as ChatTeardrop,
  jn as ChatTeardropDots,
  _n as ChatTeardropSlash,
  rs as ChatTeardropText,
  es as ChatText,
  ps as Chats,
  fs as ChatsCircle,
  is as ChatsTeardrop,
  ls as Check,
  ss as CheckCircle,
  cs as CheckFat,
  gs as CheckSquare,
  hs as CheckSquareOffset,
  ws as Checkerboard,
  Ps as Checks,
  Ts as Cheers,
  Fs as Cheese,
  bs as ChefHat,
  ys as Cherries,
  Ms as Church,
  Us as Cigarette,
  vs as CigaretteSlash,
  Os as Circle,
  Ws as CircleDashed,
  Vs as CircleHalf,
  zs as CircleHalfTilt,
  Ks as CircleNotch,
  yV as CircleWavy,
  RV as CircleWavyCheck,
  OV as CircleWavyQuestion,
  qV as CircleWavyWarning,
  Qs as CirclesFour,
  js as CirclesThree,
  _s as CirclesThreePlus,
  rS as Circuitry,
  eS as City,
  pS as Clipboard,
  fS as ClipboardText,
  lS as Clock,
  iS as ClockAfternoon,
  sS as ClockClockwise,
  cS as ClockCountdown,
  gS as ClockCounterClockwise,
  hS as ClockUser,
  wS as ClosedCaptioning,
  FS as Cloud,
  PS as CloudArrowDown,
  TS as CloudArrowUp,
  bS as CloudCheck,
  yS as CloudFog,
  MS as CloudLightning,
  US as CloudMoon,
  vS as CloudRain,
  OS as CloudSlash,
  WS as CloudSnow,
  VS as CloudSun,
  zS as CloudWarning,
  KS as CloudX,
  QS as Clover,
  jS as Club,
  _S as CoatHanger,
  rc as CodaLogo,
  pc as Code,
  ec as CodeBlock,
  fc as CodeSimple,
  ic as CodepenLogo,
  lc as CodesandboxLogo,
  cc as Coffee,
  sc as CoffeeBean,
  gc as Coin,
  hc as CoinVertical,
  wc as Coins,
  Pc as Columns,
  Tc as ColumnsPlusLeft,
  Fc as ColumnsPlusRight,
  bc as Command,
  yc as Compass,
  Mc as CompassRose,
  Uc as CompassTool,
  vc as ComputerTower,
  Oc as Confetti,
  Wc as ContactlessPayment,
  Vc as Control,
  zc as Cookie,
  Kc as CookingPot,
  Qc as Copy,
  jc as CopySimple,
  _c as Copyleft,
  ru as Copyright,
  eu as CornersIn,
  pu as CornersOut,
  fu as Couch,
  iu as CourtBasketball,
  lu as Cow,
  su as CowboyHat,
  cu as Cpu,
  gu as Crane,
  hu as CraneTower,
  wu as CreditCard,
  Pu as Cricket,
  Tu as Crop,
  Fu as Cross,
  bu as Crosshair,
  yu as CrosshairSimple,
  Mu as Crown,
  Uu as CrownCross,
  vu as CrownSimple,
  Ou as Cube,
  Wu as CubeFocus,
  Vu as CubeTransparent,
  zu as CurrencyBtc,
  Ku as CurrencyCircleDollar,
  Qu as CurrencyCny,
  ju as CurrencyDollar,
  _u as CurrencyDollarSimple,
  rg as CurrencyEth,
  eg as CurrencyEur,
  pg as CurrencyGbp,
  fg as CurrencyInr,
  ig as CurrencyJpy,
  lg as CurrencyKrw,
  sg as CurrencyKzt,
  cg as CurrencyNgn,
  gg as CurrencyRub,
  hg as Cursor,
  wg as CursorClick,
  Pg as CursorText,
  Tg as Cylinder,
  Fg as Database,
  bg as Desk,
  yg as Desktop,
  Mg as DesktopTower,
  Ug as Detective,
  vg as DevToLogo,
  Og as DeviceMobile,
  Wg as DeviceMobileCamera,
  Vg as DeviceMobileSlash,
  zg as DeviceMobileSpeaker,
  Kg as DeviceRotate,
  Qg as DeviceTablet,
  jg as DeviceTabletCamera,
  _g as DeviceTabletSpeaker,
  rC as Devices,
  eC as Diamond,
  pC as DiamondsFour,
  fC as DiceFive,
  iC as DiceFour,
  lC as DiceOne,
  sC as DiceSix,
  cC as DiceThree,
  gC as DiceTwo,
  hC as Disc,
  wC as DiscoBall,
  PC as DiscordLogo,
  TC as Divide,
  FC as Dna,
  bC as Dog,
  yC as Door,
  MC as DoorOpen,
  UC as Dot,
  vC as DotOutline,
  OC as DotsNine,
  WC as DotsSix,
  VC as DotsSixVertical,
  zC as DotsThree,
  KC as DotsThreeCircle,
  QC as DotsThreeCircleVertical,
  jC as DotsThreeOutline,
  _C as DotsThreeOutlineVertical,
  rh as DotsThreeVertical,
  eh as Download,
  ph as DownloadSimple,
  fh as Dress,
  ih as Dresser,
  lh as DribbbleLogo,
  sh as Drone,
  ch as Drop,
  gh as DropHalf,
  hh as DropHalfBottom,
  wh as DropSimple,
  Ph as DropSlash,
  Th as DropboxLogo,
  Fh as Ear,
  bh as EarSlash,
  yh as Egg,
  Mh as EggCrack,
  Uh as Eject,
  vh as EjectSimple,
  Oh as Elevator,
  Wh as Empty,
  Vh as Engine,
  zh as Envelope,
  Kh as EnvelopeOpen,
  Qh as EnvelopeSimple,
  jh as EnvelopeSimpleOpen,
  _h as Equalizer,
  rd as Equals,
  ed as Eraser,
  pd as EscalatorDown,
  fd as EscalatorUp,
  id as Exam,
  ld as ExclamationMark,
  sd as Exclude,
  cd as ExcludeSquare,
  gd as Export,
  hd as Eye,
  wd as EyeClosed,
  Pd as EyeSlash,
  Td as Eyedropper,
  Fd as EyedropperSample,
  bd as Eyeglasses,
  yd as Eyes,
  Md as FaceMask,
  Ud as FacebookLogo,
  vd as Factory,
  Od as Faders,
  Wd as FadersHorizontal,
  Vd as FalloutShelter,
  zd as Fan,
  Kd as Farm,
  Qd as FastForward,
  jd as FastForwardCircle,
  _d as Feather,
  rw as FediverseLogo,
  ew as FigmaLogo,
  sw as File,
  pw as FileArchive,
  fw as FileArrowDown,
  iw as FileArrowUp,
  lw as FileAudio,
  cw as FileC,
  gw as FileCSharp,
  hw as FileCloud,
  ww as FileCode,
  Pw as FileCpp,
  Tw as FileCss,
  Fw as FileCsv,
  bw as FileDashed,
  Hw as FileDoc,
  Dw as FileDotted,
  Rw as FileHtml,
  Nw as FileImage,
  Gw as FileIni,
  Ew as FileJpg,
  qw as FileJs,
  Iw as FileJsx,
  Xw as FileLock,
  Jw as FileMagnifyingGlass,
  jw as FileMd,
  _w as FileMinus,
  rL as FilePdf,
  eL as FilePlus,
  pL as FilePng,
  fL as FilePpt,
  iL as FilePy,
  lL as FileRs,
  Qw as FileSearch,
  sL as FileSql,
  cL as FileSvg,
  gL as FileText,
  hL as FileTs,
  wL as FileTsx,
  PL as FileTxt,
  TL as FileVideo,
  FL as FileVue,
  bL as FileX,
  yL as FileXls,
  ML as FileZip,
  UL as Files,
  vL as FilmReel,
  OL as FilmScript,
  WL as FilmSlate,
  VL as FilmStrip,
  zL as Fingerprint,
  KL as FingerprintSimple,
  QL as FinnTheHuman,
  jL as Fire,
  _L as FireExtinguisher,
  rP as FireSimple,
  eP as FireTruck,
  pP as FirstAid,
  fP as FirstAidKit,
  iP as Fish,
  lP as FishSimple,
  gP as Flag,
  sP as FlagBanner,
  cP as FlagBannerFold,
  hP as FlagCheckered,
  wP as FlagPennant,
  PP as Flame,
  TP as Flashlight,
  FP as Flask,
  bP as FlipHorizontal,
  yP as FlipVertical,
  UP as FloppyDisk,
  MP as FloppyDiskBack,
  vP as FlowArrow,
  OP as Flower,
  WP as FlowerLotus,
  VP as FlowerTulip,
  zP as FlyingSaucer,
  KP as Folder,
  ZP as FolderDashed,
  jP as FolderDotted,
  _P as FolderLock,
  rA as FolderMinus,
  JP as FolderNotch,
  oA as FolderNotchMinus,
  tA as FolderNotchOpen,
  fA as FolderNotchPlus,
  pA as FolderOpen,
  xA as FolderPlus,
  aA as FolderSimple,
  nA as FolderSimpleDashed,
  sA as FolderSimpleDotted,
  cA as FolderSimpleLock,
  gA as FolderSimpleMinus,
  hA as FolderSimplePlus,
  wA as FolderSimpleStar,
  PA as FolderSimpleUser,
  TA as FolderStar,
  FA as FolderUser,
  bA as Folders,
  yA as Football,
  MA as FootballHelmet,
  UA as Footprints,
  vA as ForkKnife,
  OA as FourK,
  WA as FrameCorners,
  VA as FramerLogo,
  zA as Function,
  KA as Funnel,
  QA as FunnelSimple,
  jA as FunnelSimpleX,
  _A as FunnelX,
  rT as GameController,
  eT as Garage,
  pT as GasCan,
  fT as GasPump,
  iT as Gauge,
  lT as Gavel,
  sT as Gear,
  cT as GearFine,
  gT as GearSix,
  hT as GenderFemale,
  wT as GenderIntersex,
  PT as GenderMale,
  TT as GenderNeuter,
  FT as GenderNonbinary,
  bT as GenderTransgender,
  yT as Ghost,
  MT as Gif,
  UT as Gift,
  vT as GitBranch,
  OT as GitCommit,
  WT as GitDiff,
  VT as GitFork,
  zT as GitMerge,
  KT as GitPullRequest,
  QT as GithubLogo,
  jT as GitlabLogo,
  _T as GitlabLogoSimple,
  rk as Globe,
  ek as GlobeHemisphereEast,
  pk as GlobeHemisphereWest,
  fk as GlobeSimple,
  ik as GlobeSimpleX,
  lk as GlobeStand,
  sk as GlobeX,
  ck as Goggles,
  gk as Golf,
  hk as GoodreadsLogo,
  wk as GoogleCardboardLogo,
  Pk as GoogleChromeLogo,
  Tk as GoogleDriveLogo,
  Fk as GoogleLogo,
  bk as GooglePhotosLogo,
  yk as GooglePlayLogo,
  Mk as GooglePodcastsLogo,
  Uk as Gps,
  vk as GpsFix,
  Ok as GpsSlash,
  Wk as Gradient,
  Vk as GraduationCap,
  zk as Grains,
  Kk as GrainsSlash,
  Qk as Graph,
  jk as GraphicsCard,
  _k as GreaterThan,
  rF as GreaterThanOrEqual,
  eF as GridFour,
  pF as GridNine,
  fF as Guitar,
  iF as HairDryer,
  lF as Hamburger,
  sF as Hammer,
  hF as Hand,
  cF as HandArrowDown,
  gF as HandArrowUp,
  wF as HandCoins,
  PF as HandDeposit,
  TF as HandEye,
  FF as HandFist,
  bF as HandGrabbing,
  yF as HandHeart,
  MF as HandPalm,
  UF as HandPeace,
  vF as HandPointing,
  OF as HandSoap,
  WF as HandSwipeLeft,
  VF as HandSwipeRight,
  zF as HandTap,
  KF as HandWaving,
  QF as HandWithdraw,
  jF as Handbag,
  _F as HandbagSimple,
  rB as HandsClapping,
  eB as HandsPraying,
  pB as Handshake,
  fB as HardDrive,
  iB as HardDrives,
  lB as HardHat,
  sB as Hash,
  cB as HashStraight,
  gB as HeadCircuit,
  hB as Headlights,
  wB as Headphones,
  PB as Headset,
  TB as Heart,
  FB as HeartBreak,
  bB as HeartHalf,
  yB as HeartStraight,
  MB as HeartStraightBreak,
  UB as Heartbeat,
  vB as Hexagon,
  OB as HighDefinition,
  WB as HighHeel,
  VB as Highlighter,
  zB as HighlighterCircle,
  KB as Hockey,
  QB as Hoodie,
  jB as Horse,
  _B as Hospital,
  rb as Hourglass,
  eb as HourglassHigh,
  pb as HourglassLow,
  fb as HourglassMedium,
  ib as HourglassSimple,
  lb as HourglassSimpleHigh,
  sb as HourglassSimpleLow,
  cb as HourglassSimpleMedium,
  gb as House,
  hb as HouseLine,
  wb as HouseSimple,
  Pb as Hurricane,
  Tb as IceCream,
  F3 as IconBase,
  T3 as IconContext,
  Fb as IdentificationBadge,
  bb as IdentificationCard,
  yb as Image,
  Mb as ImageBroken,
  Ub as ImageSquare,
  vb as Images,
  Ob as ImagesSquare,
  Wb as Infinity,
  Ib as Info,
  Xb as InstagramLogo,
  Jb as Intersect,
  Zb as IntersectSquare,
  Yb as IntersectThree,
  $b as Intersection,
  oD as Invoice,
  tD as Island,
  mD as Jar,
  xD as JarLabel,
  aD as Jeep,
  nD as Joystick,
  SD as Kanban,
  uD as Key,
  CD as KeyReturn,
  dD as Keyboard,
  LD as Keyhole,
  AD as Knife,
  kD as Ladder,
  BD as LadderSimple,
  DD as Lamp,
  HD as LampPendant,
  RD as Laptop,
  ND as Lasso,
  GD as LastfmLogo,
  ED as Layout,
  qD as Leaf,
  ID as Lectern,
  XD as Lego,
  JD as LegoSmiley,
  qb as Lemniscate,
  ZD as LessThan,
  YD as LessThanOrEqual,
  $D as LetterCircleH,
  oy as LetterCircleP,
  ty as LetterCircleV,
  my as Lifebuoy,
  xy as Lightbulb,
  ay as LightbulbFilament,
  ny as Lighthouse,
  uy as Lightning,
  Sy as LightningA,
  Cy as LightningSlash,
  dy as LineSegment,
  Ly as LineSegments,
  Ay as LineVertical,
  ky as Link,
  By as LinkBreak,
  Dy as LinkSimple,
  Hy as LinkSimpleBreak,
  Ry as LinkSimpleHorizontal,
  Ny as LinkSimpleHorizontalBreak,
  Gy as LinkedinLogo,
  Ey as LinktreeLogo,
  qy as LinuxLogo,
  Iy as List,
  Xy as ListBullets,
  Jy as ListChecks,
  Zy as ListDashes,
  Yy as ListHeart,
  $y as ListMagnifyingGlass,
  oH as ListNumbers,
  tH as ListPlus,
  mH as ListStar,
  xH as Lock,
  aH as LockKey,
  nH as LockKeyOpen,
  SH as LockLaminated,
  uH as LockLaminatedOpen,
  CH as LockOpen,
  dH as LockSimple,
  LH as LockSimpleOpen,
  AH as Lockers,
  kH as Log,
  BH as MagicWand,
  DH as Magnet,
  HH as MagnetStraight,
  RH as MagnifyingGlass,
  NH as MagnifyingGlassMinus,
  GH as MagnifyingGlassPlus,
  EH as Mailbox,
  IH as MapPin,
  qH as MapPinArea,
  XH as MapPinLine,
  JH as MapPinPlus,
  YH as MapPinSimple,
  ZH as MapPinSimpleArea,
  $H as MapPinSimpleLine,
  oM as MapTrifold,
  tM as MarkdownLogo,
  mM as MarkerCircle,
  xM as Martini,
  aM as MaskHappy,
  nM as MaskSad,
  SM as MastodonLogo,
  uM as MathOperations,
  CM as MatrixLogo,
  dM as Medal,
  LM as MedalMilitary,
  AM as MediumLogo,
  kM as Megaphone,
  BM as MegaphoneSimple,
  DM as MemberOf,
  HM as Memory,
  RM as MessengerLogo,
  NM as MetaLogo,
  GM as Meteor,
  EM as Metronome,
  qM as Microphone,
  IM as MicrophoneSlash,
  XM as MicrophoneStage,
  JM as Microscope,
  ZM as MicrosoftExcelLogo,
  YM as MicrosoftOutlookLogo,
  $M as MicrosoftPowerpointLogo,
  oR as MicrosoftTeamsLogo,
  tR as MicrosoftWordLogo,
  mR as Minus,
  xR as MinusCircle,
  aR as MinusSquare,
  nR as Money,
  SR as MoneyWavy,
  CR as Monitor,
  uR as MonitorArrowUp,
  dR as MonitorPlay,
  LR as Moon,
  AR as MoonStars,
  kR as Moped,
  BR as MopedFront,
  DR as Mosque,
  HR as Motorcycle,
  RR as Mountains,
  NR as Mouse,
  GR as MouseLeftClick,
  ER as MouseMiddleClick,
  qR as MouseRightClick,
  IR as MouseScroll,
  XR as MouseSimple,
  JR as MusicNote,
  ZR as MusicNoteSimple,
  YR as MusicNotes,
  $R as MusicNotesMinus,
  oU as MusicNotesPlus,
  tU as MusicNotesSimple,
  mU as NavigationArrow,
  xU as Needle,
  aU as Network,
  nU as NetworkSlash,
  SU as NetworkX,
  uU as Newspaper,
  CU as NewspaperClipping,
  dU as NotEquals,
  LU as NotMemberOf,
  AU as NotSubsetOf,
  kU as NotSupersetOf,
  BU as Notches,
  HU as Note,
  DU as NoteBlank,
  RU as NotePencil,
  NU as Notebook,
  GU as Notepad,
  EU as Notification,
  qU as NotionLogo,
  IU as NuclearPlant,
  XU as NumberCircleEight,
  JU as NumberCircleFive,
  ZU as NumberCircleFour,
  YU as NumberCircleNine,
  $U as NumberCircleOne,
  oN as NumberCircleSeven,
  tN as NumberCircleSix,
  mN as NumberCircleThree,
  xN as NumberCircleTwo,
  aN as NumberCircleZero,
  nN as NumberEight,
  SN as NumberFive,
  uN as NumberFour,
  CN as NumberNine,
  dN as NumberOne,
  LN as NumberSeven,
  AN as NumberSix,
  kN as NumberSquareEight,
  BN as NumberSquareFive,
  DN as NumberSquareFour,
  HN as NumberSquareNine,
  RN as NumberSquareOne,
  NN as NumberSquareSeven,
  GN as NumberSquareSix,
  EN as NumberSquareThree,
  qN as NumberSquareTwo,
  IN as NumberSquareZero,
  XN as NumberThree,
  JN as NumberTwo,
  ZN as NumberZero,
  YN as Numpad,
  $N as Nut,
  ov as NyTimesLogo,
  tv as Octagon,
  mv as OfficeChair,
  xv as Onigiri,
  av as OpenAiLogo,
  nv as Option,
  Sv as Orange,
  uv as OrangeSlice,
  Cv as Oven,
  dv as Package,
  Lv as PaintBrush,
  Av as PaintBrushBroad,
  kv as PaintBrushHousehold,
  Bv as PaintBucket,
  Dv as PaintRoller,
  Hv as Palette,
  Rv as Panorama,
  Nv as Pants,
  Gv as PaperPlane,
  Ev as PaperPlaneRight,
  qv as PaperPlaneTilt,
  Iv as Paperclip,
  Xv as PaperclipHorizontal,
  Jv as Parachute,
  Zv as Paragraph,
  Yv as Parallelogram,
  $v as Park,
  oG as Password,
  tG as Path,
  mG as PatreonLogo,
  xG as Pause,
  aG as PauseCircle,
  nG as PawPrint,
  SG as PaypalLogo,
  uG as Peace,
  CG as Pen,
  dG as PenNib,
  LG as PenNibStraight,
  AG as Pencil,
  kG as PencilCircle,
  BG as PencilLine,
  DG as PencilRuler,
  HG as PencilSimple,
  RG as PencilSimpleLine,
  NG as PencilSimpleSlash,
  GG as PencilSlash,
  EG as Pentagon,
  qG as Pentagram,
  IG as Pepper,
  XG as Percent,
  ZG as Person,
  JG as PersonArmsSpread,
  $G as PersonSimple,
  YG as PersonSimpleBike,
  oO as PersonSimpleCircle,
  tO as PersonSimpleHike,
  mO as PersonSimpleRun,
  xO as PersonSimpleSki,
  aO as PersonSimpleSnowboard,
  nO as PersonSimpleSwim,
  SO as PersonSimpleTaiChi,
  uO as PersonSimpleThrow,
  CO as PersonSimpleWalk,
  dO as Perspective,
  LO as Phone,
  AO as PhoneCall,
  kO as PhoneDisconnect,
  BO as PhoneIncoming,
  DO as PhoneList,
  HO as PhoneOutgoing,
  RO as PhonePause,
  NO as PhonePlus,
  GO as PhoneSlash,
  EO as PhoneTransfer,
  qO as PhoneX,
  IO as PhosphorLogo,
  XO as Pi,
  JO as PianoKeys,
  ZO as PicnicTable,
  YO as PictureInPicture,
  $O as PiggyBank,
  oE as Pill,
  tE as PingPong,
  mE as PintGlass,
  xE as PinterestLogo,
  aE as Pinwheel,
  nE as Pipe,
  SE as PipeWrench,
  uE as PixLogo,
  CE as Pizza,
  dE as Placeholder,
  LE as Planet,
  AE as Plant,
  kE as Play,
  BE as PlayCircle,
  DE as PlayPause,
  HE as Playlist,
  RE as Plug,
  NE as PlugCharging,
  GE as Plugs,
  EE as PlugsConnected,
  qE as Plus,
  IE as PlusCircle,
  XE as PlusMinus,
  JE as PlusSquare,
  ZE as PokerChip,
  YE as PoliceCar,
  $E as Polygon,
  oW as Popcorn,
  tW as Popsicle,
  mW as PottedPlant,
  xW as Power,
  aW as Prescription,
  nW as Presentation,
  SW as PresentationChart,
  uW as Printer,
  CW as Prohibit,
  dW as ProhibitInset,
  LW as ProjectorScreen,
  AW as ProjectorScreenChart,
  FW as Pulse,
  bW as PushPin,
  yW as PushPinSimple,
  MW as PushPinSimpleSlash,
  UW as PushPinSlash,
  vW as PuzzlePiece,
  OW as QrCode,
  WW as Question,
  VW as QuestionMark,
  zW as Queue,
  KW as Quotes,
  QW as Rabbit,
  jW as Racquet,
  _W as Radical,
  rq as Radio,
  eq as RadioButton,
  pq as Radioactive,
  fq as Rainbow,
  iq as RainbowCloud,
  lq as Ranking,
  sq as ReadCvLogo,
  cq as Receipt,
  gq as ReceiptX,
  hq as Record,
  wq as Rectangle,
  Pq as RectangleDashed,
  Tq as Recycle,
  Fq as RedditLogo,
  bq as Repeat,
  yq as RepeatOnce,
  Mq as ReplitLogo,
  Uq as Resize,
  vq as Rewind,
  Oq as RewindCircle,
  Wq as RoadHorizon,
  Vq as Robot,
  zq as Rocket,
  Kq as RocketLaunch,
  Qq as Rows,
  jq as RowsPlusBottom,
  _q as RowsPlusTop,
  rV as Rss,
  eV as RssSimple,
  pV as Rug,
  fV as Ruler,
  r as SSR,
  iV as Sailboat,
  lV as Scales,
  sV as Scan,
  cV as ScanSmiley,
  gV as Scissors,
  hV as Scooter,
  wV as Screencast,
  PV as Screwdriver,
  TV as Scribble,
  FV as ScribbleLoop,
  bV as Scroll,
  HV as Seal,
  UV as SealCheck,
  vV as SealPercent,
  EV as SealQuestion,
  VV as SealWarning,
  zV as Seat,
  KV as Seatbelt,
  QV as SecurityCamera,
  rI as Selection,
  jV as SelectionAll,
  _V as SelectionBackground,
  eI as SelectionForeground,
  pI as SelectionInverse,
  fI as SelectionPlus,
  iI as SelectionSlash,
  lI as Shapes,
  sI as Share,
  cI as ShareFat,
  gI as ShareNetwork,
  hI as Shield,
  wI as ShieldCheck,
  PI as ShieldCheckered,
  TI as ShieldChevron,
  FI as ShieldPlus,
  bI as ShieldSlash,
  yI as ShieldStar,
  MI as ShieldWarning,
  UI as ShippingContainer,
  vI as ShirtFolded,
  OI as ShootingStar,
  WI as ShoppingBag,
  VI as ShoppingBagOpen,
  zI as ShoppingCart,
  KI as ShoppingCartSimple,
  QI as Shovel,
  jI as Shower,
  _I as Shrimp,
  ez as Shuffle,
  rz as ShuffleAngular,
  pz as ShuffleSimple,
  fz as Sidebar,
  iz as SidebarSimple,
  lz as Sigma,
  sz as SignIn,
  cz as SignOut,
  gz as Signature,
  hz as Signpost,
  wz as SimCard,
  Pz as Siren,
  Tz as SketchLogo,
  Fz as SkipBack,
  bz as SkipBackCircle,
  yz as SkipForward,
  Mz as SkipForwardCircle,
  Uz as Skull,
  vz as SkypeLogo,
  Oz as SlackLogo,
  Wz as Sliders,
  Vz as SlidersHorizontal,
  zz as Slideshow,
  jz as Smiley,
  Kz as SmileyAngry,
  Qz as SmileyBlank,
  _z as SmileyMeh,
  rX as SmileyMelting,
  eX as SmileyNervous,
  pX as SmileySad,
  fX as SmileySticker,
  iX as SmileyWink,
  lX as SmileyXEyes,
  sX as SnapchatLogo,
  cX as Sneaker,
  gX as SneakerMove,
  hX as Snowflake,
  wX as SoccerBall,
  PX as Sock,
  TX as SolarPanel,
  FX as SolarRoof,
  bX as SortAscending,
  yX as SortDescending,
  MX as SoundcloudLogo,
  UX as Spade,
  vX as Sparkle,
  OX as SpeakerHifi,
  WX as SpeakerHigh,
  VX as SpeakerLow,
  zX as SpeakerNone,
  KX as SpeakerSimpleHigh,
  QX as SpeakerSimpleLow,
  jX as SpeakerSimpleNone,
  _X as SpeakerSimpleSlash,
  rK as SpeakerSimpleX,
  eK as SpeakerSlash,
  pK as SpeakerX,
  fK as Speedometer,
  iK as Sphere,
  sK as Spinner,
  lK as SpinnerBall,
  cK as SpinnerGap,
  gK as Spiral,
  hK as SplitHorizontal,
  wK as SplitVertical,
  PK as SpotifyLogo,
  TK as SprayBottle,
  FK as Square,
  bK as SquareHalf,
  yK as SquareHalfBottom,
  MK as SquareLogo,
  UK as SquareSplitHorizontal,
  vK as SquareSplitVertical,
  OK as SquaresFour,
  WK as Stack,
  VK as StackMinus,
  zK as StackOverflowLogo,
  KK as StackPlus,
  QK as StackSimple,
  jK as Stairs,
  _K as Stamp,
  rJ as StandardDefinition,
  pJ as Star,
  eJ as StarAndCrescent,
  fJ as StarFour,
  iJ as StarHalf,
  lJ as StarOfDavid,
  sJ as SteamLogo,
  cJ as SteeringWheel,
  gJ as Steps,
  hJ as Stethoscope,
  wJ as Sticker,
  PJ as Stool,
  TJ as Stop,
  FJ as StopCircle,
  bJ as Storefront,
  yJ as Strategy,
  MJ as StripeLogo,
  UJ as Student,
  vJ as SubsetOf,
  OJ as SubsetProperOf,
  WJ as Subtitles,
  VJ as SubtitlesSlash,
  zJ as Subtract,
  KJ as SubtractSquare,
  QJ as Subway,
  jJ as Suitcase,
  _J as SuitcaseRolling,
  rQ as SuitcaseSimple,
  eQ as Sun,
  pQ as SunDim,
  fQ as SunHorizon,
  iQ as Sunglasses,
  lQ as SupersetOf,
  sQ as SupersetProperOf,
  cQ as Swap,
  gQ as Swatches,
  hQ as SwimmingPool,
  wQ as Sword,
  PQ as Synagogue,
  TQ as Syringe,
  FQ as TShirt,
  bQ as Table,
  yQ as Tabs,
  MQ as Tag,
  UQ as TagChevron,
  vQ as TagSimple,
  OQ as Target,
  WQ as Taxi,
  VQ as TeaBag,
  zQ as TelegramLogo,
  KQ as Television,
  QQ as TelevisionSimple,
  jQ as TennisBall,
  _Q as Tent,
  rZ as Terminal,
  eZ as TerminalWindow,
  pZ as TestTube,
  fZ as TextAUnderline,
  iZ as TextAa,
  lZ as TextAlignCenter,
  sZ as TextAlignJustify,
  cZ as TextAlignLeft,
  gZ as TextAlignRight,
  hZ as TextB,
  dZ as TextBolder,
  LZ as TextColumns,
  AZ as TextH,
  kZ as TextHFive,
  BZ as TextHFour,
  DZ as TextHOne,
  HZ as TextHSix,
  RZ as TextHThree,
  NZ as TextHTwo,
  GZ as TextIndent,
  EZ as TextItalic,
  qZ as TextOutdent,
  IZ as TextStrikethrough,
  XZ as TextSubscript,
  JZ as TextSuperscript,
  ZZ as TextT,
  YZ as TextTSlash,
  $Z as TextUnderline,
  oj as Textbox,
  tj as Thermometer,
  mj as ThermometerCold,
  xj as ThermometerHot,
  aj as ThermometerSimple,
  nj as ThreadsLogo,
  Sj as ThreeD,
  uj as ThumbsDown,
  Cj as ThumbsUp,
  dj as Ticket,
  Lj as TidalLogo,
  Aj as TiktokLogo,
  kj as Tilde,
  Bj as Timer,
  Dj as TipJar,
  Hj as Tipi,
  Rj as Tire,
  Nj as ToggleLeft,
  Gj as ToggleRight,
  Ej as Toilet,
  qj as ToiletPaper,
  Ij as Toolbox,
  Xj as Tooth,
  Jj as Tornado,
  Zj as Tote,
  Yj as ToteSimple,
  $j as Towel,
  oY as Tractor,
  tY as Trademark,
  mY as TrademarkRegistered,
  xY as TrafficCone,
  aY as TrafficSign,
  nY as TrafficSignal,
  SY as Train,
  uY as TrainRegional,
  CY as TrainSimple,
  dY as Tram,
  LY as Translate,
  AY as Trash,
  kY as TrashSimple,
  MY as Tray,
  bY as TrayArrowDown,
  yY as TrayArrowUp,
  UY as TreasureChest,
  vY as Tree,
  OY as TreeEvergreen,
  WY as TreePalm,
  VY as TreeStructure,
  zY as TreeView,
  KY as TrendDown,
  QY as TrendUp,
  jY as Triangle,
  _Y as TriangleDashed,
  r0 as Trolley,
  e0 as TrolleySuitcase,
  p0 as Trophy,
  f0 as Truck,
  i0 as TruckTrailer,
  l0 as TumblrLogo,
  s0 as TwitchLogo,
  c0 as TwitterLogo,
  g0 as Umbrella,
  h0 as UmbrellaSimple,
  w0 as Union,
  P0 as Unite,
  T0 as UniteSquare,
  F0 as Upload,
  b0 as UploadSimple,
  y0 as Usb,
  M0 as User,
  U0 as UserCheck,
  v0 as UserCircle,
  O0 as UserCircleCheck,
  W0 as UserCircleDashed,
  V0 as UserCircleGear,
  z0 as UserCircleMinus,
  K0 as UserCirclePlus,
  Q0 as UserFocus,
  j0 as UserGear,
  _0 as UserList,
  r1 as UserMinus,
  e1 as UserPlus,
  p1 as UserRectangle,
  f1 as UserSound,
  i1 as UserSquare,
  l1 as UserSwitch,
  s1 as Users,
  c1 as UsersFour,
  g1 as UsersThree,
  h1 as Van,
  w1 as Vault,
  P1 as VectorThree,
  T1 as VectorTwo,
  F1 as Vibrate,
  b1 as Video,
  y1 as VideoCamera,
  M1 as VideoCameraSlash,
  U1 as VideoConference,
  v1 as Vignette,
  O1 as VinylRecord,
  W1 as VirtualReality,
  V1 as Virus,
  z1 as Visor,
  K1 as Voicemail,
  Q1 as Volleyball,
  j1 as Wall,
  _1 as Wallet,
  r2 as Warehouse,
  e2 as Warning,
  p2 as WarningCircle,
  f2 as WarningDiamond,
  i2 as WarningOctagon,
  l2 as WashingMachine,
  s2 as Watch,
  c2 as WaveSawtooth,
  g2 as WaveSine,
  h2 as WaveSquare,
  w2 as WaveTriangle,
  P2 as Waveform,
  T2 as WaveformSlash,
  F2 as Waves,
  b2 as Webcam,
  y2 as WebcamSlash,
  M2 as WebhooksLogo,
  U2 as WechatLogo,
  v2 as WhatsappLogo,
  O2 as Wheelchair,
  W2 as WheelchairMotion,
  V2 as WifiHigh,
  z2 as WifiLow,
  K2 as WifiMedium,
  Q2 as WifiNone,
  j2 as WifiSlash,
  _2 as WifiX,
  r3 as Wind,
  e3 as Windmill,
  p3 as WindowsLogo,
  f3 as Wine,
  i3 as Wrench,
  l3 as X,
  s3 as XCircle,
  c3 as XLogo,
  g3 as XSquare,
  h3 as Yarn,
  w3 as YinYang,
  P3 as YoutubeLogo
};

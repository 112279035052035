import axios from 'axios';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useWeb3 } from './useWeb3';
import { CreateClaimFieldsNew } from '../components/display/views/new-invoice';
import { TaxType } from '../components/modals/create-claim-modal/create-claim-inputs';
import React from 'react';
import { IN_DEV_ENV, IN_STAGING_ENV } from '../tools/env-constants';

interface SaveInvoicePayload {
    invoiceData: string;
}

interface SaveInvoiceResponse {
    invoiceId: string;
}

export interface LineItemDto {
    description: string;
    referenceNumber?: string;
    quantity: number;
    unitPrice: number;
}

export interface TaxDto {
    rate: number;
    type: TaxType;
}

export const SUPPORTED_VERSIONS = [1] as const;
export type SupportedVersion = typeof SUPPORTED_VERSIONS[number];

export interface HybridInvoiceDataSave {
    version: number;
    lineItems: LineItemDto[];
    description: string;
    tax?: TaxDto;
}

export interface HybridInvoiceData extends HybridInvoiceDataSave {
    payloadId: string;
}

export const CUSTODIAN_IDS = {
    DEV: 'A440CCE6-E0BA-40B3-A5DE-DEBA3854BE15',
    PROD: '91127ED8-BAA1-475C-ADDE-F30072FDE624',
} as const;

export type CustodianId = typeof CUSTODIAN_IDS[keyof typeof CUSTODIAN_IDS];

export const custodianId = IN_DEV_ENV || IN_STAGING_ENV ? CUSTODIAN_IDS.DEV : CUSTODIAN_IDS.PROD;

export const isValidVersion = (version: SupportedVersion): boolean => SUPPORTED_VERSIONS.includes(version);

export const isValidCustodianId = (id: string): id is CustodianId => Object.values(CUSTODIAN_IDS).includes(id as CustodianId);

export const isValidUUID = (uuid: string): boolean => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
};

export type ProcessedHybridClaimsRecord = Record<string, HybridInvoiceData>;

type GetInvoiceDataBatchResponse = {
    invoices: HybridInvoiceData[];
};

export const useCustodianApi = () => {
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork, userAddress } = useWeb3();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';
    const actingWallet = !!connectedSafeAddress ? connectedSafeAddress : userAddress;

    const getInvoiceDataBatch = React.useCallback(
        async (payloadIds: string[], custodianId: string) => {
            try {
                const { data } = await axios.post<GetInvoiceDataBatchResponse>(
                    `${endpoints.custodianApi(custodianId)}/${actingWallet}/batch/get${gnosisSafeQueryArgs}`,
                    JSON.stringify(payloadIds),
                    {
                        withCredentials: true,
                        headers: { 'content-type': 'application/json' },
                    },
                );
                return data.invoices;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 401) {
                        throw new Error('Authentication failed');
                    } else if (error.response?.status === 403) {
                        throw new Error('Unauthorized access to invoice data');
                    } else if (error.response?.status === 404) {
                        throw new Error('Invoice not found');
                    } else if (error.response?.status && error.response?.status >= 500) {
                        throw new Error('Server error occurred. Please try again later');
                    }
                }
                throw new Error('Failed to retrieve invoice data');
            }
        },
        [actingWallet, gnosisSafeQueryArgs],
    );

    const getInvoiceData = React.useCallback(
        async (payloadId: string, custodianId: string) => {
            try {
                const { data } = await axios.get<HybridInvoiceData>(
                    `${endpoints.custodianApi(custodianId)}/${actingWallet}/payload/${payloadId}${gnosisSafeQueryArgs}`,
                    {
                        withCredentials: true,
                    },
                );
                return data;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 401) {
                        throw new Error('Authentication failed');
                    } else if (error.response?.status === 403) {
                        throw new Error('Unauthorized access to invoice data');
                    } else if (error.response?.status === 404) {
                        throw new Error('Invoice not found');
                    } else if (error.response?.status && error.response?.status >= 500) {
                        throw new Error('Server error occurred. Please try again later');
                    }
                }
                throw new Error('Failed to retrieve invoice data');
            }
        },
        [actingWallet, gnosisSafeQueryArgs],
    );

    const saveInvoiceData = async (formikInputs: CreateClaimFieldsNew): Promise<string> => {
        const walletWithViewPermission = formikInputs.recipient;

        try {
            const invoiceData: HybridInvoiceDataSave = {
                version: 1,
                lineItems: formikInputs.lineItems.map(item => ({
                    description: item.description,
                    referenceNumber: item.referenceNumber || undefined,
                    quantity: Number(item.quantity),
                    unitPrice: Number(item.unitPrice),
                })),
                description: formikInputs.description,
                tax: formikInputs.tax
                    ? {
                          rate: Number(formikInputs.tax.taxRate),
                          type: formikInputs.tax.taxType,
                      }
                    : undefined,
            };

            const payload: SaveInvoicePayload = {
                invoiceData: JSON.stringify(invoiceData),
            };

            const { data } = await axios.post<SaveInvoiceResponse>(
                `${endpoints.custodianApi(custodianId)}/save/${actingWallet}/and/${walletWithViewPermission}${gnosisSafeQueryArgs}`,
                payload,
                {
                    withCredentials: true,
                    headers: { 'content-type': 'application/json' },
                },
            );

            return data.invoiceId;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    throw new Error('Invalid invoice data or wallet address');
                } else if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                } else if (error.response?.status && error.response?.status >= 500) {
                    throw new Error('Server error occurred. Please try again later');
                }
            }
            throw new Error('Failed to save invoice data');
        }
    };

    return {
        saveInvoiceData,
        getInvoiceData,
        getInvoiceDataBatch,
    };
};

import {
    Button,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ExportFileFormat } from '../../../tools/excelExport';
import { CloseModalButton } from '../common';
import { ExportFileFormatSelector } from '../ledger-export-wizard-modal/ledger-export-modal';

export type _8949ExportModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
};

type SelectedExportTypes = {
    matching: boolean;
    transactionsIn: boolean;
    transactionsOut: boolean;
    transfers: boolean;
};

type ExportType = keyof SelectedExportTypes;

const toDisplayText = (exportType: ExportType) => {
    switch (exportType) {
        case 'matching':
            return 'Transactions Matching';
        case 'transactionsIn':
            return 'Transactions (In)';
        case 'transactionsOut':
            return 'Transactions (Out)';
        case 'transfers':
            return 'Transfers';
    }
};

export const _8949ExportModal = ({ triggerElement }: _8949ExportModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [format, setFormat] = React.useState<ExportFileFormat>('csv');

    const [selectedExportTypes, setSelectedExportTypes] = React.useState<SelectedExportTypes>({
        matching: false,
        transactionsIn: false,
        transactionsOut: false,
        transfers: false,
    });

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size="md" closeOnEsc scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent py="4" px="2" maxH="80%">
                    <CloseModalButton onClose={onClose} />
                    <ModalHeader pb={6} pt={6}>
                        <Text color="icon_dark" textStyle="labelLg">
                            Export
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <Stack>
                            {(Object.keys(selectedExportTypes) as (keyof SelectedExportTypes)[]).map(exportType => (
                                <HStack key={exportType}>
                                    <Checkbox
                                        isChecked={selectedExportTypes[exportType]}
                                        onChange={_ => setSelectedExportTypes(prev => ({ ...prev, [exportType]: !prev[exportType] }))}
                                    />
                                    <Text fontWeight={'bold'}>{toDisplayText(exportType)}</Text>
                                </HStack>
                            ))}
                            <HStack key={'format'} mt="4">
                                <ExportFileFormatSelector selectedFormat={format} onFormatSelected={setFormat} />
                                <Spacer />
                            </HStack>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                            Cancel
                        </Button>
                        <Spacer />
                        <Button
                            px="8"
                            py="6"
                            fontWeight="500"
                            colorScheme="accent"
                            type="submit"
                            isLoading={inProgress}
                            onClick={() => null}
                        >
                            Export
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

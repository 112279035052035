import { Box, Flex, Heading, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { ArrowLeft } from 'phosphor-react';
import React, { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Build8949Response, _1099PaymentEntryDto } from '../../../hooks/useExternalTransactionsApi';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { TabOptions, TabSwitcher } from '../../display/views/account-tag-view';
import { _8949Tab, _8949Tabs, get8949Summary, get8949TypeDisplayName } from './tabs-type';
import { ResponsiveStack } from '../../display/responsive-stack';
import { SetDate, DateSelect, ClearFilterPillsStack, PillProps } from '../../display/views/filters/common';
import { ColumnDefinition, ListViewCard, ShadowBox } from '../../layout/cards';
import { transactionMatchingRows, transferRows, transactionRows } from './mock-data';
import { TRANSACTION_IN_HEADERS, TRANSACTION_OUT_HEADERS, TRANSACTION_MATCHING_HEADERS, TRANSFER_HEADERS } from './headers';
import { SecondaryButton } from '../../inputs/buttons';
import { _8949ExportModal } from './export-modal';
import { toDateDisplay } from '../../../tools/common';

const exportFilterToPills = (setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>) => (filters: ExportFilterValues) => {
    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    return [...dateFilterPills];
};

type ExportFilterValues = {
    date: { startDate?: Date; endDate?: Date };
};

const initialExportFilterValues = () => {
    const now = new Date();
    const year = now.getFullYear();
    const yearToDisplay = now.getMonth() >= 5 ? year : year - 1;
    return { date: { startDate: new Date(yearToDisplay, 0, 1), endDate: new Date(yearToDisplay, 11, 31) } };
};

const getPathnameFor8949Report = (tab: _8949Tab) => {
    return {
        pathname: '/8949-wizard',
        search: `?tab=${tab}`,
    };
};

type FilterProps = {
    filters: ExportFilterValues;
    setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>;
};

const ExportFilter = ({ setFilters, filters }: FilterProps) => {
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
            </ResponsiveStack>
        </Box>
    );
};

const getHeaderForActiveTab = (activeTab: _8949Tab): (string | ColumnDefinition)[] => {
    switch (activeTab) {
        case 'transactionsIn':
            return TRANSACTION_IN_HEADERS;
        case 'transactionsOut':
            return TRANSACTION_OUT_HEADERS;
        case 'transactionMatching':
            return TRANSACTION_MATCHING_HEADERS;
        case 'transfers':
            return TRANSFER_HEADERS;
    }
};

type _8949SuccessCardProps = { data: Build8949Response; reportName: string };
export const _8949SuccessCard = ({ data, reportName }: _8949SuccessCardProps) => {
    const navigate = useNavigate();
    const _8949TabOptions: TabOptions<_8949Tab>[] = _8949Tabs.map(tab => ({
        label: get8949TypeDisplayName(tab),
        value: tab,
    }));
    const setTab = (tab: _8949Tab) => {
        setActiveTab(tab);
        navigate(getPathnameFor8949Report(tab), { replace: true });
    };
    const [activeTab, setActiveTab] = useState<_8949Tab>(_8949Tabs[0]);

    const [filters, setFilters] = React.useState<ExportFilterValues>({ date: { startDate: undefined, endDate: undefined } });

    React.useEffect(() => {
        setFilters(initialExportFilterValues());
    }, []);

    const visibleMockRows =
        activeTab === 'transactionMatching' ? transactionMatchingRows : activeTab === 'transfers' ? transferRows : transactionRows;

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <HStack cursor="pointer" onClick={() => navigate('/reporting')} mb="6" color="gray.600">
                    <ArrowLeft size={18} weight="bold" />
                    <Text fontWeight="semibold" fontSize="md">
                        Back to Reporting Explorer
                    </Text>
                </HStack>
                <Flex>
                    <Heading textStyle="bullaViewHeader">8949 Report</Heading>
                    <Spacer />
                    <_8949ExportModal
                        triggerElement={onClick => (
                            <SecondaryButton onClick={onClick} h="10" px="6">
                                Export
                            </SecondaryButton>
                        )}
                    />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Stack pos="relative" spacing="0" overflow={'auto'} mb="8">
                    <HStack mt="4" alignItems="flex-end">
                        <TabSwitcher tab={activeTab} setTab={setTab} options={_8949TabOptions} activeColor="brand.bulla_blue" />
                    </HStack>
                </Stack>
                <ExportFilter filters={filters} setFilters={setFilters} />
                <ClearFilterPillsStack
                    clearAll={() => setFilters({ date: { startDate: undefined, endDate: undefined } })}
                    filters={filters}
                    filtersToPills={exportFilterToPills(setFilters)}
                />
                <ShadowBox my="8" px="6" py="8" display={'flex'} flexDirection={'row'} alignItems="center">
                    <Heading size="md" h="fit-content">
                        Summary
                    </Heading>
                    <Spacer />
                    <Stack direction="row" spacing={4}>
                        {get8949Summary(activeTab).map((column, index) => (
                            <Box key={index}>
                                <Text fontWeight={'600'} fontSize="14px">
                                    {column.label}
                                </Text>
                                <Text fontWeight={700} fontSize="20px">
                                    {column.mockValue?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) || '-'}
                                </Text>
                            </Box>
                        ))}
                    </Stack>
                </ShadowBox>
                <ListViewCard
                    headers={getHeaderForActiveTab(activeTab)}
                    displayedListItems={visibleMockRows}
                    emptyMessage="No data available"
                    rowSizeOverride="5em"
                    alternateRowColor="#F4F6F9"
                    showOverflowX
                />
            </MaxWidthWrapper>
        </Flex>
    );
};

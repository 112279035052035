import { BigNumber, ethers } from 'ethers';
import { formatEther, isAddress, isHexString, parseEther } from 'ethers/lib/utils';
import { TokenDto } from './networks';

export const AddressOne = '0x0000000000000000000000000000000000000001';
export const addressEquality = (address1: string, address2: string) => address1.toLowerCase() === address2.toLowerCase();
export const addressInList = (address: string, addressList: string[]) =>
    addressList.map(a => a.toLowerCase()).includes(address.toLowerCase());

export type Ethereum = number;
export type EthAddress = string;
export const fromEther = (ether: Ethereum) => parseEther(ether.toString());
export const toEther = (wei: BigNumber): Ethereum => Number(formatEther(wei));
export const parseBigNumber = (wei: BigNumber): number => (!isNaN(+wei) ? +wei : 0);
/**
 *
 * @param amountWei the wei amount in whatever token (e.x): $2 USDC = 2000000 or 2ETH = 2000000000000000000 OR BigNumber directly off chain
 * @param token the associated token
 * @param decimals the amount of decimals of the token, if known
 * @returns the parsed display amount, or 0 if no props are provided
 */
export const weiToDisplayAmt = ({ amountWei, token, decimals }: { amountWei: BigNumber | number; token?: TokenDto; decimals?: number }) => {
    const bnValue = amountWei instanceof BigNumber ? amountWei : BigNumber.from(amountWei);
    const exponent = token ? token.decimals : decimals ? decimals : 0;

    const result = parseFloat(ethers.utils.formatUnits(bnValue, exponent));
    return !isNaN(result) ? result : 0;
};

export const toEtherSafe = (wei: BigNumber | undefined) => (wei ? toEther(wei) : 0);
export const isValidAddress = (address: string) => isAddress(address);
export const isBytes32 = (str: string) => isHexString(str) && str.length === 66;
export const toChecksumAddress = (address: string) => ethers.utils.getAddress(address.toLowerCase());

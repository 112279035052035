const TORUS_LEGACY_NETWORK = {
  MAINNET: "mainnet",
  TESTNET: "testnet",
  CYAN: "cyan",
  AQUA: "aqua",
  CELESTE: "celeste"
};
const TORUS_SAPPHIRE_NETWORK = {
  SAPPHIRE_DEVNET: "sapphire_devnet",
  SAPPHIRE_MAINNET: "sapphire_mainnet"
};
const PROXY_CONTRACT_ADDRESS = {
  [TORUS_LEGACY_NETWORK.MAINNET]: "0xf20336e16B5182637f09821c27BDe29b0AFcfe80",
  [TORUS_LEGACY_NETWORK.TESTNET]: "0xd084604e5FA387FbC2Da8bAab07fDD6aDED4614A",
  [TORUS_LEGACY_NETWORK.CYAN]: "0x9f072ba19b3370e512aa1b4bfcdaf97283168005",
  [TORUS_LEGACY_NETWORK.AQUA]: "0x29Dea82a0509153b91040ee13cDBba0f03efb625",
  [TORUS_LEGACY_NETWORK.CELESTE]: "0x6Bffb4e89453069E7487f0fa5c9f4a2D771cce6c"
};
const MULTI_CLUSTER_NETWORKS = [
  // TORUS_LEGACY_NETWORK.AQUA,
  // TORUS_LEGACY_NETWORK.CELESTE,
  // TORUS_LEGACY_NETWORK.CYAN,
];
const LEGACY_NETWORKS_ROUTE_MAP = {
  [TORUS_LEGACY_NETWORK.AQUA]: {
    migrationCompleted: true,
    networkIdentifier: "aqua",
    networkMigratedTo: TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET
  },
  [TORUS_LEGACY_NETWORK.CELESTE]: {
    migrationCompleted: true,
    networkIdentifier: "celeste",
    networkMigratedTo: TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET
  },
  [TORUS_LEGACY_NETWORK.CYAN]: {
    migrationCompleted: true,
    networkIdentifier: "cyan",
    networkMigratedTo: TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET
  },
  [TORUS_LEGACY_NETWORK.MAINNET]: {
    migrationCompleted: true,
    networkIdentifier: "mainnet",
    networkMigratedTo: TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET
  },
  [TORUS_LEGACY_NETWORK.TESTNET]: {
    migrationCompleted: true,
    networkIdentifier: "teal",
    networkMigratedTo: TORUS_SAPPHIRE_NETWORK.SAPPHIRE_DEVNET
  }
};
const NETWORK_MAP = {
  [TORUS_LEGACY_NETWORK.MAINNET]: "mainnet",
  [TORUS_LEGACY_NETWORK.TESTNET]: "goerli",
  [TORUS_LEGACY_NETWORK.CYAN]: "polygon-mainnet",
  [TORUS_LEGACY_NETWORK.AQUA]: "polygon-mainnet",
  [TORUS_LEGACY_NETWORK.CELESTE]: "polygon-mainnet"
};
const SIGNER_MAP = {
  [TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET]: "https://signer.web3auth.io",
  [TORUS_SAPPHIRE_NETWORK.SAPPHIRE_DEVNET]: "https://signer.web3auth.io",
  [TORUS_LEGACY_NETWORK.MAINNET]: "https://signer.web3auth.io",
  [TORUS_LEGACY_NETWORK.TESTNET]: "https://signer.web3auth.io",
  [TORUS_LEGACY_NETWORK.CYAN]: "https://signer-polygon.web3auth.io",
  [TORUS_LEGACY_NETWORK.AQUA]: "https://signer-polygon.web3auth.io",
  [TORUS_LEGACY_NETWORK.CELESTE]: "https://signer-polygon.web3auth.io"
};
const METADATA_MAP = {
  [TORUS_LEGACY_NETWORK.MAINNET]: "https://metadata.web3auth.io",
  [TORUS_LEGACY_NETWORK.TESTNET]: "https://metadata.web3auth.io",
  [TORUS_LEGACY_NETWORK.CYAN]: "https://metadata.web3auth.io",
  [TORUS_LEGACY_NETWORK.AQUA]: "https://metadata.web3auth.io",
  [TORUS_LEGACY_NETWORK.CELESTE]: "https://metadata.web3auth.io"
};
const FND_SERVER = "https://fnd.web3auth.io";
const SESSION_SERVER = "https://session.web3auth.io";
const KEY_TYPE = {
  SECP256K1: "secp256k1",
  ED25519: "ed25519"
};

const abi = [{
  inputs: [{
    internalType: "string",
    name: "_verifier",
    type: "string"
  }, {
    internalType: "bytes32",
    name: "hashedVerifierId",
    type: "bytes32"
  }],
  name: "getNodeSet",
  outputs: [{
    internalType: "uint256",
    name: "currentEpoch",
    type: "uint256"
  }, {
    internalType: "string[]",
    name: "torusNodeEndpoints",
    type: "string[]"
  }, {
    internalType: "uint256[]",
    name: "torusNodePubX",
    type: "uint256[]"
  }, {
    internalType: "uint256[]",
    name: "torusNodePubY",
    type: "uint256[]"
  }, {
    internalType: "uint256[]",
    name: "torusIndexes",
    type: "uint256[]"
  }],
  stateMutability: "view",
  type: "function"
}];

export { FND_SERVER, KEY_TYPE, LEGACY_NETWORKS_ROUTE_MAP, METADATA_MAP, MULTI_CLUSTER_NETWORKS, NETWORK_MAP, PROXY_CONTRACT_ADDRESS, SESSION_SERVER, SIGNER_MAP, TORUS_LEGACY_NETWORK, TORUS_SAPPHIRE_NETWORK, abi };

export const transactionRows = [
    {
        selectId: '1',
        columnValues: [
            '19 Nov 2024',
            'Ethereum',
            '0x742d...3f9b',
            '0x8d12...4e2a',
            'USDC',
            '1,500.00',
            '$1,500.00',
            'Token Transfer',
            'DeFi',
            'Uniswap trade',
            '0x7d8f...2e4b',
            '',
        ],
    },
    {
        selectId: '2',
        columnValues: [
            '19 Nov 2024',
            'Polygon',
            '0x9f3d...2a1c',
            '0x1234...5678',
            'MATIC',
            '2,750.50',
            '$2,234.15',
            'Swap',
            'Trading',
            'QuickSwap exchange',
            '0x3f5e...9c8d',
            '',
        ],
    },
    {
        selectId: '3',
        columnValues: [
            '19 Nov 2024',
            'Arbitrum',
            '0xa1b2...c3d4',
            '0x1357...9246',
            'ETH',
            '0.85',
            '$2,975.50',
            'Bridge Transfer',
            'Bridge',
            'L1 to L2 bridge',
            '0x4d3a...7b6c',
            '',
        ],
    },
];

export const transactionMatchingRows = [
    {
        selectId: '1',
        columnValues: [
            '0x89b...5e6d',
            '137',
            '0.533702',
            'USDC',
            '10/14/2020 20:52:26',
            '9/1/2021 14:59:56',
            '0.534678843331',
            '0.533884466013',
            '0.000844375296846',
            '1',
            '1',
            '1.001830336',
            '1.000268943',
            '0',
            '4988.281702',
            '4988.748',
            '0x44...1e52',
            'ETHEREUM',
            '0x8c...bd70',
        ],
    },
];

export const transferRows = [
    {
        selectId: '1',
        columnValues: [
            '10/5/2021 16:33:55',
            '10/12/2021 19:18:40',
            'ETHEREUM',
            'USDC',
            '15',
            '0x2d...1de8',
            '0x89...5e6d',
            '1.001017875',
            '0x89...5e6d',
            'ETHEREUM',
            '0xcf...3d24',
        ],
    },
    {
        selectId: '2',
        columnValues: [
            '2/1/2022 22:56:10',
            '4/1/2022 19:29:23',
            'ETHEREUM',
            'USDC',
            '6000',
            '0x2d...1de8',
            '0x89...5e6d',
            '1.001094439',
            '0x89...5e6d',
            'ETHEREUM',
            '0xcf...3d24',
        ],
    },
    {
        selectId: '3',
        columnValues: [
            '2/14/2022 16:47:46',
            '4/3/2022 23:16:14',
            'GNOSIS',
            'USDC',
            '8000',
            '0x2d...1de8',
            '0x89...5e6d',
            '1.000207458',
            '0x89...5e6d',
            'GNOSIS',
            '0xcf...3d24',
        ],
    },
];

import React from 'react';
import { Box, Flex, Text, HStack, VStack } from '@chakra-ui/react';
import { TokenAmount } from '../currency/token-display-amount';
import { NewDirectionBadge } from '../direction-badge';
import { TokenInfo } from '../../data-lib/networks';
import { BigNumber } from 'ethers';
import ExportMenu from '../../tools/exportMenu';

export interface DepositRedemptionSummary {
    totalDeposits: BigNumber;
    totalRedemptions: BigNumber;
    total: BigNumber;
}

interface DepositRedemptionTotalsProps {
    summary: DepositRedemptionSummary;
    poolUnderlyingToken: TokenInfo;
    handleExport: (method: 'excel' | 'csv') => Promise<void>;
}

export const DepositRedemptionTotals: React.FC<DepositRedemptionTotalsProps> = ({ summary, poolUnderlyingToken, handleExport }) => {
    return (
        <Box borderWidth="1px" borderRadius="lg" p="6" shadow="md">
            <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="3xl" fontWeight="bold">
                    Deposit & Redemptions
                </Text>
                <HStack spacing="8" textStyle="columnName" alignItems="start">
                    <VStack alignItems="left">
                        <Box>
                            <NewDirectionBadge direction="In" />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            <TokenAmount amount={summary.totalDeposits} tokenInfo={poolUnderlyingToken} withRounding />
                        </Box>
                    </VStack>
                    <VStack alignItems="left">
                        <Box>
                            <NewDirectionBadge direction="Out" />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            <TokenAmount amount={summary.totalRedemptions} tokenInfo={poolUnderlyingToken} withRounding />
                        </Box>
                    </VStack>
                    <HStack>
                        <VStack alignItems="left" mr="4">
                            <Box>Total</Box>
                            <Box fontWeight={700} fontSize="16px" color="black">
                                <TokenAmount amount={summary.total} tokenInfo={poolUnderlyingToken} withRounding />
                            </Box>
                        </VStack>
                        <ExportMenu handleExport={handleExport} />
                    </HStack>
                </HStack>
            </Flex>
        </Box>
    );
};

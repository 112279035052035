import { CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonProps,
    Collapse,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Skeleton,
    Spinner,
    Stack,
    Text,
    useBoolean,
    useDisclosure,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import * as Yup from 'yup';
import { MessageParams } from 'yup/lib/types';
import { AlertInfo } from '../../../components/base/alert';
import { randomWidth } from '../../../components/base/skeleton';
import { useBullaToast } from '../../../components/base/toast';
import { CreateOrangeButton, TextButton } from '../../../components/inputs/buttons';
import { MaxWidthWrapper } from '../../../components/layout/page-layout';
import { CloseModalButton } from '../../../components/modals/common';
import { disabledInputProps } from '../../../components/modals/create-claim-modal/create-claim-inputs';
import { useAttachmentGenerationState } from '../../../hooks/useAttachmentGenerationState';
import { useCompanyDetailsRepo } from '../../../hooks/useCompanyDetailsRepo';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useSessionStorage } from '../../../hooks/useStorage';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { isUserReady, useAuth } from '../../../state/auth-state';
import { BULLA_NETWORK_DISCORD_INVITE } from '../../../tools/common';
import { STORAGE_KEYS } from '../../../tools/storage';
import { CaughtError } from '../../../tools/types';
import { SignIn } from '../sign-in';
import {
    CompanyDetails,
    countAddedLines,
    emptyFields,
    Fields,
    FIELDS,
    mapCompanyDetailsToFields,
    mapFieldsToCompanyDetails,
} from './common';
import { DebouncedInvoicePreview } from './invoice-preview';

const INPUT_TOO_LONG_ERROR = 'Must not exceed 45 characters';
const YupString = Yup.string().max(45, INPUT_TOO_LONG_ERROR);

export const requiredError = (value: MessageParams) => `${_.startCase(value.path)} is required`;

export const companyDetailsSchema = Yup.object().shape({
    companyName: YupString.required(requiredError),
    addressLine1: YupString,
    addressLine2: YupString,
    city: YupString,
    state: YupString,
    country: YupString,
    postalCode: Yup.string().max(16, 'Postal code must not exceed 16 characters'),
    additionalLine1: YupString,
    additionalLine2: YupString,
    additionalLine3: YupString,
});

export const EditButton = ({ onClick, w }: { onClick: VoidFunction; w?: string }) =>
    CreateOrangeButton('Edit')({
        onClick,
        isDisabled: false,
        w: w ?? undefined,
    });

export const SubmitButton = ({ isLoading, isDisabled, w }: { isLoading: boolean; isDisabled: boolean; w?: string }) =>
    CreateOrangeButton('Save Changes')({
        isDisabled: isDisabled,
        isLoading,
        type: 'submit',
        w: w ?? undefined,
    });

export const CancelButton = (props: ButtonProps) => (
    <Button bg="transparent" h="14" py="6" px="10" w="auto" borderWidth="px" border={'solid'} borderColor={'brand.bulla_blue'} {...props}>
        <Text fontWeight="600" fontSize={16} color="brand.bulla_blue">
            Cancel
        </Text>
    </Button>
);

export const PersistForm = <T,>({
    isDisabled,
    values,
    persist,
}: {
    isDisabled: boolean;
    values: T;
    persist: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const [debouncedValues] = useDebounce(values, 300);

    React.useEffect(() => {
        if (!isDisabled) persist(debouncedValues);
    }, [debouncedValues]);
    return null;
};

type SettingsFieldProps = typeof FIELDS[number] & {
    optional?: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    show: boolean;
    touched: boolean | undefined;
    locked: boolean;
    error: string | undefined;
};

export const SettingsField = ({
    error,
    isLoading,
    show,
    label,
    name,
    placeholder,
    isDisabled,
    locked,
    touched,
    optional,
}: SettingsFieldProps) => {
    const loadingWidth = React.useRef(randomWidth(250, 400, 'px'));
    return (
        <Field name={name}>
            {({ field }: FieldProps) => (
                <FormControl isInvalid={!!error && !!touched} my="4">
                    <Collapse in={show}>
                        <FormLabel htmlFor={name}>
                            <HStack>
                                <Text>{label}</Text> {optional && <Text fontWeight={'normal'}>(optional)</Text>}
                            </HStack>
                        </FormLabel>
                        <InputGroup>
                            <Skeleton w={isLoading ? loadingWidth.current : 'inherit'} isLoaded={!isLoading}>
                                <Input
                                    {...field}
                                    value={field.value ?? ''}
                                    bg={'white'}
                                    placeholder={locked ? '' : placeholder}
                                    isDisabled={locked || isDisabled}
                                    borderColor="gray.500"
                                    {...disabledInputProps}
                                />
                            </Skeleton>
                        </InputGroup>
                        {touched && <FormErrorMessage>{error}</FormErrorMessage>}
                    </Collapse>
                </FormControl>
            )}
        </Field>
    );
};

type SubmissionState = boolean | CaughtError;
type CompanyDetailsState = 'init' | 'fetching' | null | CompanyDetails | CaughtError;

export const areDetailsReady = (state: CompanyDetailsState): state is CompanyDetails =>
    !!state && state !== 'init' && state !== 'fetching' && !('errorMessage' in state);
export const areDetailsLoading = (state: CompanyDetailsState): state is 'init' | 'fetching' => state === 'init' || state === 'fetching';

type CompanyDetailsFormProps = {
    initialValues: Fields;
    defaultFieldValues: Fields;
    isLoading: boolean;
    isSubmitting: boolean;
    areFieldsLocked: boolean;
    submissionError?: CaughtError;
    detailsFetchError?: CaughtError;
    companyDetails?: CompanyDetails;
    onSubmit: (values: Fields) => Promise<void>;
    renderActions: (props: {
        values: Fields;
        hasUnsavedChanges: boolean;
        isSubmitting: boolean;
        resetForm: () => void;
        completeForm: () => void;
    }) => React.ReactNode;
    onPersist: (values: Fields) => void;
};

export const CompanyDetailsForm = ({
    initialValues,
    defaultFieldValues,
    isLoading,
    isSubmitting,
    areFieldsLocked,
    submissionError,
    detailsFetchError,
    onSubmit,
    renderActions,
    onPersist,
}: CompanyDetailsFormProps) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [_addedLineCount, setAddedLineCount] = useState(1);
    const addedLineCount = countAddedLines(initialValues);

    const incrementAdditionalLines = () => setAddedLineCount(lns => (lns < 3 ? lns + 1 : lns));

    return (
        <Formik validateOnBlur enableReinitialize onSubmit={onSubmit} initialValues={initialValues} validationSchema={companyDetailsSchema}>
            {({ errors, touched, values, resetForm }) => {
                const hasUnsavedChanges = !_.isEqual(defaultFieldValues, values);
                const completeForm = () => onPersist(values);

                return (
                    <Stack>
                        <Wrap spacing={10} w="100%">
                            <WrapItem flex="1">
                                <Box ref={gridRef} w="100%">
                                    <Form placeholder={''}>
                                        {FIELDS.map(field => {
                                            const show = field.name.includes('additionalLine')
                                                ? addedLineCount >= +field.name.slice(-1)
                                                : true;
                                            return (
                                                <SettingsField
                                                    {...field}
                                                    locked={areFieldsLocked}
                                                    show={show}
                                                    isLoading={isLoading}
                                                    isDisabled={isSubmitting}
                                                    error={errors[field.name]}
                                                    touched={touched[field.name]}
                                                    key={field.name}
                                                />
                                            );
                                        })}
                                        <TextButton
                                            onClick={incrementAdditionalLines}
                                            isDisabled={addedLineCount === 3 || areFieldsLocked}
                                            color="brand.bulla_blue"
                                            textDecor="none"
                                        >
                                            Add additonal line +
                                        </TextButton>
                                        <Box h="10" />
                                        {(submissionError || detailsFetchError) && (
                                            <>
                                                <Text color={'red'}>
                                                    {submissionError?.errorMessage || detailsFetchError?.errorMessage}
                                                </Text>
                                                <Text color={'red'}>
                                                    Please reach out to{' '}
                                                    <Link
                                                        href={BULLA_NETWORK_DISCORD_INVITE}
                                                        isExternal
                                                        fontSize={'inherit'}
                                                        fontWeight={600}
                                                    >
                                                        Support <ExternalLinkIcon boxSize={4} />
                                                    </Link>
                                                </Text>
                                            </>
                                        )}
                                        {renderActions({ values, hasUnsavedChanges, isSubmitting, resetForm, completeForm })}
                                    </Form>
                                    <PersistForm isDisabled={_.isEqual(initialValues, values)} values={values} persist={onPersist} />
                                </Box>
                            </WrapItem>
                        </Wrap>
                    </Stack>
                );
            }}
        </Formik>
    );
};

export const CompanyDetailsPage = ({ onSave }: { onSave?: VoidFunction }) => {
    const actingAddress = useActingWalletAddress();
    const isMobile = useIsMobile();
    const { getCompanyDetails, postCompanyDetails } = useCompanyDetailsRepo();
    const triggerSuccessToast = useBullaToast();

    const gridRef = useRef<HTMLDivElement>(null);
    const [companyDetails, setCompanyDetails] = useState<CompanyDetailsState>('init');
    const [submissionState, setSubmissionState] = useState<SubmissionState>(false);
    const [unsavedChanges, setUnsavedChanges, clearUnsavedChanges] = useSessionStorage<Fields | undefined>(
        `${actingAddress}:${STORAGE_KEYS.companyDetails}`,
    );
    const [editing, editFields] = useBoolean(!!unsavedChanges);

    const defaultFieldValues = areDetailsReady(companyDetails) ? mapCompanyDetailsToFields(companyDetails) : emptyFields;
    const initialValues = unsavedChanges ?? defaultFieldValues;
    const [_addedLineCount, setAddedLineCount] = React.useState(1);
    const [, addedLineCount] = [areDetailsReady(companyDetails) ? countAddedLines(initialValues) : _addedLineCount, _addedLineCount].sort();

    useEffect(() => {
        setCompanyDetails('fetching');
        getCompanyDetails()
            .then(setCompanyDetails)
            .catch(e => setCompanyDetails({ errorMessage: e.message }));
    }, [actingAddress]);

    const incrementAdditionalLines = () => setAddedLineCount(lns => (lns < 3 ? lns + 1 : lns));

    const detailsFetchError = !!companyDetails && typeof companyDetails === 'object' && 'errorMessage' in companyDetails;
    const hasUnsavedChanges = !!unsavedChanges && !_.isEqual(defaultFieldValues, unsavedChanges);
    const submissionError = typeof submissionState === 'object' && 'errorMessage' in submissionState;

    const isLoading = areDetailsLoading(companyDetails);
    const isFirstTimeUse = _.isEqual(initialValues, emptyFields);
    const isSubmitting = submissionState === true;
    const areFieldsLocked = !editing && !hasUnsavedChanges && !isFirstTimeUse;

    const completeForm = () => {
        editFields.off();
        clearUnsavedChanges();
    };

    const handleSubmit = useCallback(
        async (values: Fields) => {
            setSubmissionState(true);
            try {
                const companyDetails = mapFieldsToCompanyDetails(values);
                await postCompanyDetails(companyDetails);
                setCompanyDetails(companyDetails);

                completeForm();
                setSubmissionState(false);
                triggerSuccessToast({ title: 'Changes saved successfully.' });
                onSave?.();
            } catch (e: any) {
                console.error('🚨 POST failed 🚨');
                console.log(e);
                setSubmissionState({ errorMessage: e.message });
            }
        },
        [actingAddress],
    );

    return (
        <Formik
            validateOnBlur
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={companyDetailsSchema}
        >
            {({ errors, touched, values, resetForm }) => (
                <Stack>
                    <AlertInfo message="Adding your company details allows you to generate invoices or payment receipts as PDFs whenever you create a transaction." />
                    <Box h="4" />
                    <Wrap spacing={10} w="100%">
                        <WrapItem flex="1">
                            <Box ref={gridRef} w="100%">
                                <Heading size="lg" color="heading" fontWeight={600}>
                                    Company Details
                                </Heading>
                                <CompanyDetailsForm
                                    initialValues={initialValues}
                                    defaultFieldValues={defaultFieldValues}
                                    isLoading={isLoading}
                                    isSubmitting={isSubmitting}
                                    areFieldsLocked={areFieldsLocked}
                                    submissionError={submissionError ? { errorMessage: submissionState.errorMessage } : undefined}
                                    detailsFetchError={detailsFetchError ? (companyDetails as CaughtError) : undefined}
                                    onSubmit={handleSubmit}
                                    onPersist={setUnsavedChanges}
                                    renderActions={({ values, hasUnsavedChanges, isSubmitting, resetForm }) => (
                                        <HStack float={'right'}>
                                            {areFieldsLocked ? (
                                                <EditButton onClick={editFields.on} />
                                            ) : (
                                                <>
                                                    {!_.isEqual(emptyFields, values) && (
                                                        <CancelButton
                                                            isDisabled={isSubmitting}
                                                            onClick={() => {
                                                                completeForm();
                                                                resetForm();
                                                            }}
                                                        />
                                                    )}
                                                    <SubmitButton
                                                        isLoading={isSubmitting}
                                                        isDisabled={isLoading || isSubmitting || !hasUnsavedChanges}
                                                    />
                                                </>
                                            )}
                                        </HStack>
                                    )}
                                />
                            </Box>
                        </WrapItem>
                        {!isMobile && (
                            <WrapItem flex="1" w="100%">
                                <Box height={`${gridRef.current?.offsetHeight}px` ?? '100%'} overflow={'hidden'} minW="800px">
                                    <DebouncedInvoicePreview fields={values} delay={500} />
                                </Box>
                            </WrapItem>
                        )}
                    </Wrap>
                </Stack>
            )}
        </Formik>
    );
};

export const AuthenticatedCompanyDetails = () => {
    const { user } = useAuth();

    return isUserReady(user) ? (
        <CompanyDetailsPage />
    ) : (
        <Container maxW="495px">
            <SignIn message={`Sign in to access and edit your company details.`} />
        </Container>
    );
};

export const ComapanyDetailsFromClaimCreationModal = ({
    fetchingDetails,
    onSignIn,
    onDetailsReady,
    triggerElement,
}: {
    fetchingDetails: boolean;
    onSignIn?: VoidFunction;
    onDetailsReady?: VoidFunction;
    triggerElement: (onOpen: () => void) => React.ReactNode;
}) => {
    const { user } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const isLoading = fetchingDetails;
    const showDetailsPage = isUserReady(user) && !isLoading;

    const handleDetailsInput = () => {
        onDetailsReady?.();
        onClose();
    };

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={showDetailsPage ? 'full' : '3xl'} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent py="4">
                    {!showDetailsPage && <CloseModalButton onClose={onClose} />}
                    <ModalBody py={showDetailsPage ? '10' : 'initial'}>
                        <MaxWidthWrapper>
                            <Flex align={'center'} justify="center">
                                {!isUserReady(user) ? (
                                    <Flex maxW="450px" minH="600px" flexGrow={1} align={'center'} justify={'center'}>
                                        <SignIn onSignIn={onSignIn} message="This is an advanced feature, sign-in to get access" />
                                    </Flex>
                                ) : isLoading ? (
                                    <Flex maxW="450px" minH="600px" flexGrow={1} align={'center'} justify={'center'}>
                                        <Spinner />
                                    </Flex>
                                ) : null}
                                {showDetailsPage && (
                                    <Stack spacing={0} w="100%">
                                        <HStack>
                                            <Heading color="heading" flex={1}>
                                                Settings
                                            </Heading>
                                            <Button
                                                bg="white"
                                                color={'black'}
                                                size={'lg'}
                                                border="1px solid #C9C9C9"
                                                borderRadius={'4px'}
                                                position="static"
                                                onClick={onClose}
                                                h="40px"
                                                w="40px"
                                            >
                                                <CloseIcon />
                                            </Button>
                                        </HStack>
                                        <Box h="8" />
                                        <CompanyDetailsPage onSave={handleDetailsInput} />
                                    </Stack>
                                )}
                            </Flex>
                        </MaxWidthWrapper>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export const SignInAndOnboardAttachemntGeneration = ({
    disableTrigger,
    onDetailsReady,
    onSignIn,
}: {
    disableTrigger?: boolean;
    onDetailsReady?: VoidFunction;
    onSignIn?: VoidFunction;
}) => {
    const { attachmentGenerationState, setAttachmentGenerationState } = useAttachmentGenerationState();

    return (
        <HStack>
            <ComapanyDetailsFromClaimCreationModal
                fetchingDetails={attachmentGenerationState === 'fetching'}
                onSignIn={onSignIn}
                onDetailsReady={() => {
                    setAttachmentGenerationState('ready');
                    onDetailsReady?.();
                }}
                triggerElement={onOpen => (
                    <TextButton
                        onClick={onOpen}
                        color="brand.bulla_blue"
                        fontWeight={600}
                        isDisabled={disableTrigger || attachmentGenerationState == 'fetching'}
                    >
                        {attachmentGenerationState == 'unauthenticated' ? 'Sign In' : 'Add Company Details'}
                    </TextButton>
                )}
            />
        </HStack>
    );
};
